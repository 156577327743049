import React, { Component } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
//import { InputSwitch } from 'primereact/inputswitch';
import * as Constants from "../constants/Constant"
import "../styles/css/Table.css";

export class FormStore extends Component {
  // userData;
  // userStore;

  clear() {
    this.setState({
      stores: [],
      store: {},
      name: '',
      submitted: false,
      deleteStoreDialog: false,
      editingRows: {},
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      store: {},
      name: '',
      submitted: false,
      deleteStoreDialog: false,
      editingRows: {},
    };
    this.userData = {};
    this.userStore = {};
    this.originalRows = {};
    this.getStores = this.getStores.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.confirmDeleteStore = this.confirmDeleteStore.bind(this);
    this.hideDeleteStoreDialog = this.hideDeleteStoreDialog.bind(this);
    this.onEditorValueChange = this.onEditorValueChange.bind(this);
    this.inputTextEditor = this.inputTextEditor.bind(this);
    this.onRowEditInit = this.onRowEditInit.bind(this);
    this.onRowEditCancel = this.onRowEditCancel.bind(this);
    this.onRowEditChange = this.onRowEditChange.bind(this);
    this.storeNameEditor = this.storeNameEditor.bind(this);
    this.deleteStore = this.deleteStore.bind(this);
    this.saveStore = this.saveStore.bind(this);
    this.updateStore = this.updateStore.bind(this);
    this.clearStore = this.clearStore.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    //console.log("id" , this.props.match.params.id);
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.userStore = JSON.parse(localStorage.getItem('store'));

    const stores = this.getStores();
    this.setState({ stores });
  }

  getStores() {
    const fetchs = require('sync-fetch');
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const res = fetchs(Constants.APIURL + "/stores", requestOptions);
    if (res.ok) {
      return res.json();
    } else {
      return null;
    }
  }


  deleteStore() {
    let store = { ...this.state.store };
    // let checked = '';
    // if (store.isActive === 'Y') {
    //   checked = 'N';
    // } else {
    //   checked = 'Y';
    // }

    let headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json"
    };

    fetch(Constants.APIURL + "/storeIsActive/" + store.storeId, {
      method: "PUT",
      body: JSON.stringify({
        isActive: 'N',
        updatedBy: this.userData.userId,
      }),
      headers: headersList,
    }).then(function (response) {
      return response.text();
    }).then(function (data) {
      console.log(data);
    });

    let state = {
      //...state,
      stores: this.getStores(),
      store: {},
      name: '',
      submitted: false,
      deleteStoreDialog: false,
      editingRows: {},
    };
    this.setState(state);
    this.toast.show({ severity: "success", summary: "เพิ่มร้านค้า", detail: "ลบสำเร็จ", life: 3000, });
    
    this.refresh();
  }

  saveStore() {
    let state = { submitted: true };
    if (this.state.name.trim) {

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: this.state.name,
          isActive: 'Y',
          createdBy: this.userData.userId,
          updatedBy: this.userData.userId,
        }),
      };

      fetch(Constants.APIURL + "/store", requestOptions)
        .then((response) => {
          response.json()
        }).then((data) => {
          console.log(data)
          if (data.message !== 'Successful') {
            this.toast.show({ severity: 'warn', summary: 'เพิ่มร้านค้า', detail: 'บันทึกไม่สำเร็จ', life: 3000, });
          } else {
            this.toast.show({ severity: "success", summary: "เพิ่มร้านค้า", detail: "บันทึกสำเร็จ", life: 3000, });
          }
        }).catch((err) => console.log(err));

      state = {
        ...state,
        stores: this.getStores(),
        store: {},
        name: '',
        submitted: false,
        deleteStoreDialog: false,
        editingRows: {},
      };

    }
    this.setState(state);
  }

  updateStore(store) {

    let headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json"
    };

    fetch(Constants.APIURL + "/store/" + store.storeId, {
      method: "PUT",
      body: JSON.stringify({
        name: store.name,
        updatedBy: this.userData.userId,
      }),
      headers: headersList,
    }).then(function (response) {
      return response.text();
    }).then(function (data) {
      console.log(data);
    });

    let state = {
      //...state,
      stores: this.getStores(),
      name: "",
      submitted: false,
      deleteStoreDialog: false,
      editingRows: {},
    }
    this.setState(state);
    this.toast.show({ severity: "success", summary: "แก้ไขร้านค้า", detail: "บันทึกสำเร็จ", life: 3000, });
    
    this.refresh();
  }

  refresh() {
    setTimeout(function () {
      window.location.reload();
    }, 100);
  }


  clearStore() {
    let state = {
      //...state,
      stores: [],
      store: {},
      name: '',
      submitted: false,
      deleteStoreDialog: false,
      editingRows: {},
    };
    this.setState(state);
  }

  onRowEditInit(event) {
    this.originalRows[event.index] = { ...this.state.stores[event.index] };
  }

  onRowEditCancel(event) {
    let storesEdit = this.state.stores;
    storesEdit[event.index] = this.originalRows[event.index];
    this.setState((state, props) => ({
      stores: Object.values(storesEdit),
    }));
    delete this.originalRows[event.index];
  }

  onRowEditChange(event) {
    this.setState((state, props) => ({
      editingRows: event.data,
    }));
    let editingRows = { ...this.state.editingRows };
    let length = Object.keys(editingRows).length;
    if (length === 1) {
      let storeId = Object.keys(editingRows).toString();
      let stores = { ...this.state.stores };
      let storesByEditingRows = Object.keys(stores).filter((key) => {
        if (stores[key].storeId.toString() === storeId) {
          return storeId;
        }
        else { return ''; }
      });
      let store = stores[storesByEditingRows]
      this.updateStore(store);
    }
  }

  onEditorValueChange(itemKey, props, value) {
    let updatedStore = [...props.value];
    updatedStore[props.rowIndex][props.field] = value;
    this.setState((state, props) => ({
      [`${itemKey}`]: updatedStore,
    }));
  }

  inputTextEditor(itemKey, props, field) {
    return (
      <InputText
        type="text"
        className="p-inputtext-sm"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChange(itemKey, props, e.target.value)
        }
      />
    );
  }


  storeNameEditor(itemKey, props) {
    return this.inputTextEditor(itemKey, props, "name");
  }


  actionBodyTemplate(rowData) {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-secondary p-button-text"
          onClick={() => this.confirmDeleteStore(rowData)}
        />

        {/* <InputSwitch
          // checked={rowData.isActive}
          checked={false}
          onChange={() => this.confirmDeleteStore(rowData)} /> */}
      </React.Fragment>
    );
  }

  confirmDeleteStore(store) {
    this.setState({
      store,
      deleteStoreDialog: true,
    });
  }

  hideDeleteStoreDialog() {
    this.setState({ deleteStoreDialog: false });
  }

  render() {
    const deleteStoreDialogFooter = (
      <React.Fragment>
        <Button
          label="ยกเลิก"
          icon="pi pi-times"
          className="p-button-sm p-button-text"
          onClick={this.hideDeleteStoreDialog}
        />
        <Button
          label="ยื่นยัน"
          icon="pi pi-check"
          className="p-button-sm p-button-text"
          onClick={this.deleteStore}
        />
      </React.Fragment>
    );

    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <div className="card">
          <h3>จัดการร้านค้า</h3>
        </div>

        <div className="card">
          <div className="p-fluid p-formgrid p-grid">

            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
              <span className="p-float-label">
                <InputText
                  id="name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  autoFocus
                  className={classNames({
                    "p-inputtext-sm p-invalid": this.state.submitted && !this.state.name,
                  })}
                />
                <label htmlFor="cost">ชื่อร้านค้า</label>
              </span>
              {this.state.submitted && !this.state.name && (
                <small className="p-error">Store name is required.</small>
              )}
            </div>

            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
              <Button
                label="บันทึก"
                icon="pi pi-save"
                className="p-button-sm p-button-raised p-button-info"
                style={{ width: "100px" }}
                onClick={this.saveStore}
              />
              <Button
                label="ล้างข้อมูล"
                icon="pi pi-undo"
                className="p-button-sm p-button-raised p-button-secondary"
                style={{ width: "120px", marginLeft: "7px" }}
                onClick={this.clearStore}
              />
            </div>
          </div>

        </div>

        <div className="card">
          <DataTable
            className="p-datatable-responsive-demo p-datatable-sm"
            dataKey="storeId"
            value={this.state.stores}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50 ,100]}
            style={{ fontSize: "11px" }}
            editMode="row"
            editingRows={this.state.editingRows}
            onRowEditInit={this.onRowEditInit}
            onRowEditChange={this.onRowEditChange}
            onRowEditCancel={this.onRowEditCancel}
          >
            <Column
              field="name"
              header="ชื้อร้านค้า"
              editor={(props) => this.storeNameEditor("stores", props)}
              headerStyle={{ textAlign: "center" }}
            />
            <Column
              header="แก้ไข"
              rowEditor
              headerStyle={{ textAlign: "center", width: "5rem" }}
              bodyStyle={{ textAlign: "center" }}
            />
            <Column
              field="isActive"
              header="ลบ"
              headerStyle={{ textAlign: "center", width: "5rem" }}
              bodyStyle={{ textAlign: "center" }}
              body={this.actionBodyTemplate}
            />
          </DataTable>
        </div>

        <Dialog
          visible={this.state.deleteStoreDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteStoreDialogFooter}
          onHide={this.hideDeleteStoreDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {this.state.stores && (
              <span>
                Are you sure you want to delete{" "}
                <b>{this.state.stores.name}</b>?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}