import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    requisitionNoContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    requisitionDateContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },

    label: {
        width: 60,
    },

    titleContainer: {
        flexDirection: "row",
    },
    reportTitle: {
        fontSize: 25,
        textAlign: "center",
    },
});

const RequisitionNo = ({ item }) => {
    console.log("pdf requisitionNo item", item);
    console.log("pdf requisitionNo items", item.items);
    return (
        <Fragment>
            <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}>ใบเบิก</Text>
            </View>
            <View style={styles.requisitionNoContainer}>
                <Text style={styles.label}>วันที่: </Text>
                <Text>{item.date}</Text>
            </View>
            <View style={styles.requisitionDateContainer}>
                <Text style={styles.label}>ลำดับที:</Text>
                <Text>{item.no}</Text>
            </View>
        </Fragment>
    );
};

export default RequisitionNo;
