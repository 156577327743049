import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        flexGrow: 1,
        marginTop: 24,
    },
    approver: {
        width: "40%",
        marginRight: 50,
    },
    receiver: {
        width: "60%",
        marginLeft: 90,
    },
});

const RequisitionFooter = () => (
    <Fragment>
        <View View style={styles.container}>
            <View>
                <Text style={styles.approver}>-------------------- </Text>
                <Text style={styles.approver}>(ผู้อนุมัติ) </Text>
            </View>
            <View>
                <Text style={styles.receiver}>-------------------- </Text>
                <Text style={styles.receiver}>(ผู้รับสินค้า)</Text>
            </View>
        </View>
    </Fragment>
);

export default RequisitionFooter;
