import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { Component } from "react";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormItemStock extends Component {
    // userData;
    // userStore;

    emptStock = {
        stockNumber: "",
        selectedItem: "",
        quantity: 0,
    };

    constructor(props) {
        super(props);
        this.state = {
            stocks: [],
            stock: this.emptStock,
            items: [],
            type: "เพิ่มสต็อกสินค้า",
            selectedItem: null,
            selectedType: null,
            submitted: false,
        };

        this.types = [
            { description: "เพิ่มสต็อกสินค้า", type: "IN" },
            { description: "ปรับลดสต็อกสินค้า", type: "ADJ" },
        ];

        this.userData = {};
        this.userStore = {};
        this.onItemChange = this.onItemChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.getItems = this.getItems.bind(this);
        this.getItemById = this.getItemById.bind(this);
        this.getStocks = this.getStocks.bind(this);
        this.saveStock = this.saveStock.bind(this);
        this.clearStock = this.clearStock.bind(this);
        this.dateBodyTemplate = this.dateBodyTemplate.bind(this);
        this.stockNumberBodyTemplate = this.stockNumberBodyTemplate.bind(this);
        this.typeBodyTemplate = this.typeBodyTemplate.bind(this);
        this.itemNameBodyTemplate = this.itemNameBodyTemplate.bind(this);
        this.quantityBodyTemplate = this.quantityBodyTemplate.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const items = await this.getItems();
        const stocks = await this.getStocks();

        //console.log("items ", items);

        this.setState({
            items: items,
            stocks: stocks,
        });
    }

    getItems() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/itemByIsGrouping", requestOptions).json();
        return res;
    }

    getStocks() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/itemStocks", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getItemById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/item/" + id, requestOptions).json();
        return res;
    }

    getNumber(stock) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/itemStockByStockNumber/" + stock.stockNumber + "/" + stock.itemId, requestOptions).json();
        return res;
    }

    saveStock() {
        let state = { submitted: true };

        let type;
        if (this.state.selectedType) {
            type = this.state.selectedType.type;
        } else {
            type = "IN";
        }

        if (this.state.selectedItem && this.state.stock.stockNumber.trim()) {
            let stock = {
                stockNumber: this.state.stock.stockNumber.trim(),
                itemId: this.state.selectedItem.itemId,
            };

            let data = this.getNumber(stock);

            if (data.number) {
                this.toast.show({ severity: "warn", summary: "เพิ่มสต๊อกสินค้า", detail: "มีรหัสสต๊อกสินค้านี้แล้ว", life: 3000 });
            } else {
                let stock = { ...this.state.stock };

                let headersList = {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                };

                fetch(Constants.APIURL + "/itemStock", {
                    method: "POST",
                    body: JSON.stringify({
                        stockNumber: stock.stockNumber,
                        type: type,
                        itemId: this.state.selectedItem.itemId,
                        quantity: stock.quantity,
                        remark: stock.remark,
                        isActive: "Y",
                        createdBy: this.userData.userId,
                        updatedBy: this.userData.userId,
                    }),
                    headers: headersList,
                })
                    .then(function (response) {
                        return response.text();
                    })
                    .then(function (data) {
                        console.log(data);
                    });

                let itemById = this.getItemById(this.state.selectedItem.itemId);

                if (itemById) {
                    let total;
                    if (type === "IN") {
                        total = itemById.total + stock.quantity;
                    } else {
                        total = itemById.total - stock.quantity;
                    }

                    let headersList = {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                    };

                    fetch(Constants.APIURL + "/itemTotal/" + itemById.itemId, {
                        method: "PUT",
                        body: JSON.stringify({
                            total: total,
                            updatedBy: this.userData.userId,
                        }),
                        headers: headersList,
                    })
                        .then(function (response) {
                            return response.text();
                        })
                        .then(function (data) {
                            console.log(data);
                        });
                }

                state = {
                    ...state,
                    stocks: this.getStocks(),
                    stock: this.emptStock,
                    selectedItem: null,
                    selectedType: null,
                    submitted: false,
                };

                this.toast.show({ severity: "success", summary: "เพิ่มสต๊อกสินค้า", detail: "บันทึกสำเร็จ", life: 3000 });

                this.refresh();
            }
        }
        this.setState(state);
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    clearStock() {
        let state = {
            //...state,
            stock: this.emptStock,
            selectedItem: null,
            selectedType: null,
            submitted: false,
        };
        this.setState(state);
    }

    onItemChange(e) {
        this.setState({ selectedItem: e.value });
    }

    onTypeChange(e) {
        this.setState({ selectedType: e.value });
    }

    onInputChange(e, value) {
        const val = (e.target && e.target.value) || "";
        let stock = { ...this.state.stock };
        stock[`${value}`] = val;

        this.setState({ stock });
    }

    onInputNumberChange(e, value) {
        const val = e.value || 0;
        let stock = { ...this.state.stock };
        stock[`${value}`] = val;

        this.setState({ stock });
    }

    dateBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">วันที่</span>
                <span>{rowData.createdDate}</span>
            </React.Fragment>
        );
    }

    stockNumberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสสต๊อกสินค้า</span>
                <span>{rowData.stockNumber}</span>
            </React.Fragment>
        );
    }

    typeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภท</span>
                <span>{rowData.type}</span>
            </React.Fragment>
        );
    }

    itemNameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อสินค้า</span>
                <span>{rowData.itemName}</span>
            </React.Fragment>
        );
    }

    quantityBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวน</span>
                <span>{rowData.quantity}</span>
            </React.Fragment>
        );
    }
    remarkBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">หมายเหตุ</span>
                <span>{rowData.remark}</span>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="card">
                    <h3>จัดการสต๊อก</h3>
                </div>

                <div className="card">
                    <div className="p-fluid p-formgrid p-grid">
                        {this.userData.role === Constants.ADMIN && (
                            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                                <Dropdown
                                    value={this.state.selectedType}
                                    options={this.types}
                                    onChange={this.onTypeChange}
                                    optionLabel="description"
                                    placeholder="เลือกประเภท"
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedType,
                                    })}
                                />
                                {this.state.submitted && !this.state.selectedType && <small className="p-error">Item Type is required.</small>}
                            </div>
                        )}
                        {this.userData.role !== Constants.ADMIN && (
                            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                                <span className="p-float-label">
                                    <InputText className="p-inputtext-sm" value={this.state.type} disabled="true" />
                                    <label htmlFor="cost">ประเภท</label>
                                </span>
                            </div>
                        )}
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="stockNumber"
                                    value={this.state.stock.stockNumber}
                                    onChange={(e) => this.onInputChange(e, "stockNumber")}
                                    prefix="SI"
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.stock.stockNumber,
                                    })}
                                />
                                <label htmlFor="itemNumber">รหัสสต๊อกสินค้า</label>
                            </span>
                            {this.state.submitted && !this.state.stock.stockNumber && <small className="p-error">Stock number is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <Dropdown
                                value={this.state.selectedItem}
                                options={this.state.items}
                                onChange={this.onItemChange}
                                optionLabel="name"
                                filter
                                showClear
                                filterBy="name"
                                placeholder="เลือกสินค้า"
                                autoFocus
                                //className="p-invalid"
                                className={classNames({
                                    "p-invalid": this.state.submitted && !this.state.selectedItem,
                                })}
                            />
                            {this.state.submitted && !this.state.selectedItem && <small className="p-error">Item name is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputNumber
                                    id="quantity"
                                    value={this.state.stock.quantity}
                                    onChange={(e) => this.onInputNumberChange(e, "quantity")}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.stock.quantity,
                                    })}
                                />
                                <label htmlFor="cost">จำนวนสินค้า</label>
                            </span>
                            {this.state.submitted && !this.state.stock.quantity && <small className="p-error">Item quantity is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText id="remark" className="p-inputtext-sm" value={this.state.stock.remark} onChange={(e) => this.onInputChange(e, "remark")} />
                                <label htmlFor="cost">หมายเหตุ</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <Button label="บันทึก" icon="pi pi-save" className="p-button-sm p-button-raised p-button-info" style={{ width: "100px" }} onClick={this.saveStock} />
                            <Button label="ล้างข้อมูล" icon="pi pi-undo" className="p-button-sm p-button-raised p-button-secondary" style={{ width: "120px", marginLeft: "7px" }} onClick={this.clearStock} />
                        </div>
                    </div>
                </div>

                <div className="datatable-responsive-demo">
                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="stockId"
                        value={this.state.stocks}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        style={{ fontSize: "11px" }}
                    >
                        <Column field="createdDate" header="วันที่" body={this.dateBodyTemplate} sortable />
                        <Column field="stockNumber" header="รหัสสต๊อกสินค้า" body={this.stockNumberBodyTemplate} sortable />
                        <Column field="type" header="ประเภท" body={this.typeBodyTemplate} sortable />
                        <Column field="itemName" header="ชื่อสินค้า" body={this.itemNameBodyTemplate} sortable />
                        <Column field="quantity" header="จำนวน" body={this.quantityBodyTemplate} />
                        <Column field="remark" header="หมายเหตุ" body={this.remarkBodyTemplate} />
                    </DataTable>
                </div>
            </div>
        );
    }
}
