import React, { Component } from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Card, Col, Input, InputGroup, InputGroupAddon, FormGroup, Button, Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from "xlsx";
import * as Constants from "../constants/Constant";
import { TemplateService } from "../service/TemplateService";
export class FormUploadCod extends Component {
    userAccessToken;
    // userData;
    // userStore;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dataLoaded: false,
            isFormInvalid: false,
            rows: null,
            cols: null,
            data: null,
            header: null,
        };
        this.userData = {};
        this.userStore = {};
        this.templateService = new TemplateService();
        this.fileHandler = this.fileHandler.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openFileBrowser = this.openFileBrowser.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.fileInput = React.createRef();
        this.readFile = this.readFile.bind(this);
        this.convertToJson = this.convertToJson.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));
        this.userAccessToken = localStorage.getItem("accessToken");
        this.templateService.getTemplateHeader().then((data) => this.setState({ header: data }));
        console.log("header", this.state.header);
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.header);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
            this.saveAsExcelFile(excelBuffer, "upload_cod_template");
        });
    }

    saveAsExcelFile(buffer, fileName) {
        // import("file-saver").then((FileSaver) => {
        //     let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        //     let EXCEL_EXTENSION = ".xlsx";
        //     const data = new Blob([buffer], {
        //         type: EXCEL_TYPE,
        //     });
        //     FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        // });
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    saveFile() {
        console.log("userAccessToken", this.userAccessToken);
        console.log("userData", this.userData.userId);
        console.log("userStore", this.userStore.storeId);

        let data = { ...this.state.data };
        console.log(data);

        const fetchs = require("sync-fetch");
        const responsePost = fetchs(Constants.APIURL + "/upload/" + this.userAccessToken, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: this.state.data,
        }).json();

        console.log(responsePost);

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        fetchs(Constants.APIURL + "/upload/" + this.userAccessToken + "/" + this.userData.userId + "/" + this.userStore.storeId, requestOptions).json();

        this.setState({
            isOpen: false,
            dataLoaded: false,
            isFormInvalid: false,
            uploadedFileName: "",
            rows: null,
            cols: null,
            data: null,
        });
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    openFileBrowser = () => {
        this.fileInput.current.click();
    };

    fileHandler = (event) => {
        if (event.target.files.length) {
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;

            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
                this.setState({
                    uploadedFileName: fileName,
                    isFormInvalid: false,
                });
                this.renderFile(fileObj);
                this.readFile(fileObj);
            } else {
                this.setState({
                    isFormInvalid: true,
                    uploadedFileName: "",
                });
            }
        }
    };

    renderFile = (fileObj) => {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    dataLoaded: true,
                    cols: resp.cols,
                    rows: resp.rows,
                });
            }
        });
    };

    readFile(file) {
        var f = file;
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            //console.log("Data>>>" , data);// shows that excel data is read
            //console.log("Json>>>" , this.convertToJson(data)); // shows data in json format

            this.setState({ data: this.convertToJson(data) });
        };
        reader.readAsBinaryString(f);
    }

    convertToJson(csv) {
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            if (currentline.length > 1) {
                for (var j = 0; j < headers.length; j++) {
                    obj[headers[j]] = currentline[j];
                }

                result.push(obj);
            }
        }
        //return result; //JavaScript object
        return JSON.stringify(result); //JSON
    }

    render() {
        return (
            <div>
                <div className="card">
                    <h1>Upload COD Report</h1>
                </div>
                <div className="card">
                    <Container>
                        <FormGroup row>
                            <Col xs={4} sm={8} lg={10}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <Button color="info" style={{ color: "white", zIndex: 0 }} onClick={this.openFileBrowser}>
                                            <i className="cui-file"></i> Browse&hellip;
                                        </Button>
                                        <input
                                            type="file"
                                            hidden
                                            onChange={this.fileHandler}
                                            ref={this.fileInput}
                                            onClick={(event) => {
                                                event.target.value = null;
                                            }}
                                            style={{ padding: "10px" }}
                                        />
                                    </InputGroupAddon>
                                    <Input type="text" className="form-control" value={this.state.uploadedFileName} readOnly invalid={this.state.isFormInvalid} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button color="info" style={{ color: "white", zIndex: 0 }} onClick={this.saveFile}>
                                        บันทึก
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button color="info" style={{ color: "white", zIndex: 0 }} onClick={this.exportExcel}>
                                        ดาวน์โหลด Template
                                    </Button>
                                </InputGroup>
                            </Col>
                        </FormGroup>

                        {this.state.dataLoaded && (
                            <div style={{ marginTop: "20px" }}>
                                <Card body outline color="secondary" className="restrict-card">
                                    <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                                </Card>
                            </div>
                        )}
                    </Container>
                </div>
            </div>
        );
    }
}
