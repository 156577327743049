import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderBottomWidth: 1,
        alignItems: "center",
        textAlign: "center",
        flexGrow: 1,
    },
    no: {
        width: "5%",
        borderRightWidth: 1,
    },
    description: {
        width: "25%",
        borderRightWidth: 1,
    },
    number: {
        width: "10%",
        borderRightWidth: 1,
    },
    piece: {
        width: "10%",
        borderRightWidth: 1,
    },
    set: {
        width: "10%",
        borderRightWidth: 1,
    },
    remak: {
        width: "15%",
        //borderRightWidth: 1,
    },
});

const RequisitionTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.no}>No</Text>
        <Text style={styles.number}>Item Number</Text>
        <Text style={styles.description}>Item Set/Description</Text>
        <Text style={styles.set}>Unit/set</Text>
        <Text style={styles.description}>Item Name/Description</Text>
        <Text style={styles.piece}>Unit/piece </Text>
        <Text style={styles.remak}>Remark</Text>
    </View>
);

export default RequisitionTableHeader;
