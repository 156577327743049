//export const APIURL = "http://localhost:8080"

export const APIURL = "http://api.rukmeemii.com";
export const URL = "http://rukmeemii.com";

export const ADMIN = "Ultra";
export const OWNER = "Owner-shop";
export const SUPER = "Supervisor";
export const SALE = "Admin";
export const MANAGE = "Manage-shop";
