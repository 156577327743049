import React, { Component } from 'react';
import { PDFDownloadLink} from '@react-pdf/renderer'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Axios from "axios";
import Requisition from './Requisition';
// import requisition from '../data/requisition'
import * as Constants from "../constants/Constant"
import "../styles/css/Table.css";

export class FormRequisitionItem extends Component {
    // userData;
    // userStore;

    empty = {
        itemNumber: '-',
        itemSet: '-',
        totalSet: '-',
        total: '-',
    };

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            storeId: null,
            show: false,
        };
        this.userData = {};
        this.userStore = {};
        this.getReport = this.getReport.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem('user'));
        this.userStore = JSON.parse(localStorage.getItem('store'));

        //const reports = await this.getReport();
        let res = await Axios(
            `${Constants.APIURL}/report/dailyRequisitionItem/${this.userStore.storeId}`
        )

        this.setState({
            reports: res.data,
            storeId: this.userStore.storeId,
            show: true,
        });

    }

    getReport() {
        const fetchs = require('sync-fetch');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const res = fetchs(Constants.APIURL + "/report/dailyRequisitionItem/" + this.userStore.storeId, requestOptions).json();
        return res;
    }




    render() {

        return (
            <div>
                <div className="card">
                    <h3>รายงาน:ใบเบิกสินค้า</h3>
                </div>

                <div className="card">
                    {this.state.show && (
                        <div>
                            <PDFDownloadLink
                                document={<Requisition item={this.state.reports} />}
                                fileName="Requisition.pdf"
                            >
                                <Button label="ดาวน์โหลด" className="p-button-sm p-button-info p-mr-2 p-mb-2" />

                            </PDFDownloadLink>
                        </div>
                    )}
                    <DataTable value={this.state.reports.items} style={{ fontSize: "11px" }}>
                        <Column field="itemNumber" header="Item itemNumber" />
                        <Column field="itemSet" header="Item Set /Description" />
                        <Column field="totalSet" header="Unit/set" />
                        <Column field="itemDesc" header="item Name/Description" />
                        <Column field="total" header="Unit/piece" />
                    </DataTable>
                </div>
                {/* {this.state.show && (
                    <PDFViewer width="1000" height="600" className="app" >
                        <Requisition item={this.state.reports} />
                    </PDFViewer>
                )} */}

            </div>
        );
    }
}
