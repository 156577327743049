import React, { Component } from "react";
import * as Constants from "../constants/Constant";

export class FormLink extends Component {
    // userData;
    // userStore;

    emptyOrder = {
        status: "",
        orderDate: "",
        name: "",
        address: "",
        mobilePhone: "",
        delivery: "",
        trackingNumber: "",
        amount: "",
    };

    emptyOrderItem = {
        name: "",
        total: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            orders: this.emptyOrder,
            orderItems: this.emptyOrderItem,
        };

        this.userData = {};
        this.userStore = {};
    }

    async componentDidMount() {
        console.log("FormLink id ---------->", this.props.match.params.id);
        // const count = Number('1234') //1234
        // console.log("count", count);
        // const num = Number(this.props.match.params.id) //1234
        // console.log("num", num);
        // console.log("typeof", typeof (num));

        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const orders = await this.getOrder();
        const orderItems = await this.getOrderItem();

        this.setState({
            orders: orders,
            orderItems: orderItems,
        });
    }

    getOrder() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/order/" + this.props.match.params.id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return this.emptyOrder;
        }
    }

    getOrderItem() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItemByOrderId/" + this.props.match.params.id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return this.emptyOrderItem;
        }
    }

    isNumeric(id) {
        const num = Number(id);

        console.log("num", num);

        if (isNaN(num)) {
            console.log("NaN", isNaN(num));

            return null;
        } else {
            console.log("typeof", typeof num);

            return typeof num === "number";
        }
    }

    render() {
        if (this.isNumeric(this.props.match.params.id)) {
            return (
                <div>
                    <div className="p-text-center" style={{ fontSize: "20px", marginTop: 20 }}>
                        รายละเอียดออเดอร์สินค้า{" "}
                    </div>
                    <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                        <table style={{ background: "white" }}>
                            <thead>
                                <tr style={{ background: "#dddddd" }}>
                                    <th>รายละเอียด ออเดอร์ </th>
                                    <th>สถานะ : {this.state.orders.status}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={"w-25"}>วันที่</td>
                                    <td>{this.state.orders.orderDateTime}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>ชื่อ</td>
                                    <td>{this.state.orders.name}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>ที่อยู่</td>
                                    <td>
                                        {this.state.orders.address} {this.state.orders.zipcode}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>เฟซบุ๊ก</td>
                                    <td>{this.state.orders.facebook}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>เบอร์โทรศัพท์</td>
                                    <td>{this.state.orders.mobilePhone}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>การจัดส่ง</td>
                                    <td>{this.state.orders.delivery}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>สะดวกรับ</td>
                                    <td>{this.state.orders.appointment}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>เลขพัสดุ</td>
                                    <td>{this.state.orders.trackingNumber}</td>
                                </tr>
                                <tr>
                                    <td className={"w-25"}>รวมเงิน</td>
                                    <td>{this.state.orders.amount}</td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr style={{ background: "#dddddd" }}>
                                    <th>รายการสินค้า</th>
                                    <th>จำนวน</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orderItems.length > 0 &&
                                    this.state.orderItems.map((item, key) => (
                                        <tr key={key}>
                                            <td className={"w-25"}>{item.name}</td>
                                            <td>{item.total}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
