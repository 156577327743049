import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from 'primereact/image';
import { Button } from "primereact/button";
import * as Constants from "../constants/Constant"
import "../styles/css/Table.css";

export class FormSaleOrderDtl extends Component {


    constructor(props) {
        super(props);

        this.state = {
            order: {},
            items: [],
            data: '',
        };

        this.userData = {};
        this.userStore = {};
        this.backToOrder = this.backToOrder.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.itemNumberBodyTemplate = this.itemNumberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.totalBodyTemplate = this.totalBodyTemplate.bind(this);
        this.discountBodyTemplate = this.discountBodyTemplate.bind(this);
        this.discountTypeBodyTemplate = this.discountTypeBodyTemplate.bind(this);
    }

    async componentDidMount() {

        this.userData = JSON.parse(localStorage.getItem('user'));
        this.userStore = JSON.parse(localStorage.getItem('store'));

        const order = await this.getOrderById(this.props.match.params.id);

        const items = this.getOrderItemById(order.orderId);

        this.setState({
            order: order,
            items: items,
        });
    }

    getOrderById(id) {
        const fetchs = require('sync-fetch');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const res = fetchs(Constants.APIURL + "/order/" + id, requestOptions).json();
        return res;
    }

    getOrderItemById(id) {
        const fetchs = require('sync-fetch');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const res = fetchs(Constants.APIURL + "/orderItemByOrderId/" + id, requestOptions).json();
        return res;
    }

    backToOrder() {
        this.props.history.push("/saleOrder");
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button
                    label="กลับไปหน้ารายการ"
                    icon="pi pi-chevron-left"
                    className="p-button-sm p-button-secondary"
                    style={{ width: "160px" }}
                    onClick={this.backToOrder}
                />
            </React.Fragment>
        )
    }

    itemNumberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสสินค้า</span>
                <span>{rowData.itemNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อสินค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    priceBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ราคาขาย</span>
                <span>{rowData.price}</span>
            </React.Fragment>
        );
    }

    totalBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวน</span>
                <span>{rowData.total}</span>
            </React.Fragment>
        );
    }

    discountTypeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทส่วนลด</span>
                <span>{rowData.discountType}</span>
            </React.Fragment>
        );
    }

    discountBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ส่วนลด</span>
                <span>{rowData.discount}</span>
            </React.Fragment>
        );
    }


    render() {
        return (
            <div>

                <div className="card">
                    <h3>ออเดอร์สินค้า</h3>
                </div>

                <div className="card form-demo">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                    <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="createdByName"
                                    className="p-inputtext-sm"
                                    value={this.state.order.createdByName}
                                    disabled
                                />
                                <label htmlFor="orderDate">แอดมิน</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="updatedByName"
                                    className="p-inputtext-sm"
                                    value={this.state.order.updatedByName}
                                    disabled
                                />
                                <label htmlFor="orderDate">แอดมินแก้ไขล่าสุด</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="orderDate"
                                    className="p-inputtext-sm"
                                    value={this.state.order.orderDateTime}
                                    disabled
                                />
                                <label htmlFor="orderDate">วันที่ออเดอร์</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="updatedDate"
                                    className="p-inputtext-sm"
                                    value={this.state.order.updatedDate}
                                    disabled
                                />
                                <label htmlFor="updatedDate">วันที่แก้ไขล่าสุด</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    className="p-inputtext-sm"
                                    value={this.state.order.orderNumber}
                                    disabled
                                />
                                <label htmlFor="name">เลขที่ออเดอร์</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    className="p-inputtext-sm"
                                    value={this.state.order.name}
                                    disabled
                                />
                                <label htmlFor="name">ชื่อ-นามสกุล</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="facebook"
                                    className="p-inputtext-sm"
                                    value={this.state.order.facebook}
                                    disabled />
                                <label htmlFor="name">เฟซบุ๊ก</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputTextarea
                                    rows={1}
                                    cols={30}
                                    id="address"
                                    value={this.state.order.address}
                                    disabled />
                                <label htmlFor="address">ที่อยู่</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="zipcode"
                                    className="p-inputtext-sm"
                                    value={this.state.order.zipcode}
                                    disabled />
                                <label htmlFor="paymentId">รหัสไปรณีย์</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputNumber
                                    id="price"
                                    className="p-inputtext-sm"
                                    value={this.state.order.amount}
                                    mode="currency"
                                    currency="THB"
                                    locale="th-TH"
                                    disabled
                                />
                                <label htmlFor="price">ยอดเงิน</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="paymentId"
                                    className="p-inputtext-sm"
                                    value={this.state.order.payment}
                                    disabled />
                                <label htmlFor="paymentId">การชำระเงิน</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="paymentStatus"
                                    className="p-inputtext-sm"
                                    value={this.state.order.paymentStatus}
                                    disabled />
                                <label htmlFor="paymentStatus">สถานะการชำระเงิน</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="delivery"
                                    className="p-inputtext-sm"
                                    value={this.state.order.delivery}
                                    disabled />
                                <label htmlFor="delivery">การจัดส่ง</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="status"
                                    className="p-inputtext-sm"
                                    value={this.state.order.status}
                                    disabled />
                                <label htmlFor="status">สถานะการจัดส่ง</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="mobilePhone"
                                    className="p-inputtext-sm"
                                    value={this.state.order.mobilePhone}
                                    type="number"
                                    maxLength='10'
                                    disabled />
                                <label htmlFor="mobilePhone">เบอร์โทรศัทพ์</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="appointment"
                                    className="p-inputtext-sm"
                                    value={this.state.order.appointment}
                                    disabled />
                                <label htmlFor="appointment">วันที่สะดวกรับ</label>
                            </span>
                        </div>

                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="appointment"
                                    className="p-inputtext-sm"
                                    value={this.state.order.pocketNumber}
                                    disabled />
                                <label htmlFor="appointment">รหัสกระเป๋า</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="trackingNumber"
                                    className="p-inputtext-sm"
                                    value={this.state.order.trackingNumber}
                                    disabled />
                                <label htmlFor="trackingNumber">เลขที่พัสดุ</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputTextarea
                                    rows={1}
                                    cols={30}
                                    value={this.state.order.link}
                                    disabled />
                                <label htmlFor="link">ลิงค์</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <Image src={this.state.order.slip} alt="slip" width="100" preview />

                        </div>
                    </div>

                    <div className="datatable-responsive-demo">
                        <DataTable
                            className="p-datatable-responsive-demo p-datatable-sm"
                            dataKey="itemListId"
                            value={this.state.items}
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            editMode="row"
                            style={{ fontSize: "11px" }}
                        >
                            <Column
                                field="itemNumber"
                                header="รหัสสินค้า"
                                body={this.itemNumberBodyTemplate}>
                            </Column>
                            <Column
                                field="name"
                                header="ชื่อสินค้า"
                                body={this.nameBodyTemplate}
                            />
                            <Column
                                field="price"
                                header="ราคาขาย"
                                body={this.priceBodyTemplate}
                            />
                            <Column
                                field="total"
                                header="จำนวน"
                                body={this.totalBodyTemplate}
                            />
                            <Column
                                field="discountType"
                                header="ประเภทส่วนลด"
                                body={this.discountTypeBodyTemplate}
                            />
                            <Column
                                field="discount"
                                header="ส่วนลด"
                                body={this.discountBodyTemplate}
                            />
                        </DataTable>
                    </div>

                </div>
            </div>
        );
    }
}