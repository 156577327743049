import React from "react";

export const AppFooter = () => {
    return (
        <div className="layout-footer">
            {/*<span className="footer-text" style={{ 'marginRight': '5px' }}></span>
            <img src="assets/layout/images/image-black.png" alt="Logo" width="50" />*/}
            <span className="footer-text" style={{ marginLeft: "5px" }}>
                Stock & Order Management
            </span>
        </div>
    );
};
