import React, { Component } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
//import { InputSwitch } from "primereact/inputswitch";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormStoreUser extends Component {
    //userData;
    //userStore;

    emptUser = {
        username: "",
        password: "",
        name: "",
        email: "",
        mobilePhone: "",
        selectedRole: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            storeUsers: [],
            storeUser: this.emptUser,
            roles: [],
            selectedRole: null,
            roleId: "Admin",
            submitted: false,
            deleteStoreUserDialog: false,
            editingRows: {},
        };
        this.userData = {};
        this.userStore = {};
        this.originalRows = {};
        this.onRoleChange = this.onRoleChange.bind(this);
        this.getRole = this.getRole.bind(this);
        this.getStoreUsers = this.getStoreUsers.bind(this);
        this.saveStoreUser = this.saveStoreUser.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateIsActive = this.updateIsActive.bind(this);
        this.clearStoreUser = this.clearStoreUser.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.passwordEditor = this.passwordEditor.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.confirmDeleteStoreUser = this.confirmDeleteStoreUser.bind(this);
        this.hideDeleteStoreUserDialog = this.hideDeleteStoreUserDialog.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const roles = await this.getRole();
        const storeUsers = await this.getStoreUsers(this.userStore.storeId);

        this.setState({
            roles: roles,
            storeUsers: storeUsers,
        });
    }

    getRole() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/role", requestOptions).json();
        return res;
    }

    getStoreUsers(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/storeUsersByStoreId/" + id, requestOptions);
        console.log("getStoreUsers", res.ok);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    saveStoreUser() {
        console.log("saveStoreUser");
        let state = { submitted: true };

        let roleId;

        if (this.state.selectedRole) {
            roleId = this.state.selectedRole.roleId;
            console.log("if", roleId);
        } else {
            roleId = 5;
            console.log("else", roleId);
        }

        console.log("roleId", roleId);

        if (roleId && this.state.storeUser.username && this.state.storeUser.password) {
            let storeUser = { ...this.state.storeUser };
            let roleId;
            if (this.state.selectedRole) {
                roleId = this.state.selectedRole.roleId;
            } else {
                roleId = 5;
            }

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    name: storeUser.name,
                    username: storeUser.username,
                    password: storeUser.password,
                    roleId: roleId,
                    storeId: this.userStore.storeId,
                    isActive: "Y",
                    createdBy: this.userData.userId,
                    updatedBy: this.userData.userId,
                }),
            };

            fetch(Constants.APIURL + "/storeUser", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (data.message !== "Successful") {
                        this.toast.show({ severity: "warn", summary: "เพิ่มผู้ใช้", detail: "บันทึกไม่สำเร็จ", life: 3000 });
                    } else {
                        this.toast.show({ severity: "success", summary: "เพิ่มผู้ใช้", detail: "บันทึกสำเร็จ", life: 3000 });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            state = {
                ...state,
                storeUsers: this.getStoreUsers(this.userStore.storeId),
                storeUser: this.emptUser,
                selectedRole: null,
                submitted: false,
                deleteStoreUserDialog: false,
                editingRows: {},
            };
        }
        this.setState(state);
    }

    updatePassword(storeUser) {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/storeUserPassword/" + storeUser.userId, {
            method: "PUT",
            body: JSON.stringify({
                password: storeUser.password,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        let state = {
            //...state,
            storeUsers: this.getStoreUsers(this.userStore.storeId),
            storeUser: this.emptUser,
            selectedRole: null,
            deleteStoreUserDialog: false,
            submitted: false,
        };
        this.setState(state);

        this.toast.show({ severity: "success", summary: "แก้ไขผู้ใช้", detail: "บันทึกสำเร็จ", life: 3000 });
    }

    updateIsActive() {
        let storeUser = { ...this.state.storeUser };
        // let checked = '';
        // if (storeUser.isActive === 'Y') {
        //   checked = 'N';
        // } else {
        //   checked = 'Y';
        // }

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/storeUserIsActive/" + storeUser.userId, {
            method: "PUT",
            body: JSON.stringify({
                isActive: "N",
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        this.setState({
            storeUsers:this.getStoreUsers(this.userStore.storeId),
            storeUser: this.emptUser,
            selectedRole: null,
            deleteStoreUserDialog: false,
            submitted: false,
        });

        this.toast.show({ severity: "success", summary: "แก้ไขผู้ใช้", detail: "บันทึกสำเร็จ", life: 3000 });

        window.location.reload();
    }

    clearStoreUser() {
        let state = {
            //...state,
            storeUser: this.emptUser,
            selectedRole: null,
            submitted: false,
            deleteStoreUserDialog: false,
            editingRows: {},
        };
        this.setState(state);
    }

    onRoleChange(e) {
        this.setState({ selectedRole: e.value });
    }

    onInputChange(e, value) {
        const val = (e.target && e.target.value) || "";
        let storeUser = { ...this.state.storeUser };
        storeUser[`${value}`] = val;

        this.setState({ storeUser });
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.storeUsers[event.index] };
    }

    onRowEditCancel(event) {
        let storeUsersEdit = this.state.storeUsers;
        storeUsersEdit[event.index] = this.originalRows[event.index];
        this.setState((state, props) => ({
            storeUsers: Object.values(storeUsersEdit),
        }));
        delete this.originalRows[event.index];
    }

    onRowEditChange(event) {
        this.setState((state, props) => ({
            editingRows: event.data,
        }));
        let editingRows = { ...this.state.editingRows };
        let length = Object.keys(editingRows).length;
        if (length === 1) {
            let userId = Object.keys(editingRows).toString();
            let storeUsers = { ...this.state.storeUsers };
            let storeUsersByEditingRows = Object.keys(storeUsers).filter((key) => {
                if (storeUsers[key].userId.toString() === userId) {
                    return userId;
                } else {
                    return "";
                }
            });
            let storeUser = storeUsers[storeUsersByEditingRows];
            this.updatePassword(storeUser);
        }
    }

    onEditorValueChange(itemKey, props, value) {
        let updatedStoreUser = [...props.value];
        updatedStoreUser[props.rowIndex][props.field] = value;
        this.setState((state, props) => ({
            [`${itemKey}`]: updatedStoreUser,
        }));
    }

    inputTextEditor(itemKey, props, field) {
        return <InputText type="text" className="p-inputtext-sm" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(itemKey, props, e.target.value)} />;
    }

    passwordEditor(itemKey, props) {
        return this.inputTextEditor(itemKey, props, "password");
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อ-สกุล</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    userNameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อผู้ใช้</span>
                <span>{rowData.userName}</span>
            </React.Fragment>
        );
    }

    roleBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทผู้ใช้งาน</span>
                <span>{rowData.role}</span>
            </React.Fragment>
        );
    }
    passwordBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสผู้ใช้งาน</span>
                <span>{rowData.password}</span>
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.confirmDeleteStoreUser(rowData)} />
                {/* <InputSwitch
          checked={rowData.isActive}
          onChange={() => this.confirmDeleteStoreUser(rowData)} /> */}
            </React.Fragment>
        );
    }

    confirmDeleteStoreUser(storeUser) {
        this.setState({
            storeUser,
            deleteStoreUserDialog: true,
        });
    }

    hideDeleteStoreUserDialog() {
        this.setState({ deleteStoreUserDialog: false });
    }

    render() {
        const deleteStoreUserDialogFooter = (
            <React.Fragment>
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-sm p-button-text" onClick={this.hideDeleteStoreUserDialog} />
                <Button label="ยื่นยัน" icon="pi pi-check" className="p-button-sm p-button-text" onClick={this.updateIsActive} />
            </React.Fragment>
        );
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />

                <div className="card">
                    <h3>ผู้ใช้งาน</h3>
                </div>

                <div className="card">
                    <div className="p-fluid p-formgrid p-grid">
                        {this.userData.role !== Constants.SUPER && (
                            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                                <Dropdown
                                    value={this.state.selectedRole}
                                    options={this.state.roles}
                                    onChange={this.onRoleChange}
                                    optionLabel="role"
                                    filter
                                    showClear
                                    filterBy="role"
                                    placeholder="เลือกประเภทผู้ใช้งาน"
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedRole,
                                    })}
                                />

                                {this.state.submitted && !this.state.selectedRole && <small className="p-error">Role is required.</small>}
                            </div>
                        )}
                        {this.userData.role === Constants.SUPER && (
                            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                                <span className="p-float-label">
                                    <InputText className="p-inputtext-sm" value={this.state.roleId} disabled="true" />
                                    <label htmlFor="cost">ประเภทผู้ใช้งาน</label>
                                </span>
                            </div>
                        )}
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    value={this.state.storeUser.name}
                                    onChange={(e) => this.onInputChange(e, "name")}
                                    autoFocus
                                    keyfilter={/^[^#<>*!]+$/}
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.storeUser.name,
                                    })}
                                />
                                <label htmlFor="cost">ชื่อ - นามสกุล</label>
                            </span>
                            {this.state.submitted && !this.state.storeUser.name && <small className="p-error">Item price is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    value={this.state.storeUser.username}
                                    onChange={(e) => this.onInputChange(e, "username")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.storeUser.username,
                                    })}
                                />
                                <label htmlFor="cost">ชื่อผู้ใช้</label>
                            </span>
                            {this.state.submitted && !this.state.storeUser.username && <small className="p-error">Username is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <Password
                                    id="password"
                                    value={this.state.storeUser.password}
                                    onChange={(e) => this.onInputChange(e, "password")}
                                    autoFocus
                                    toggleMask
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.storeUser.password,
                                    })}
                                />
                                <label htmlFor="cost">รหัสผ่าน</label>
                            </span>
                            {this.state.submitted && !this.state.storeUser.password && <small className="p-error">Password is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <Button label="บันทึก" icon="pi pi-save" className="p-button-sm p-button-raised p-button-info" style={{ width: "100px" }} onClick={this.saveStoreUser} />
                            <Button label="ล้างข้อมูล" icon="pi pi-undo" className="p-button-sm p-button-raised p-button-secondary" style={{ width: "120px", marginLeft: "7px" }} onClick={this.clearStoreUser} />
                        </div>
                    </div>
                </div>

                <div className="datatable-responsive-demo">
                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="userId"
                        value={this.state.storeUsers}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        style={{ fontSize: "11px" }}
                        editMode="row"
                        editingRows={this.state.editingRows}
                        onRowEditInit={this.onRowEditInit}
                        onRowEditChange={this.onRowEditChange}
                        onRowEditCancel={this.onRowEditCancel}
                    >
                        <Column field="role" header="ประเภทผู้ใช้งาน" body={this.roleBodyTemplate} />
                        <Column field="name" header="ชื่อ-สกุล" body={this.nameBodyTemplate} />
                        <Column field="userName" header="ชื่อผู้ใช้" body={this.userNameBodyTemplate} />

                        <Column field="password" header="รหัสผู้ใช้งาน" body={this.passwordBodyTemplate} editor={(props) => this.passwordEditor("stores", props)} />
                        <Column header="แก้ไข" rowEditor headerStyle={{ width: "5rem" }} />
                        <Column field="isActive" header="ลบ" body={this.actionBodyTemplate} headerStyle={{ width: "5rem" }} />
                    </DataTable>
                </div>

                <Dialog visible={this.state.deleteStoreUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteStoreUserDialogFooter} onHide={this.hideDeleteStoreUserDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                        {this.state.storeUsers && (
                            <span>
                                Are you sure you want to delete <b>{this.state.storeUsers.name}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        );
    }
}
