import React, { Component } from "react";
import axios from "axios";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { OverlayPanel } from "primereact/overlaypanel";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import * as Constants from "../constants/Constant";
//import "../styles/css/Table.css";

export class FormItemCreate extends Component {
    //userData;
    //userStore;

    emptItem = {
        itemNumber: "",
        name: "",
        description: "",
        img: "",
        cost: 0,
        total: 0,
        minimun: 10,
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptItem,
            itemSet: this.emptItem,
            items: [],
            products: [],
            selectedProduct: null,
            submitted: false,
            editingRows: {},
            file: "",
            data: [],
            progress: 0,
        };
        this.userData = {};
        this.userStore = {};
        this.originalRows = {};
        this.getProducts = this.getProducts.bind(this);
        this.getNumber = this.getNumber.bind(this);
        this.formatCurrency = this.formatCurrency.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.onProductSelect = this.onProductSelect.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.clearItem = this.clearItem.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.cancelItem = this.cancelItem.bind(this);
        this.deleteItemSet = this.deleteItemSet.bind(this);
        this.itemCostBody = this.itemCostBody.bind(this);
        this.itemTotalEditor = this.itemTotalEditor.bind(this);
        this.createItemGroup = this.createItemGroup.bind(this);
        this.createItem = this.createItem.bind(this);
        this.imgUploadHandler = this.imgUploadHandler.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.imageBody = this.imageBody.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const products = await this.getProducts();
        this.setState({ products });
    }

    getProducts() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/itemByIsGrouping", requestOptions).json();
        return res;
    }

    getNumber(number) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/item/itemNumber/" + number, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    createItem(isGrouping) {
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/item", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                itemNumber: this.state.item.itemNumber,
                name: this.state.item.name,
                description: this.state.item.description,
                cost: this.state.item.cost,
                minimun: this.state.item.minimun,
                img: this.state.data.path,
                total: 0,
                isGrouping: isGrouping,
                isActive: "Y",
                createdBy: this.userData.userId,
                updatedBy: this.userData.userId,
            }),
        }).json();
        return response;
    }

    createItemGroup(item) {
        console.log("createItemGroup >>>> item", item);
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/itemGroup", {
            method: "POST",
            body: JSON.stringify({
                itemId: item.itemId,
                itemSetId: item.itemSetId,
                total: item.total,
                isActive: "Y",
                createdBy: this.userData.userId,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    saveItem() {
        let state = { submitted: true };

        if (this.state.item.itemNumber.trim() && this.state.item.name.trim()) {
            const data = this.getNumber(this.state.item.itemNumber.trim());

            console.log("dupNumber", this.state.item.itemNumber.trim(), data);

            if (data) {
                this.toast.show({ severity: "warn", summary: "เพิ่มสินค้า", detail: "มีรหัสสินค้านี้แล้ว", life: 3000 });
            } else {
                let items = [...this.state.items];

                console.log("items", items);
                console.log("itemslength", items);

                let isGrouping = "N";

                if (items.length > 0) {
                    isGrouping = "Y";
                    let createItem = this.createItem(isGrouping);

                    for (let itemSet of items) {
                        const itemGroup = {
                            itemId: createItem.id,
                            itemSetId: itemSet.itemId,
                            total: itemSet.total,
                        };
                        this.createItemGroup(itemGroup);
                    }
                } else {
                    this.createItem(isGrouping);
                }

                state = {
                    ...state,
                    item: this.emptItem,
                    itemSet: this.emptItem,
                    items: [],
                    products: [],
                    selectedProduct: null,
                    submitted: false,
                    editingRows: {},
                };

                this.toast.show({ severity: "success", summary: "เพิ่มสินค้า", detail: "บันทึกสำเร็จ", life: 3000 });
                this.refresh();
            }
        }
        this.setState(state);
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    cancelItem() {
        this.props.history.push("/item");
    }

    clearItem() {
        let state = {
            //...state,
            item: this.emptItem,
            itemSet: this.emptItem,
            selectedProduct: null,
            submitted: false,
            editingRows: {},
        };
        this.setState(state);
    }

    deleteItemSet(item) {
        let items = [...this.state.items];
        items.pop(item);
        this.setState({ items });
        this.toast.show({ severity: "success", summary: "สินค้า", detail: "ลบสำเร็จ", life: 3000 });
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    onRowEditInit(event) {
        console.log("onRowEditInit >>>> event.index: ", event.index);
        this.originalRows[event.index] = { ...this.state.items[event.index] };
    }

    onRowEditCancel(event) {
        console.log("onRowEditCancel >>>> event.index: ", event.index);
        let itemsEdit = this.state.items;

        itemsEdit[event.index] = this.originalRows[event.index];

        this.setState((state, props) => ({
            items: Object.values(itemsEdit),
        }));

        delete this.originalRows[event.index];
    }

    onRowEditChange(event) {
        this.setState({ editingRows: event.data });
    }

    onInputChange(e, value) {
        const val = (e.target && e.target.value) || "";
        let item = { ...this.state.item };
        item[`${value}`] = val;

        this.setState({ item });
    }

    onInputNumberChange(e, value) {
        const val = e.value || 0;
        let item = { ...this.state.item };
        item[`${value}`] = val;

        this.setState({ item });
    }

    onProductSelect(e) {
        this.setState({ selectedProduct: e.value }, () => {
            let items = [...this.state.items];
            let itemSet = { ...this.state.itemSet };
            let selectedProduct = this.state.selectedProduct;

            console.log("onProductSelect >>>> selectedProduct : ", selectedProduct.itemId);

            let itemsBySelectedProduct = items.filter((val) => {
                if (val.itemId === selectedProduct.itemId) {
                    return selectedProduct;
                } else {
                    return "";
                }
            });

            let length = Object.keys(itemsBySelectedProduct).length;

            console.log("onProductSelect >>>> length : ", length);

            if (length === 1) {
                this.toast.show({ severity: "warn", summary: "เพิ่มสินค้า", detail: "มีสินค้านี้แล้ว" + this.state.selectedProduct.name, life: 3000 });
            } else {
                console.log("onProductSelect >>>> itemId : ", this.state.selectedProduct.itemId);

                itemSet.itemId = this.state.selectedProduct.itemId;
                itemSet.itemNumber = this.state.selectedProduct.itemNumber;
                itemSet.name = this.state.selectedProduct.name;
                itemSet.cost = this.state.selectedProduct.cost;
                items.push(itemSet);

                this.setState({ items });
                this.toast.show({ severity: "info", summary: "เพิ่มสินค้า", detail: "เลือกสินค้าสำเร็จ" + this.state.selectedProduct.name, life: 3000 });
            }
            this.op.hide();
        });
    }

    onEditorValueChange(itemKey, props, value) {
        let updatedItems = [...props.value];
        updatedItems[props.rowIndex][props.field] = value;
        //this.setState({ [`${itemKey}`]: updatedItems });

        this.setState((state, props) => ({
            [`${itemKey}`]: updatedItems,
        }));
    }

    imgUploadHandler(e) {
        //console.log('file', this.state.file);
        const file = e.files[0];
        //const [file] = files;
        //console.log('file', file);
        this.uploadImg(file);
        this.setState({
            file: file,
            progress: 0,
        });
    }

    uploadImg(img) {
        //console.log('img', img);
        let formData = new FormData();
        formData.append("file", img);

        axios
            .post(Constants.APIURL + "/uploadItem", formData, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + "%";
                    this.setState({
                        progress: progress,
                        file: "",
                    });
                },
            })
            .then((res) => {
                console.log(res);
                const data = { name: res.data.name, path: Constants.APIURL + "/item" + res.data.path };
                this.setState({ data: data });
                this.upload.clear();
            })
            .catch((err) => console.log(err));
    }

    itemCostBody(rowData) {
        return this.formatCurrency(rowData.cost);
    }

    itemTotalEditor(itemKey, props) {
        //return this.inputTextEditor(itemKey, props, "total");
        return <InputNumber value={props.rowData["total"]} onValueChange={(e) => this.onEditorValueChange(itemKey, props, e.value)} mode="decimal" showButtons min={0} max={100} />;
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="กลับไปหน้ารายการ" icon="pi pi-chevron-left" className="p-button-sm p-button-secondary" style={{ width: "160px", marginLeft: "7px" }} onClick={this.cancelItem} />
            </React.Fragment>
        );
    }

    imageBody(rowData) {
        return <img src={rowData.img} alt={rowData.img} className="product-image" width="32" />;
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.deleteItemSet(rowData)} />
            </React.Fragment>
        );
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button icon="pi pi-search" label={"เลือกสินค้า"} onClick={(e) => this.op.toggle(e)} aria-haspopup aria-controls="overlay_panel" className="p-button-sm p-button-success" />
                <Button label="ล้างข้อมูล" icon="pi pi-undo" className="p-button-sm p-button-secondary" style={{ width: "120px", marginLeft: "7px" }} onClick={this.clearItem} />
                <Button label="บันทึก" icon="pi pi-save" className="p-button-sm p-button-info" style={{ width: "100px", marginLeft: "7px" }} onClick={this.saveItem} />
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-sm p-button-danger" style={{ width: "100px", marginLeft: "7px" }} onClick={this.cancelItem} />
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />

                <div className="card">
                    <h3>เพิ่มสินค้า</h3>
                </div>

                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                    <div className="p-fluid p-grid">
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="itemNumber"
                                    value={this.state.item.itemNumber}
                                    onChange={(e) => this.onInputChange(e, "itemNumber")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.item.itemNumber,
                                    })}
                                />
                                <label htmlFor="itemNumber">รหัสสินค้า</label>
                            </span>
                            {this.state.submitted && !this.state.item.itemNumber && <small className="p-error">Item number is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    value={this.state.item.name}
                                    onChange={(e) => this.onInputChange(e, "name")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.item.name,
                                    })}
                                />
                                <label htmlFor="name">ชื่อสินค้า</label>
                            </span>
                            {this.state.submitted && !this.state.item.name && <small className="p-error">Item name is required.</small>}
                        </div>
                        <div className="p-field p-col-12" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText id="description" className="p-inputtext-sm" value={this.state.item.description} onChange={(e) => this.onInputChange(e, "description")} />
                                <label htmlFor="description">คำอธิบาย</label>
                            </span>
                        </div>
                        {this.userData.role !== Constants.SUPER && (
                            <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                                <span className="p-float-label">
                                    <InputNumber id="cost" value={this.state.item.cost} onChange={(e) => this.onInputNumberChange(e, "cost")} mode="currency" currency="THB" locale="th-TH" />
                                    <label htmlFor="cost">ราคาต้นทุน</label>
                                </span>
                            </div>
                        )}
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputNumber id="minimun" value={this.state.item.minimun} onChange={(e) => this.onInputNumberChange(e, "minimun")} />
                                <label htmlFor="name">ขั้นต้ำ</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "5px" }}>
                            <FileUpload name="img" ref={(el) => (this.upload = el)} accept="image/*" customUpload={true} uploadHandler={this.imgUploadHandler} mode="basic" chooseLabel="Upload Img" />
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <Image src={this.state.data.path} alt={this.state.data.name} width="100" preview />
                        </div>
                    </div>

                    <div className="datatable-responsive-demo">
                        <DataTable
                            className="p-datatable-responsive-demo p-datatable-sm"
                            dataKey="itemId"
                            value={this.state.items}
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            editMode="row"
                            editingRows={this.state.editingRows}
                            onRowEditInit={this.onRowEditInit}
                            onRowEditChange={this.onRowEditChange}
                            onRowEditCancel={this.onRowEditCancel}
                            style={{ fontSize: "11px" }}
                        >
                            <Column field="itemNumber" header="รหัสสินค้า" />
                            <Column field="name" header="ชื้อสินค้า" />
                            {this.userData.role !== Constants.SUPER && <Column field="cost" header="ราคาต้นทุน" body={this.itemCostBody} />}
                            <Column field="total" header="จำนวนขาย" editor={(props) => this.itemTotalEditor("total", props)} headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} required />
                            <Column header="แก้ไข" rowEditor headerStyle={{ textAlign: "center", width: "5rem" }} bodyStyle={{ textAlign: "center" }} />
                            <Column header="ลบ" headerStyle={{ textAlign: "center", width: "5rem" }} bodyStyle={{ textAlign: "center" }} body={this.actionBodyTemplate} />
                        </DataTable>
                    </div>
                    <OverlayPanel ref={(el) => (this.op = el)} showCloseIcon id="overlay_panel" style={{ width: "450px" }} className="overlaypanel-demo">
                        <DataTable value={this.state.products} selectionMode="single" paginator rows={5} selection={this.state.selectedProduct} onSelectionChange={this.onProductSelect} style={{ fontSize: "11px" }}>
                            <Column field="itemNumber" header="รหัสสินค้า" sortable />
                            <Column field="name" header="ชื่อสินค้า" sortable />
                            <Column field="img" header="รูปภาพ" body={this.imageBody} />
                            {this.userData.role !== Constants.SUPER && <Column field="cost" header="ราคาต้นทุน" body={this.costBody} sortable />}
                        </DataTable>
                    </OverlayPanel>
                </div>
            </div>
        );
    }
}
