import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormSaleOrder extends Component {
    // userData;
    // userStore;

    emptFilter = {
        dateOrder: "",
        orderNumber: "",
        name: "",
        storeId: "",
        status: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            selectedStatus: "",
            selectedDate: "",
            globalFilter: "",
        };
        this.userData = {};
        this.userStore = {};
        this.getOrder = this.getOrder.bind(this);
        // this.getOrderItem = this.getOrderItem.bind(this);
        // this.getItemDetail = this.getItemDetail.bind(this);
        this.getDelivery = this.getDelivery.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getStore = this.getStore.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.saleItemBodyTemplate = this.saleItemBodyTemplate.bind(this);
        this.amountBodyTemplate = this.amountBodyTemplate.bind(this);
        this.reset = this.reset.bind(this);
        this.onDeliveryChange = this.onDeliveryChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.filterDate = this.filterDate.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.formatDateValue = this.formatDateValue.bind(this);
        this.addressBodyTemplate = this.addressBodyTemplate.bind(this);
        this.viewOrder = this.viewOrder.bind(this);
        this.actionViewBodyTemplate = this.actionViewBodyTemplate.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const status = await this.getStatus();
        const delivery = await this.getDelivery();
        const orders = await this.checkRole();

        this.setState({
            orders: orders,
            delivery: delivery,
            status: status,
        });
    }

    checkRole() {
        console.log(">>>>>", this.userData.role, "<<<<<");
        if (this.userData.role === Constants.SALE) {
            const orders = this.getOrderUser();
            this.setState({ orders });
            return this.getOrderUser();
        } else {
            const orders = this.getOrder();
            this.setState({ orders });
            return this.getOrder();
        }
    }

    getOrderUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByUser/?storeId=" + this.userStore.storeId + "&userId=" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getOrder() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByStoreId/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    // getOrderItem(id) {
    //     const fetchs = require("sync-fetch");
    //     const requestOptions = {
    //         method: "GET",
    //         headers: { "Content-Type": "application/json" },
    //     };
    //     const res = fetchs(Constants.APIURL + "/orderItem/listItemName/" + id, requestOptions).json();
    //     return res;
    // }

    // getItemDetail(id) {
    //     let getOrderItem = this.getOrderItem(id);
    //     let detailArray = { ...getOrderItem };
    //     let index = Object.keys(detailArray).toString();
    //     let detail = detailArray[index];
    //     return detail.itemList;
    // }

    getDelivery() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/deliverName/", requestOptions).json();
        return res;
    }

    getStatus() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/statusName/", requestOptions).json();
        return res;
    }

    getStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/store/", requestOptions).json();
        return res;
    }

    viewOrder(order) {
        this.props.history.push("/viewOrder/" + order.orderId);
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.orders);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
            this.saveAsExcelFile(excelBuffer, "orders");
        });
    }

    saveAsExcelFile(buffer, fileName) {
        // import("file-saver").then((FileSaver) => {
        //     let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        //     let EXCEL_EXTENSION = ".xlsx";
        //     const data = new Blob([buffer], {
        //         type: EXCEL_TYPE,
        //     });
        //     FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        // });
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    reset() {
        this.setState({
            selectedDate: "",
            selectedDelivery: "",
            selectedStatus: "",
            globalFilter: "",
        });

        this.dt.reset();
    }

    onStatusChange(e) {
        this.dt.filter(e.value, "status", "equals");
        this.setState({ selectedStatus: e.value });
    }

    onDeliveryChange(e) {
        this.dt.filter(e.value, "delivery", "equals");
        this.setState({ selectedDelivery: e.value });
    }

    onDateChange(e) {
        this.dt.filter(e.value, "orderDateTime", "custom");
        this.setState({ selectedDate: e.value });
    }

    dateBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">วันที่</span>
                <span>{rowData.orderDateTime}</span>
            </React.Fragment>
        );
    }

    numberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสออเดอร์</span>
                <span>{rowData.orderNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อลูกค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    addressBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ที่อยู่</span>
                <span>{rowData.address}</span>
            </React.Fragment>
        );
    }

    saleItemBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สินค้า</span>
                <span>{rowData.product}</span>
                {/* <span>{this.getItemDetail(rowData.orderId)}</span> */}
            </React.Fragment>
        );
        //return this.getItemDetail(rowData.orderId);
    }

    amountBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ยอดเงิน</span>
                <span>{rowData.amount}</span>
            </React.Fragment>
        );
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    deliveryBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">การจัดส่ง</span>
                <span> {rowData.delivery}</span>
            </React.Fragment>
        );
    }

    trackingBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัส tracking</span>
                <span>
                    <mark>{rowData.trackingNumber}</mark>
                </span>
            </React.Fragment>
        );
    }

    statusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สถานะ</span>
                <span>{rowData.status}</span>
            </React.Fragment>
        );
    }

    paymentTypeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทการชำระเงิน</span>
                <span>{rowData.payment}</span>
            </React.Fragment>
        );
    }

    paymentStatusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สถานะการชำระเงิน</span>
                <span>{rowData.paymentStatus}</span>
            </React.Fragment>
        );
    }

    pocketBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสกระเป๋า</span>
                <span>{rowData.pocketNumber}</span>
            </React.Fragment>
        );
    }

    userBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">แอดมิน</span>
                <span>{rowData.createdByName}</span>
            </React.Fragment>
        );
    }

    imageBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">slip</span>
                {/*<img src={rowData.slip} alt="slip" className="product-image" width="32" /> */}
                <Image src={rowData.slip} alt="slip" width="32" preview />
            </React.Fragment>
        );
    }

    copyBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ลิงค์</span>
                <CopyToClipboard
                    text={rowData.link}
                    //onCopy={() => this.setState({ copied: true })}
                >
                    <Button icon="pi pi-copy" className="p-button-sm p-button-text" onClick={this.hideDeleteOrdersDialog} />
                </CopyToClipboard>
            </React.Fragment>
        );
    }

    actionViewBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.viewOrder(rowData)} />
            </React.Fragment>
        );
    }

    formatDate(data) {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            // if (day < 10) {
            //     day = day;
            // }

            return day + "-" + month + "-" + data.getFullYear();
        }
    }

    formatDateValue(value) {
        return value.substring(1, value.indexOf(' '));
    }


    filterDate(value, filter) {
        console.log("filterDate value", this.formatDateValue(value));
        console.log("filterDate filter",this.formatDate(filter));


        if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        
        return this.formatDateValue(value) === this.formatDate(filter);
    }

    render() {
        const header = (
            <div className="p-d-flex p-ai-center export-buttons">
                <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={this.reset} />
            </div>
        );

        const dateFilter = <Calendar value={this.state.selectedDate} onChange={this.onDateChange} dateFormat="dd-mm-yy" className="p-column-filter" />;

        const statusFilter = <Dropdown value={this.state.selectedStatus} options={this.state.status} onChange={this.onStatusChange} itemTemplate={this.statusItemTemplate} optionLabel="status" optionValue="status" className="p-column-filter" showClear />;

        // const deliveryFilter =
        //     <Dropdown value={this.state.selectedDelivery}
        //         options={this.state.delivery}
        //         onChange={this.onDeliveryChange}
        //         itemTemplate={this.deliveryItemTemplate}
        //         optionLabel="delivery"
        //         optionValue="delivery"
        //         className="p-column-filter"
        //         showClear />;

        return (
            <div>
                <div className="card">
                    <h3>รายการขาย</h3>
                </div>
                <div className="datatable-responsive-demo">
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="orderId"
                        value={this.state.orders}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        globalFilter={this.state.globalFilter}
                        header={header}
                        ref={(el) => (this.dt = el)}
                        style={{ fontSize: "11px" }}
                    >
                        <Column field="orderDateTime" header="วันที่" body={this.dateBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={this.filterDate} />
                        <Column field="orderNumber" header="รหัสออเดอร์" body={this.numberBodyTemplate} filter fontSize filterField="orderNumber" filterMatchMode="contains" sortable />
                        <Column field="name" header="ชื่อลูกค้า" body={this.nameBodyTemplate} filter filterField="name" filterMatchMode="contains" sortable />
                        <Column field="facebook" header="เฟซบุ๊ก" body={this.facebookBodyTemplate} filter filterField="facebook" filterMatchMode="contains" sortable />
                        <Column field="address" header="ที่อยู่" body={this.addressBodyTemplate} filter filterField="address" filterMatchMode="contains" sortable />
                        <Column field="mobilePhone" header="เบอร์โทรศัทพ์" body={this.mobilephoneBodyTemplate} filter filterField="mobilePhone" filterMatchMode="contains" sortable />
                        <Column field="amount" header="ยอดเงิน" body={this.amountBodyTemplate} filter filterField="amount" filterMatchMode="contains" sortable />
                        <Column field="product" header="สินค้า" body={this.saleItemBodyTemplate} sortable />
                        <Column field="pocketNumber" header="รหัสกระเป๋า" body={this.pocketBodyTemplate} filter filterField="pocketNumber" filterMatchMode="contains" sortable />
                        <Column field="trackingNumber" header="รหัส tracking" body={this.trackingBodyTemplate} filter filterField="trackingNumber" filterMatchMode="contains" sortable />
                        {/* <Column
                            field="payment"
                            header="ประเภทการชำระเงิน"
                            body={this.paymentTypeBodyTemplate}
                            sortable
                        /> */}
                        {/* <Column
                            field="paymentStatus"
                            header="สถานะการชำระเงิน"
                            body={this.paymentStatusBodyTemplate}
                            sortable
                        /> */}
                        {/* <Column
                            field="delivery"
                            header="การจัดส่ง"
                            body={this.deliveryBodyTemplate}
                            filterElement={deliveryFilter}
                            filter
                            sortable
                        /> */}
                        <Column field="status" header="สถานะ" body={this.statusBodyTemplate} filterElement={statusFilter} filter sortable />
                        <Column field="link" header="คัดลอกลิงค์" body={this.copyBodyTemplate} />
                        <Column field="createdByName" header="แอดมิน" body={this.userBodyTemplate} sortable />

                        <Column header="ดู" rowEditor headerStyle={{ width: "5rem" }} body={this.actionViewBodyTemplate} />
                    </DataTable>
                </div>
            </div>
        );
    }
}
