import React, { Component } from "react";
import axios from "axios";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

export class FormOrderCreate extends Component {
    // userData;
    // userStore;

    emptOrder = {
        name: "",
        facebook: "",
        address: "",
        subDistrict: "",
        district: "",
        province: "",
        zipcode: "",
        mobilePhone: "",
        amount: 0,
        selectedDelivery: null,
        selectedDiscountType: null,
        selectedPaymentType: null,
        selectedPostcode: null,
    };

    emptItem = {
        itemNumber: "",
        name: "",
        discountId: 3,
        discountType: "none",
        discount: 0,
        price: 0,
        total: 0,
    };

    constructor(props) {
        super(props);

        let today = new Date();

        this.state = {
            delivery: [],
            selectedDelivery: null,
            discountType: [],
            selectedDiscountType: null,
            paymentType: [],
            selectedPaymentType: null,
            order: this.emptOrder,
            items: [],
            item: this.emptItem,
            products: [],
            selectedProduct: null,
            postcode: [],
            selectedPostcode: null,
            submitted: false,
            editingRows: {},
            //blockedForm: false,
            disableDelivery: true,
            disableDiscount: true,
            file: "",
            data: [],
            progress: 0,
            confirmOrderDialog: false,
            orderDup: [],
        };

        this.userData = {};
        this.userStore = {};

        this.toDate = [today];

        this.options = [
            { value: 1, icon: "pi pi-percentage" },
            { value: 2, icon: "pi pi-money-bill" },
        ];
        this.el = React.createRef();
        this.originalRows = {};
        //this.createNumber = this.createNumber.bind(this);
        this.updateNumber = this.updateNumber.bind(this);
        this.getDelivery = this.getDelivery.bind(this);
        this.getDiscountType = this.getDiscountType.bind(this);
        this.getPaymentType = this.getPaymentType.bind(this);
        this.getPostcode = this.getPostcode.bind(this);
        this.getStoreItemsByStoreId = this.getStoreItemsByStoreId.bind(this);
        this.getOrderSumByOrderId = this.getOrderSumByOrderId.bind(this);
        this.getOrderDupCustomer = this.getOrderDupCustomer.bind(this);
        this.getOrderDupMobile = this.getOrderDupMobile.bind(this);
        this.getOrderById = this.getOrderById.bind(this);
        this.getOrderItemListById = this.getOrderItemListById.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.createOrderItem = this.createOrderItem.bind(this);
        this.deleteOrderItem = this.deleteOrderItem.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.clearOrder = this.clearOrder.bind(this);
        this.onDeliveryChange = this.onDeliveryChange.bind(this);
        this.onPaymentTypeChange = this.onPaymentTypeChange.bind(this);
        this.onPostcodeChange = this.onPostcodeChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onProductSelect = this.onProductSelect.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.itemTotalEditor = this.itemTotalEditor.bind(this);
        this.itemDiscountEditor = this.itemDiscountEditor.bind(this);
        this.itemDiscountTypeEditor = this.itemDiscountTypeEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.imageBody = this.imageBody.bind(this);
        this.priceBody = this.priceBody.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.itemNumberBodyTemplate = this.itemNumberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.totalBodyTemplate = this.totalBodyTemplate.bind(this);
        this.discountBodyTemplate = this.discountBodyTemplate.bind(this);
        this.discountTypeBodyTemplate = this.discountTypeBodyTemplate.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.formatCurrency = this.formatCurrency.bind(this);
        this.updatedAmount = this.updatedAmount.bind(this);
        this.updatedLink = this.updatedLink.bind(this);
        this.imgUploadHandler = this.imgUploadHandler.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
        this.formatDateToDateSql = this.formatDateToDateSql.bind(this);
        this.refresh = this.refresh.bind(this);
        this.hideConfirmOrderDialog = this.hideConfirmOrderDialog.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const products = await this.getStoreItemsByStoreId();
        const discountType = await this.getDiscountType();
        const paymentType = await this.getPaymentType();
        const postcode = await this.getPostcode();

        this.setState({
            products: products,
            discountType: discountType,
            paymentType: paymentType,
            postcode: postcode,
        });
    }

    getItems() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/items", requestOptions).json();
        return res;
    }

    getDelivery(payment) {
        console.log("getDelivery", payment);
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/deliverByPaymentId/" + payment.paymentId, requestOptions).json();
        return res;
    }

    getPostcode() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/postcode", requestOptions).json();
        return res;
    }

    getDiscountType() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/discount", requestOptions).json();
        return res;
    }

    getPaymentType() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/payment", requestOptions).json();
        return res;
    }

    getStoreItemsByStoreId() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/storeItemsByStoreId/" + this.userStore.storeId, requestOptions).json();
        return res;
    }

    getOrderSumByOrderId(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItem/sumAmount/" + id, requestOptions).json();
        return res;
    }

    getOrderDupCustomer(customerName) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersCustomerDupByStoreId/" + customerName + "/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getOrderDupMobile(mobilePhone) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersMobileDupByStoreId/" + mobilePhone + "/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getOrderById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/order/" + id, requestOptions).json();
        return res;
    }

    getOrderItemListById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItem/listItemName/" + id, requestOptions).json();
        return res;
    }

    createOrder() {
        let paymentStatusId;
        if (this.state.data.path) {
            paymentStatusId = 2;
        } else {
            paymentStatusId = 1;
        }
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/order", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                //orderNumber: this.createNumber(),
                name: this.state.order.name.trim(),
                facebook: this.state.order.facebook,
                mobilePhone: this.state.order.mobilePhone,
                address: this.state.order.address,
                //zipcode: this.state.order.zipcode,
                zipcode: this.state.selectedPostcode.postcode,
                deliveryId: this.state.selectedDelivery.deliveryId,
                paymentId: this.state.selectedPaymentType.paymentId,
                isPrinting: "N",
                statusId: 1,
                paymentStatusId: paymentStatusId,
                storeId: this.userStore.storeId,
                slip: this.state.data.path,
                appointmentFlag: this.state.order.appointment ? "Y" : "N",
                appointment: this.formatDateToDateSql(this.state.order.appointment),
                createdBy: this.userData.userId,
                updatedBy: this.userData.userId,
            }),
        }).json();
        return response;
    }

    createOrderItem(orderItem) {
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderItem", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                total: orderItem.total,
                discountId: orderItem.discountId,
                discount: orderItem.discount,
                orderId: orderItem.orderId,
                itemListId: orderItem.itemListId,
                createdBy: this.userData.userId,
                updatedBy: this.userData.userId,
            }),
        }).json();
        return response;
    }

    updateNumber(orderId) {
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderNumber/" + this.userData.userId + "/" + orderId, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
        }).json();
        return response;
    }

    updatedAmount(order) {
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderAmount/" + order.orderId, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                amount: order.amount,
            }),
        }).json();
        return response;
    }

    updatedLink(orderId) {
        let order = this.getOrderById(orderId);
        let getOrderItem = this.getOrderItemListById(orderId);
        let detailArray = { ...getOrderItem };
        let index = Object.keys(detailArray).toString();
        let detail = detailArray[index];
        let appointment = order.appointment ? order.appointment : "-";
        //console.log("order", order);
        let datail =
            "===ข้อมูลออเดอร์===" +
            "\n วันที่ : " +
            order.orderDateTime +
            "\n ชื่อ : " +
            order.name +
            "\n ที่อยู่: " +
            order.address +
            ", " +
            order.zipcode +
            "\n เฟซบุ๊ก: " +
            order.facebook +
            "\n เบอร์โทรศัทพ์: " +
            order.mobilePhone +
            "\n สั่งซื้อ : " +
            detail.itemList +
            "\n รวม : " +
            Number(order.amount).toFixed(2) +
            "\n จัดส่ง : " +
            order.delivery +
            "\n สะดวกรับ : " +
            appointment +
            "\n รายละเอียดเพิ่มเติม : " +
            Constants.URL +
            "/" +
            orderId;

        //console.log("datail", datail);

        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderLink/" + orderId, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                link: datail,
            }),
        }).json();
        return response;
    }

    updatePaymentStatus(orderId) {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/orderPaymentStatus/" + orderId, {
            method: "PUT",
            body: JSON.stringify({
                paymentStatusId: 2,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    updateProduct(orderId) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderProduct/" + orderId, requestOptions).json();
        return res;
    }

    async saveOrder() {
        let state = { submitted: true };

        if (this.state.order.name.trim() && this.state.selectedPostcode && this.state.selectedDelivery && this.state.selectedPaymentType && this.state.order.mobilePhone && this.state.order.mobilePhone.length === 10 && this.state.items.length > 0) {
            console.log("saveOrder >>>>items", this.state.items);
            console.log("saveOrder >>>>selectedDelivery", this.state.selectedDelivery);
            console.log("saveOrder >>>>deliveryId", this.state.selectedDelivery.deliveryId);
            console.log("saveOrder >>>>selectedPaymentType", this.state.selectedPaymentType);
            console.log("saveOrder >>>>selectedPostcode", this.state.selectedPostcode);
            console.log("saveOrder >>>>paymentType", this.state.selectedPaymentType.paymentId);
            console.log("saveOrder >>>>items.length", this.state.items.length);
            console.log("saveOrder >>>>mobilePhone.length", this.state.order.mobilePhone.length);
            console.log("saveOrder >>>>appointment", this.state.order.appointment);

            //const data = this.getOrderDupCustomer(this.state.order.name.trim());
            //console.log("dupCustomer >>>>", this.state.order.name.trim(), data);

            const data = this.getOrderDupMobile(this.state.order.mobilePhone.trim());
            console.log("dupMobile >>>>", this.state.order.mobilePhone.trim(), data);

            if (data) {
                // this.toast.show({ severity: "warn", summary: "เพิ่มออเดอร์สินค้า", detail: "มีชื่อออเดอร์นี้แล้ว", life: 3000 });
                console.log("dupOrder >>>>", data.orderNumber);
                this.setState({
                    confirmOrderDialog: true,
                    orderDup: data,
                });
            } else {
                let createOrder = await this.createOrder();

                console.log("saveOrder >>>> createOrder:", createOrder);
                console.log("saveOrder >>>> path:", this.state.data.path);
                if (this.state.data.path) {
                    await this.updatePaymentStatus(this.state.order.orderId);
                }

                let items = [...this.state.items];

                console.log("saveOrder >>>> items:", items);

                for (let item of items) {
                    let discountId = 3;
                    let discount = 0;
                    if (item.discountId != null) {
                        discountId = item.discountId;
                        discount = item.discount;
                    }
                    const orderItem = {
                        total: item.total,
                        discountId: discountId,
                        discount: discount,
                        itemListId: item.itemListId,
                        orderId: createOrder.id,
                    };

                    let createOrderItem = await this.createOrderItem(orderItem);

                    console.log("saveOrder >>>> createOrderItem:", createOrderItem);

                    let getOrderSumByOrderId = this.getOrderSumByOrderId(createOrder.id);

                    console.log("saveOrder >>>> getOrderSumByOrderId:", getOrderSumByOrderId);

                    // let totalArray = [...getOrderSumByOrderId];

                    // console.log("saveOrder >>>> totalArray:", totalArray);

                    // let index = Object.keys(totalArray).toString();

                    // let amount = totalArray[index].amount;

                    let amount = Number(getOrderSumByOrderId.amount).toFixed(2);

                    console.log("saveOrder >>>> amount ", amount);

                    let updateOrder = {
                        amount: amount,
                        orderId: createOrder.id,
                    };

                    console.log("saveOrder >>>> updateOrderAmount:", updateOrder);

                    await this.updatedAmount(updateOrder);
                    await this.updateNumber(createOrder.id);
                    await this.updatedLink(createOrder.id);
                    await this.updateProduct(createOrder.id);
                }

                state = {
                    ...state,
                    selectedDelivery: null,
                    selectedDiscountType: null,
                    selectedPaymentType: null,
                    selectedPostcode: null,
                    selectedProduct: null,
                    order: this.emptOrder,
                    items: [],
                    item: this.emptItem,
                    submitted: false,
                    editingRows: {},
                    file: "",
                    data: [],
                    progress: 0,
                };
                this.toast.show({ severity: "success", summary: "เพิ่มออเดอร์สินค้า", detail: "บันทึกสำเร็จ", life: 3000 });
                this.refresh();
            }
        } else {
            console.log("saveOrder >>>>items", this.state.items);
            console.log("saveOrder >>>>selectedDelivery", this.state.selectedDelivery);
            console.log("saveOrder >>>>selectedPaymentType", this.state.selectedPaymentType);
            console.log("saveOrder >>>>selectedPostcode", this.state.selectedPostcode);
            console.log("saveOrder >>>>items.length", this.state.items.length);
            console.log("saveOrder >>>>mobilePhone.length", this.state.order.mobilePhone.length);

            this.toast.show({ severity: "warn", summary: "เพิ่มออเดอร์สินค้า", detail: "โปรดระบุให้ครบถ้วน", life: 3000 });
        }
        this.setState(state);
    }

    async confirmOrder() {
        let createOrder = await this.createOrder();

        console.log("saveOrder >>>> createOrder:", createOrder);
        console.log("saveOrder >>>> path:", this.state.data.path);
        if (this.state.data.path) {
            await this.updatePaymentStatus(this.state.order.orderId);
        }

        let items = [...this.state.items];

        console.log("saveOrder >>>> items:", items);

        for (let item of items) {
            let discountId = 3;
            let discount = 0;
            if (item.discountId != null) {
                discountId = item.discountId;
                discount = item.discount;
            }
            const orderItem = {
                total: item.total,
                discountId: discountId,
                discount: discount,
                itemListId: item.itemListId,
                orderId: createOrder.id,
            };

            let createOrderItem = await this.createOrderItem(orderItem);

            console.log("saveOrder >>>> createOrderItem:", createOrderItem);

            let getOrderSumByOrderId = this.getOrderSumByOrderId(createOrder.id);

            console.log("saveOrder >>>> getOrderSumByOrderId:", getOrderSumByOrderId);

            let amount = Number(getOrderSumByOrderId.amount).toFixed(2);

            console.log("saveOrder >>>> amount ", amount);

            let updateOrder = {
                amount: amount,
                orderId: createOrder.id,
            };

            console.log("saveOrder >>>> updateOrderAmount:", updateOrder);

            await this.updatedAmount(updateOrder);
            await this.updateNumber(createOrder.id);
            await this.updatedLink(createOrder.id);
            await this.updateProduct(createOrder.id);
        }

        this.setState({
            selectedDelivery: null,
            selectedDiscountType: null,
            selectedPaymentType: null,
            selectedPostcode: null,
            selectedProduct: null,
            order: this.emptOrder,
            items: [],
            item: this.emptItem,
            submitted: false,
            editingRows: {},
            file: "",
            data: [],
            progress: 0,
            confirmOrder: false,
        });
        this.toast.show({ severity: "success", summary: "เพิ่มออเดอร์สินค้า", detail: "บันทึกสำเร็จ", life: 3000 });
        this.refresh();
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    cancelOrder() {
        this.props.history.push("/order");
        /*console.log("blockedForm", this.state.blockedForm)
        this.setState({
            blockedForm: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    blockedForm: false
                });

                console.log("blockedForm", this.state.blockedForm)
                if (!this.state.blockedForm) {
                    this.props.history.push("/order");
                }
            }, 3000);
        });
        */
    }

    clearOrder() {
        let state = { submitted: true };
        state = {
            ...state,
            selectedDelivery: "",
            selectedDiscountType: "",
            selectedPaymentType: "",
            selectedPostcode: "",
            order: this.emptOrder,
            items: [],
            item: this.emptItem,
            selectedProduct: "",
            submitted: false,
            editingRows: {},
        };
        this.setState(state);
    }

    deleteOrderItem(item) {
        let items = [...this.state.items];
        items.pop(item);
        this.setState({ items });
        this.toast.show({ severity: "success", summary: "เพิ่มสินค้า", detail: "ลบสำเร็จ", life: 3000 });
    }

    imgUploadHandler(e) {
        console.log("file", this.state.file);
        const file = e.files[0];
        //const [file] = files;
        console.log("file", file);
        this.uploadImg(file);
        this.setState({
            file: file,
            progress: 0,
        });
    }

    uploadImg(img) {
        console.log("img", img);
        let formData = new FormData();
        formData.append("file", img);

        axios
            .post(Constants.APIURL + "/uploadSlip", formData, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + "%";
                    this.setState({
                        progress: progress,
                        file: "",
                    });
                },
            })
            .then((res) => {
                console.log(res);
                const data = { name: res.data.name, path: Constants.APIURL + "/slip" + res.data.path };
                this.setState({ data: data });
                this.upload.clear();
            })
            .catch((err) => console.log(err));
    }

    formatDateToDateSql(data) {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            if (day < 10) {
                day = "0" + day;
            }

            return data.getFullYear() + "-" + month + "-" + day;
        }
    }

    onRowEditInit(event) {
        console.log("onRowEditInit >>>> event.index: ", event.index);
        this.originalRows[event.index] = { ...this.state.items[event.index] };
    }

    onRowEditCancel(event) {
        console.log("onRowEditCancel >>>> event.index: ", event.index);
        let itemsEdit = this.state.items;

        itemsEdit[event.index] = this.originalRows[event.index];

        this.setState((state, props) => ({
            items: Object.values(itemsEdit),
        }));

        delete this.originalRows[event.index];
    }

    onRowEditChange(event) {
        this.setState({ editingRows: event.data });
    }

    onProductSelect(e) {
        this.setState({ selectedProduct: e.value }, () => {
            let items = [...this.state.items];
            let item = { ...this.state.item };
            let selectedProduct = this.state.selectedProduct;

            console.log("onProductSelect >>>> selectedProduct : ", selectedProduct);

            let itemsBySelectedProduct = items.filter((val) => {
                if (val.itemListId === selectedProduct.itemListId) {
                    return selectedProduct;
                } else {
                    return "";
                }
            });

            let length = Object.keys(itemsBySelectedProduct).length;

            if (length === 1) {
                this.toast.show({ severity: "warn", summary: "เพิ่มสินค้า", detail: "มีสินค้านี้แล้ว" + this.state.selectedProduct.name, life: 3000 });
            } else {
                console.log("onProductSelect >>>> itemListId : ", this.state.selectedProduct.itemListId);

                item.itemListId = this.state.selectedProduct.itemListId;
                item.itemId = this.state.selectedProduct.itemId;
                item.itemNumber = this.state.selectedProduct.itemNumber;
                item.name = this.state.selectedProduct.name;
                item.price = this.state.selectedProduct.price;
                item.total = 1;
                items.push(item);

                this.setState({ items });
                this.toast.show({ severity: "info", summary: "เพิ่มสินค้า", detail: "เลือกสินค้าสำเร็จ" + this.state.selectedProduct.name, life: 3000 });
            }
            this.op.hide();
        });
    }

    onDeliveryChange(e) {
        this.setState({ selectedDelivery: e.value });
    }

    async onPaymentTypeChange(e) {
        this.setState({ selectedPaymentType: e.value });
        const delivery = await this.getDelivery(e.value);
        this.setState({
            delivery: delivery,
            disableDelivery: false,
        });
    }

    onPostcodeChange(e) {
        this.setState({ selectedPostcode: e.value });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || "";
        let order = { ...this.state.order };
        order[`${name}`] = val;

        this.setState({ order });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let order = { ...this.state.order };
        order[`${name}`] = val;

        this.setState({ order });
    }

    onEditorValueChange(itemKey, props, value) {
        let updatedItems = [...props.value];
        updatedItems[props.rowIndex][props.field] = value;
        //this.setState({ [`${itemKey}`]: updatedItems });

        this.setState((state, props) => ({
            [`${itemKey}`]: updatedItems,
        }));
    }

    onEditorValueDiscountChange(itemKey, props, value) {
        let updatedItems = [...props.value];
        updatedItems[props.rowIndex][props.field] = value;
        //this.setState({ [`${itemKey}`]: updatedItems });

        this.setState((state, props) => ({
            [`${itemKey}`]: updatedItems,
        }));

        if (value === 1) {
            this.setState({ disableDiscount: false });
        } else if (value === 2) {
            this.setState({ disableDiscount: false });
        } else {
            this.setState({ disableDiscount: true });
        }
    }

    imageBody(rowData) {
        return <img src={rowData.img} alt={rowData.img} className="product-image" width="32" />;
    }

    priceBody(rowData) {
        return this.formatCurrency(rowData.price);
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    inputTextEditor(itemKey, props, field) {
        return <InputText type="text" className="p-inputtext-sm" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(itemKey, props, e.target.value)} />;
    }

    inputDiscountEditor(itemKey, props, field) {
        return <InputText type="text" className="p-inputtext-sm" value={props.rowData[field]} disabled={this.state.disableDiscount} onChange={(e) => this.onEditorValueChange(itemKey, props, e.target.value)} />;
    }

    itemTotalEditor(itemKey, props) {
        // return this.inputTextEditor(itemKey, props, "total");
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวน</span>
                <InputNumber value={props.rowData["total"]} onValueChange={(e) => this.onEditorValueChange(itemKey, props, e.value)} mode="decimal" showButtons min={1} max={1000} />
            </React.Fragment>
        );
    }

    itemDiscountEditor(itemKey, props) {
        return (
            <React.Fragment>
                <span className="p-column-title">ส่วนลด</span>
                {this.inputDiscountEditor(itemKey, props, "discount")}
            </React.Fragment>
        );
    }

    itemDiscountTypeEditor(itemKey, props) {
        //return this.inputTextEditor(itemKey, props, "discount");
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทส่วนลด</span>
                <MultiStateCheckbox value={props.rowData["discountId"]} options={this.options} optionValue="value" onChange={(e) => this.onEditorValueDiscountChange(itemKey, props, e.target.value)} />
                {props.rowData["discountId"] === 1 && <label>ส่วนลดเปอร์เซ็น</label>}
                {props.rowData["discountId"] === 2 && <label>ส่วนลดเงินบาท</label>}
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.deleteOrderItem(rowData)} />
            </React.Fragment>
        );
    }

    itemNumberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสสินค้า</span>
                <span>{rowData.itemNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อสินค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    priceBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ราคาขาย</span>
                <span>{rowData.price}</span>
            </React.Fragment>
        );
    }

    totalBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวน</span>
                <span>{rowData.total}</span>
            </React.Fragment>
        );
    }

    discountTypeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทส่วนลด</span>
                <MultiStateCheckbox value={rowData.discountId} options={this.options} optionValue="value" />
            </React.Fragment>
        );
    }

    discountBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ส่วนลด</span>
                <span>{rowData.discount}</span>
            </React.Fragment>
        );
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="กลับไปหน้ารายการ" icon="pi pi-chevron-left" className="p-button-sm p-button-secondary p-mr-2" style={{ width: "160px" }} onClick={this.cancelOrder} />
            </React.Fragment>
        );
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-search"
                    label={"เลือกสินค้า"}
                    onClick={(e) => this.op.toggle(e)}
                    //onClick={(e) => this.onItemSelecte(e)}
                    aria-haspopup
                    aria-controls="overlay_panel"
                    className="p-button-sm p-button-success"
                />
                <Button label="บันทึก" icon="pi pi-save" className="p-button-sm p-button-info" style={{ width: "100px", marginLeft: "7px" }} onClick={this.saveOrder} />
                <Button label="ล้างข้อมูล" icon="pi pi-undo" className="p-button-sm p-button-secondary" style={{ width: "120px", marginLeft: "7px" }} onClick={this.clearOrder} />
                {/* <Button
          label="ยกเลิก"
          icon="pi pi-times"
          className="p-button-danger"
          style={{ width: "100px", marginLeft: "7px" }}
          onClick={this.cancelOrder}
        /> */}
            </React.Fragment>
        );
    }

    hideConfirmOrderDialog() {
        this.setState({ confirmOrderDialog: false });
    }

    render() {
        const confirmOrderDialogFooter = (
            <React.Fragment>
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-sm p-button-text" onClick={this.hideConfirmOrderDialog} />
                <Button label="ยื่นยัน" icon="pi pi-check" className="p-button-sm p-button-text" onClick={this.confirmOrder} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />

                <div className="card">
                    <h3>เพิ่มออเดอร์สินค้า </h3>
                </div>

                <div className="card form-demo">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    value={this.state.order.name}
                                    onChange={(e) => this.onInputChange(e, "name")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.order.name,
                                    })}
                                />
                                <label htmlFor="name">ชื่อ-นามสกุล</label>
                            </span>
                            {this.state.submitted && !this.state.order.name && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="facebook"
                                    value={this.state.order.facebook}
                                    onChange={(e) => this.onInputChange(e, "facebook")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.order.facebook,
                                    })}
                                />
                                <label htmlFor="name">เฟซบุ๊ก</label>
                            </span>
                            {this.state.submitted && !this.state.order.facebook && <small className="p-error">Facebook is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputTextarea
                                    rows={2}
                                    cols={30}
                                    id="address"
                                    value={this.state.order.address}
                                    onChange={(e) => this.onInputChange(e, "address")}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.order.address,
                                    })}
                                />
                                <label htmlFor="address">ที่อยู่</label>
                            </span>
                            {this.state.submitted && !this.state.order.address && <small className="p-error">Address is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Dropdown
                                    value={this.state.selectedPostcode}
                                    options={this.state.postcode}
                                    onChange={this.onPostcodeChange}
                                    optionLabel="postcode"
                                    showClear
                                    filter
                                    filterBy="postcode"
                                    placeholder="รหัสไปรณีย์"
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedPostcode,
                                    })}
                                />

                                {this.state.submitted && !this.state.selectedPostcode && <small className="p-error">Zip Code is required.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="mobilePhone"
                                    value={this.state.order.mobilePhone}
                                    onChange={(e) => this.onInputChange(e, "mobilePhone")}
                                    maxLength="10"
                                    keyfilter="num"
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && this.state.order.mobilePhone && this.state.order.mobilePhone.length !== 9 && this.state.order.mobilePhone.length !== 10,
                                    })}
                                />
                                <label htmlFor="mobilePhone">เบอร์โทรศัทพ์</label>
                                {this.state.submitted && this.state.order.mobilePhone && this.state.order.mobilePhone.length !== 9 && this.state.order.mobilePhone.length !== 10 && <small className="p-error">Mobile Phone is 9 or 10 digit.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Dropdown
                                    id="paymentId"
                                    value={this.state.selectedPaymentType}
                                    options={this.state.paymentType}
                                    onChange={this.onPaymentTypeChange}
                                    optionLabel="payment"
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedPaymentType,
                                    })}
                                />
                                <label htmlFor="paymentId">การชำระเงิน</label>
                                {this.state.submitted && !this.state.selectedPaymentType && <small className="p-error">Payment is required.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Dropdown
                                    id="deliveryId"
                                    value={this.state.selectedDelivery}
                                    options={this.state.delivery}
                                    onChange={this.onDeliveryChange}
                                    optionLabel="delivery"
                                    disabled={this.state.disableDelivery}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedDelivery,
                                    })}
                                />
                                <label htmlFor="deliveryId">การจัดส่ง</label>
                                {this.state.submitted && !this.state.selectedDelivery && <small className="p-error">Delivery is required.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Calendar id="appointment" value={this.state.order.appointment} onChange={(e) => this.onInputChange(e, "appointment")} dateFormat="dd-mm-yy" />
                                <label htmlFor="address">วันที่สะดวกรับ </label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <FileUpload name="slip" ref={(el) => (this.upload = el)} accept="image/*" customUpload={true} uploadHandler={this.imgUploadHandler} mode="basic" chooseLabel="Upload Slip" />
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-lg-6" style={{ marginTop: "5px" }}>
                            <Image src={this.state.data.path} alt={this.state.data.name} width="100" preview />
                        </div>
                    </div>

                    <div className="datatable-responsive-demo">
                        <DataTable
                            className="p-datatable-responsive-demo p-datatable-sm"
                            dataKey="itemListId"
                            value={this.state.items}
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            editMode="row"
                            editingRows={this.state.editingRows}
                            onRowEditInit={this.onRowEditInit}
                            onRowEditChange={this.onRowEditChange}
                            onRowEditCancel={this.onRowEditCancel}
                            style={{ fontSize: "11px" }}
                        >
                            <Column field="itemNumber" header="รหัสสินค้า" body={this.itemNumberBodyTemplate} />
                            <Column field="name" header="ชื่อสินค้า" body={this.nameBodyTemplate} />
                            <Column field="price" header="ราคาขาย" body={this.priceBodyTemplate} />
                            <Column field="total" header="จำนวน" body={this.totalBodyTemplate} required editor={(props) => this.itemTotalEditor("orderItems", props)} />
                            <Column field="discountId" header="ประเภทส่วนลด" body={this.discountTypeBodyTemplate} required editor={(props) => this.itemDiscountTypeEditor("discountId", props)} />
                            <Column field="discount" header="ส่วนลด" body={this.discountBodyTemplate} required editor={(props) => this.itemDiscountEditor("discount", props)} />
                            <Column header="แก้ไข" rowEditor headerStyle={{ width: "5rem" }} />
                            <Column header="ลบ" headerStyle={{ width: "5rem" }} body={this.actionBodyTemplate} />
                        </DataTable>
                    </div>

                    <OverlayPanel ref={(el) => (this.op = el)} showCloseIcon id="overlay_panel" style={{ width: "450px" }} className="overlaypanel-demo">
                        <DataTable value={this.state.products} selectionMode="single" paginator rows={5} selection={this.state.selectedProduct} onSelectionChange={this.onProductSelect} style={{ fontSize: "11px" }}>
                            <Column field="name" header="ชื่อสินค้า" sortable />
                            <Column field="img" header="รูปภาพ" body={this.imageBody} />
                            <Column
                                field="price"
                                header="ราคา"
                                sortable
                                //body={this.priceBody}
                            />
                        </DataTable>
                    </OverlayPanel>
                </div>

                <Dialog visible={this.state.confirmOrderDialog} style={{ width: "450px" }} header="Confirm" modal footer={confirmOrderDialogFooter} onHide={this.hideConfirmOrderDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                        {this.state.order && (
                            <span>
                                มีเบอร์โทรศัทพ์นี้แล้ว <b>{this.state.order.mobilePhone}</b>
                                <br></br>
                                <b>
                                    {this.state.orderDup.map((val, key) => (
                                        <>
                                            รหัสออเดอร์{" "}
                                            <b>
                                                {" "}
                                                {val.order_number} - {val.name}
                                            </b>
                                        </>
                                    ))}
                                </b>
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        );
    }
}
