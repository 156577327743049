import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Constants from "../constants/Constant";

import "../styles/css/Table.css";

export class FormTrackingList extends Component {
    // userData;
    // userStore;

    constructor(props) {
        super(props);

        this.state = {
            stores: [],
            orders: [],
            status: [],
            delivery: [],
            deleteOrderDialog: false,
            deleteOrdersDialog: false,
            selectedOrders: null,
            selectedDelivery: null,
            selectedStatus: null,
            selectedDate: null,
            selectedAppointment: null,
            globalFilter: "",
            copied: false,
        };

        this.userData = {};
        this.userStore = {};
        this.numberBodyTemplate = this.numberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.addressBodyTemplate = this.addressBodyTemplate.bind(this);
        this.amountBodyTemplate = this.amountBodyTemplate.bind(this);
        this.deliveryBodyTemplate = this.deliveryBodyTemplate.bind(this);
        this.trackingBodyTemplate = this.trackingBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.userBodyTemplate = this.userBodyTemplate.bind(this);
        this.linkBodyTemplate = this.linkBodyTemplate.bind(this);
        this.pocketBodyTemplate = this.pocketBodyTemplate.bind(this);
        this.saleItemBodyTemplate = this.saleItemBodyTemplate.bind(this);
        this.dateBodyTemplate = this.dateBodyTemplate.bind(this);
        this.getDelivery = this.getDelivery.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getStore = this.getStore.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.reset = this.reset.bind(this);
        this.onDeliveryChange = this.onDeliveryChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.filterDate = this.filterDate.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.formatDateValue = this.formatDateValue.bind(this);
        this.onAppointmentChange = this.onAppointmentChange.bind(this);
        this.appointmentBodyTemplate = this.appointmentBodyTemplate.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const orders = await this.getOrder();
        const status = await this.getStatus();
        const delivery = await this.getDelivery();

        this.setState({
            orders: orders,
            delivery: delivery,
            status: status,
        });
    }


    getOrder() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersWaitingTrackingByStoreId/" + this.userStore.storeId , requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getDelivery() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/deliverName/", requestOptions).json();
        return res;
    }

    getStatus() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/statusName/", requestOptions).json();
        return res;
    }

    getStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/store/", requestOptions).json();
        return res;
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    reset() {
        this.setState({
            selectedDate: null,
            selectedDelivery: null,
            selectedStatus: null,
            globalFilter: "",
        });

        this.dt.reset();
    }

    onStatusChange(e) {
        this.dt.filter(e.value, "status", "equals");
        this.setState({ selectedStatus: e.value });
    }

    onDeliveryChange(e) {
        this.dt.filter(e.value, "delivery", "equals");
        this.setState({ selectedDelivery: e.value });
    }

    onDateChange(e) {
        this.dt.filter(e.value, "orderDateTime", "custom");
        this.setState({ selectedDate: e.value });
        //console.log(e.value);
    }

    onAppointmentChange(e) {
        this.dt.filter(e.value, "appointment", "custom");
        this.setState({ selectedAppointment: e.value });
    }



    filterDate(value, filter) {
        console.log("filterDate value", this.formatDateValue(value));
        console.log("filterDate filter",this.formatDate(filter));


        if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        
        return this.formatDateValue(value) === this.formatDate(filter);
    }

    formatDateValue(value) {
        return value.substring(1, value.indexOf(' '));
    }


    formatDate(data) {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            // if (day < 10) {
            //     day = "0" + day;
            // }

            return day + "-" + month + "-" + data.getFullYear();
        }
    }

    dateBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">วันที่</span>
                <span>{rowData.orderDateTime}</span>
            </React.Fragment>
        );
    }

    numberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสออเดอร์</span>
                <span>{rowData.orderNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อลูกค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    addressBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ที่อยู่</span>
                <span>
                    {rowData.address} {rowData.zipcode}
                </span>
            </React.Fragment>
        );
    }

    amountBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ยอดเงิน</span>
                <span>{rowData.amount}</span>
            </React.Fragment>
        );
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    saleItemBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สินค้า</span>
                <span>{rowData.product}</span>
            </React.Fragment>
        );
    }

    deliveryBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">การจัดส่ง</span>
                <span> {rowData.delivery}</span>
            </React.Fragment>
        );
    }

    trackingBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัส tracking</span>
                <span>{rowData.trackingNumber}</span>
            </React.Fragment>
        );
    }

    statusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สถานะ</span>
                <span>{rowData.status}</span>
            </React.Fragment>
        );
    }

    userBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">แอดมิน</span>
                <span>{rowData.createdByName}</span>
            </React.Fragment>
        );
    }

    linkBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ลิงค์</span>
                <span>{rowData.link}</span>
            </React.Fragment>
        );
    }

    facebookBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">เฟซบุ๊ก</span>
                <span>{rowData.facebook}</span>
            </React.Fragment>
        );
    }

    mobilephoneBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">เบอร์โทรศัทพ์</span>
                <span>{rowData.mobilePhone}</span>
            </React.Fragment>
        );
    }

    copyBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ลิงค์</span>
                <CopyToClipboard
                    text={rowData.link}
                    //onCopy={() => this.setState({ copied: true })}
                >
                    <Button icon="pi pi-copy" className="p-button-sm p-button-text" onClick={this.hideDeleteOrdersDialog} />
                </CopyToClipboard>
            </React.Fragment>
        );
    }

    paymentTypeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทการชำระเงิน</span>
                <span>{rowData.payment}</span>
            </React.Fragment>
        );
    }

    paymentStatusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สถานะการชำระเงิน</span>
                <span>{rowData.paymentStatus}</span>
            </React.Fragment>
        );
    }

    appointmentBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สะดวกรับ</span>
                <span> {rowData.appointment}</span>
            </React.Fragment>
        );
    }

    pocketBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสกระเป๋า</span>
                <span>{rowData.pocketNumber}</span>
            </React.Fragment>
        );
    }

    render() {
        const dateFilter = <Calendar value={this.state.selectedDate} onChange={this.onDateChange} dateFormat="dd-mm-yy" className="p-column-filter" />;

        const deliveryFilter = <Dropdown value={this.state.selectedDelivery} options={this.state.delivery} onChange={this.onDeliveryChange} itemTemplate={this.deliveryItemTemplate} optionLabel="delivery" optionValue="delivery" className="p-column-filter" showClear />;

        const appointmentFilter = <Calendar value={this.state.selectedAppointment} onChange={this.onAppointmentChange} dateFormat="dd-mm-yy" className="p-column-filter" />;

        const header = (
            <div className="table-header">
                <Button type="button" label="Clear" className="p-button-sm p-button-outlined" icon="pi pi-filter-slash" onClick={this.reset} />
            </div>
        );

        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="card">
                    <h3>รายการรอบันทึกเลขพัสดุ </h3>
                </div>

                <div className="card datatable-responsive-demo">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}></Toolbar>
                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="orderId"
                        value={this.state.orders}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={200}
                        rowsPerPageOptions={[100, 200, 500, 1000]}
                        onSelectionChange={(e) => this.setState({ selectedOrders: e.value })}
                        ref={(el) => (this.dt = el)}
                        style={{ fontSize: "11px" }}
                        header={header}
                    >
                        <Column field="orderDateTime" header="วันที่" body={this.dateBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={this.filterDate} />
                        <Column field="orderNumber" header="รหัสออเดอร์" body={this.numberBodyTemplate} filter filterField="orderNumber" filterMatchMode="contains" sortable />
                        <Column field="name" header="ชื่อลูกค้า" body={this.nameBodyTemplate} filter filterField="name" filterMatchMode="contains" sortable />
                        <Column field="facebook" header="เฟซบุ๊ก" body={this.facebookBodyTemplate} filter filterField="facebook" filterMatchMode="contains" sortable />
                        <Column field="address" header="ที่อยู่" body={this.addressBodyTemplate} filter filterField="address" filterMatchMode="contains" sortable />
                        <Column field="mobilePhone" header="เบอร์โทรศัทพ์" body={this.mobilephoneBodyTemplate} filter filterField="mobilePhone" filterMatchMode="contains" sortable />
                        <Column field="delivery" header="การจัดส่ง" body={this.deliveryBodyTemplate} filterElement={deliveryFilter} filter sortable />
                        <Column field="pocketNumber" header="รหัสกระเป๋า" body={this.pocketBodyTemplate} filter filterField="pocketNumber" filterMatchMode="contains" sortable />
                        <Column field="appointment" header="สะดวกรับ" body={this.appointmentBodyTemplate} filter filterElement={appointmentFilter} filterFunction={this.filterDate} sortable />
                        <Column field="amount" header="ยอดเงิน" body={this.amountBodyTemplate} filter filterField="amount" filterMatchMode="contains" sortable />
                        <Column field="product" header="สินค้า" body={this.saleItemBodyTemplate} sortable />
                        <Column field="payment" header="ประเภทการชำระเงิน" body={this.paymentTypeBodyTemplate} sortable />
                        <Column field="paymentStatus" header="สถานะการชำระเงิน" body={this.paymentStatusBodyTemplate} sortable />
                        <Column field="createdByName" header="แอดมิน" body={this.userBodyTemplate} />
                    </DataTable>
                </div>

            </div>
        );
    }
}
