import React, { Component } from "react";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from "primereact/toast";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormReturnTracking extends Component {
    // userData;
    // userStore;

    emptFilter = {
        storeId: "",
        dateForm: "",
        dateTo: "",
        orderNumber: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            order: {},
            trackingNumber: "",
            show: false,
            notfound: false,
            disableSaveTrackingNumber: true,
        };
        this.userData = {};
        this.userStore = {};
        this.updateStock = this.updateStock.bind(this);
        this.getTrackingNumber = this.getTrackingNumber.bind(this);
        this.getOrderByTrackingNumber = this.getOrderByTrackingNumber.bind(this);
        this.onTrackingNumberChange = this.onTrackingNumberChange.bind(this);
    }

    async componentDidMount() {
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));
    }

    // getOrderByOrderNumber(number) {
    //     const fetchs = require("sync-fetch");
    //     const requestOptions = {
    //         method: "GET",
    //         headers: { "Content-Type": "application/json" },
    //     };
    //     const res = fetchs(Constants.APIURL + "/ordersByOrderNumber/" + number + "/4", requestOptions);
    //     if (res.ok) {
    //         this.setState({
    //             show: true,
    //             notfound: false,
    //             disableSaveTrackingNumber: false,
    //         });
    //         return res.json();
    //     } else {
    //         this.setState({
    //             show: false,
    //             notfound: true,
    //             disableSaveTrackingNumber: true,
    //         });
    //         return null;
    //     }
    // }

    // getOrderNumber(event) {
    //     console.log("query", event.query);
    //     const order = this.getOrderByOrderNumber(event.query);
    //     console.log("order", order);
    //     this.setState({
    //         order: order,
    //     });
    //     console.log("order", this.state.order);
    // }

    getOrderByTrackingNumber(number) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByTrackingNumber/" + number, requestOptions);
        if (res.ok) {
            this.setState({
                show: true,
                notfound: false,
                disableSaveTrackingNumber: false,
            });
            return res.json();
        } else {
            this.setState({
                show: false,
                notfound: true,
                disableSaveTrackingNumber: true,
            });
            return null;
        }
    }

    getTrackingNumber(event) {
        console.log("query", event.query);
        const order = this.getOrderByTrackingNumber(event.query);
        console.log("order", order);
        this.setState({
            order: order,
        });
        console.log("order", this.state.order);
    }

    updateStock() {
        let order = { ...this.state.order };
        console.log("order", order);

        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        fetchs(Constants.APIURL + "/itemStock/return/" + order.orderId, requestOptions).json();

        this.updateStatus(order);

        this.setState({
            order: {},
            trackingNumber: "",
            show: false,
            notfound: false,
            disableSaveTrackingNumber: true,
        });

        this.toast.show({ severity: "success", summary: "บันทึกคืนเลขพัสดุ", detail: "บันทึกสำเร็จ", life: 3000 });
    }

    updateStatus(order) {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                statusId: 6,
                updatedBy: this.userData.userId,
            }),
        };

        fetch(Constants.APIURL + "/orderStatus/" + order.orderId, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // onOrderNumberChange(e) {
    //     const val = (e.target && e.target.value) || "";
    //     console.log("value", val);
    //     this.setState({
    //         orderNumber: val,
    //     });
    // }

    onTrackingNumberChange(e) {
        const val = (e.target && e.target.value) || "";
        console.log("value", val);
        this.setState({
            trackingNumber: val,
        });
    }


    cancel() {
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="card">
                    <h1>บันทึกคืนพัสดุ</h1>
                </div>

                <div className="card">
                    <div className="p-field p-grid">
                        <label htmlFor="trackingNumber" className="p-col-fixed" style={{ width: "100px" }}>
                            เลขที่พัสดุ
                        </label>
                        <div className="p-col">
                            {/* <InputText */}
                            <AutoComplete id="trackingNumber" type="text" value={this.state.trackingNumber} completeMethod={this.getTrackingNumber} onChange={this.onTrackingNumberChange} autoFocus />
                        </div>
                    </div>
                    <div className="p-field p-grid"></div>
                    <div className="p-field p-grid">
                        <label htmlFor="save" className="p-col-fixed" style={{ width: "100px" }}></label>
                        <div className="p-col">
                            <Button id="save" label="บันทึก" onClick={this.updateStock} disabled={this.state.disableSaveTrackingNumber} />

                            <Button label="ยกเลิก" className="p-button-sm p-button-info" onClick={this.cancel} style={{ marginLeft: "12px" }} />
                        </div>
                    </div>
                </div>
                {this.state.show && (
                    <div className="card">
                        <div>
                            รหัสออเดอร์ : {this.state.order.orderNumber}
                            <br />
                            ชื่อลูกค้า : {this.state.order.name}
                            <br />
                            ที่อยู่ : {this.state.order.address}
                            <br />
                            เบอร์โทรศัทพ์ : {this.state.order.mobilePhone}
                            <br />
                            ยอดขาย : {this.state.order.amount} บาท
                            <br />
                            เลขที่พัสดุ : {this.state.order.trackingNumber} บาท
                            <br />
                            สถานะ : {this.state.order.status}
                        </div>
                    </div>
                )}
                {this.state.notfound && (
                    <div className="card">
                        <div>data not found!</div>
                    </div>
                )}
            </div>
        );
    }
}
