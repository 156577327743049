import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Constants from "./constants/Constant";
import "./styles/css/Tooltip.css";

export const AppTopbar = (props) => {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [store] = useState(JSON.parse(localStorage.getItem("store")));
    const [selectedStore, setSelectedStore] = useState([]);
    const [stores, setStores] = useState([]);
    //let history = useHistory();

    useEffect(() => {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/stores", requestOptions);
        const data = res.json();
        setStores(data);
        setSelectedStore(store);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onStoresChange = (e) => {
        localStorage.removeItem("store");
        localStorage.setItem("store", JSON.stringify(e.value));
        setSelectedStore(e.value);
        window.location.href = "/home";
    };

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("store");
        window.location.href = "/";
    };

    return (
        <div className="layout-topbar clearfix">
            <button type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                <span className="pi pi-bars" />
            </button>

            <div className="layout-topbar-icons">
                {user.role === Constants.ADMIN && <Dropdown value={selectedStore} options={stores} onChange={onStoresChange} optionLabel="name" placeholder="เลือกร้านค้า" style={{ height: "27px" }} />}

                <Button type="button" className="p-link" onClick={handleLogout} tooltip="ออกจากระบบ" tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15 }} style={{ marginBottom: "2px" }}>
                    <span className="layout-topbar-icon pi pi-fw pi-power-off" />
                </Button>
            </div>
        </div>
    );
};
