import React, { Component } from "react";
import axios from "axios";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
//import { RadioButton } from 'primereact/radiobutton';
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormOrderUpdate extends Component {
    // userData;
    // userStore;

    emptOrder = {
        name: "",
        facebook: "",
        address: "",
        subDistrict: "",
        district: "",
        province: "",
        zipcode: "",
        mobilePhone: "",
        delivery: "",
        amount: 0,
    };

    emptItem = {
        //itemId: null,
        itemNumber: "-",
        name: "-",
        discountId: 3,
        discountType: "none",
        discount: 0,
        price: 0,
        total: 0,
    };

    constructor(props) {
        super(props);

        let today = new Date();

        this.state = {
            delivery: [],
            selectedDelivery: {},
            paymentType: [],
            products: [],
            postcode: [],
            selectedPostcode: null,
            selectedPaymentType: null,
            selectedProduct: null,
            order: {},
            item: {},
            items: [],
            submitted: false,
            editingRows: {},
            //blockedForm: false,
            disableDelivery: true,
            disableDiscount: true,
            file: "",
            data: [],
            progress: 0,
        };

        this.userData = {};
        this.userStore = {};
        this.options = [
            { value: 1, icon: "pi pi-percentage" },
            { value: 2, icon: "pi pi-money-bill" },
        ];

        this.toDate = today;

        this.originalRows = {};
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onProductSelect = this.onProductSelect.bind(this);
        this.onDeliveryChange = this.onDeliveryChange.bind(this);
        this.onPaymentTypeChange = this.onPaymentTypeChange.bind(this);
        this.imageBody = this.imageBody.bind(this);
        this.priceBody = this.priceBody.bind(this);
        this.getOrderById = this.getOrderById.bind(this);
        this.getOrderItemById = this.getOrderItemById.bind(this);
        this.getOrderItemListById = this.getOrderItemListById.bind(this);
        this.getOrderSumByOrderId = this.getOrderSumByOrderId.bind(this);
        this.getDelivery = this.getDelivery.bind(this);
        this.getDeliveryById = this.getDeliveryById.bind(this);
        this.getPaymentType = this.getPaymentType.bind(this);
        this.getPaymentTypeById = this.getPaymentTypeById.bind(this);
        this.getStoreItemsByStoreId = this.getStoreItemsByStoreId.bind(this);
        this.editOrder = this.editOrder.bind(this);
        this.updatedAmount = this.updatedAmount.bind(this);
        this.updatedOrder = this.updatedOrder.bind(this);
        this.updatedOrderItem = this.updatedOrderItem.bind(this);
        this.backToOrder = this.backToOrder.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.formatCurrency = this.formatCurrency.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.itemNumberBodyTemplate = this.itemNumberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.totalBodyTemplate = this.totalBodyTemplate.bind(this);
        this.discountBodyTemplate = this.discountBodyTemplate.bind(this);
        this.discountTypeBodyTemplate = this.discountTypeBodyTemplate.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.itemTotalEditor = this.itemTotalEditor.bind(this);
        this.itemDiscountEditor = this.itemDiscountEditor.bind(this);
        this.itemDiscountTypeEditor = this.itemDiscountTypeEditor.bind(this);
        this.onPostcodeChange = this.onPostcodeChange.bind(this);
        this.getPostcode = this.getPostcode.bind(this);
        this.getPostcodeByPostcode = this.getPostcodeByPostcode.bind(this);
        this.imgUploadHandler = this.imgUploadHandler.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.formatDateCal = this.formatDateCal.bind(this);
        this.formatDateToDateSql = this.formatDateToDateSql.bind(this);
        this.updatedLink = this.updatedLink.bind(this);
        this.refresh = this.refresh.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
    }

    async componentDidMount() {
        console.log("id ", this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const order = await this.getOrderById(this.props.match.params.id);

        const items = this.getOrderItemById(order.orderId);
        const products = await this.getStoreItemsByStoreId();
        const paymentType = await this.getPaymentType();
        const selectedPaymentType = await this.getPaymentTypeById(order.paymentId);
        const delivery = await this.getDelivery(selectedPaymentType);
        const selectedDelivery = await this.getDeliveryById(order.deliveryId);
        const postcode = await this.getPostcode();
        const selectedPostcode = await this.getPostcodeByPostcode(order.zipcode);
        const data = { name: "slip", path: order.slip };

        this.setState({
            order: order,
            items: items,
            products: products,
            delivery: delivery,
            selectedDelivery: selectedDelivery,
            paymentType: paymentType,
            selectedPaymentType: selectedPaymentType,
            postcode: postcode,
            selectedPostcode: selectedPostcode,
            data: data,
        });
    }

    getPostcode() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/postcode", requestOptions).json();
        return res;
    }

    getPostcodeByPostcode(postcode) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/postcode/" + postcode, requestOptions).json();
        return res;
    }

    getDelivery(paymeny) {
        console.log("getDelivery", paymeny.paymentId);
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/deliverByPaymentId/" + paymeny.paymentId, requestOptions).json();
        return res;
    }

    getDeliveryById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/deliver/" + id, requestOptions).json();
        return res;
    }

    getPaymentType() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/payment", requestOptions).json();
        return res;
    }

    getPaymentTypeById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/payment/" + id, requestOptions).json();
        return res;
    }

    getOrderById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/order/" + id, requestOptions).json();
        return res;
    }

    getOrderItemById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItemByOrderId/" + id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return [this.emptItem];
        }
    }

    getStoreItemsByStoreId() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/storeItemsByStoreId/" + this.userStore.storeId, requestOptions).json();
        return res;
    }

    getOrderSumByOrderId(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItem/sumAmount/" + id, requestOptions).json();
        return res;
    }

    getOrderItemListById(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItem/listItemName/" + id, requestOptions).json();
        return res;
    }

    updatedAmount(order) {
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderAmount/" + order.orderId, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                amount: order.amount,
            }),
        }).json();
        return response;
    }

    updatedLink(orderId) {
        let order = this.getOrderById(orderId);
        let getOrderItem = this.getOrderItemListById(orderId);
        let detailArray = { ...getOrderItem };
        let index = Object.keys(detailArray).toString();
        let detail = detailArray[index];
        let appointment = order.appointment ? order.appointment : "-";

        console.log("saveOrder >>>> updatedLink:", detailArray);
        console.log("saveOrder >>>> updatedLink:", detail);

        let datail =
            "===ข้อมูลออเดอร์===" +
            "\n วันที่ : " +
            order.orderDateTime +
            "\n ชื่อ : " +
            order.name +
            "\n ที่อยู่: " +
            order.address +
            ", " +
            order.zipcode +
            "\n เฟซบุ๊ก: " +
            order.facebook +
            "\n เบอร์โทรศัทพ์: " +
            order.mobilePhone +
            "\n สั่งซื้อ : " +
            detail.itemList +
            "\n รวม : " +
            Number(order.amount).toFixed(2) +
            "\n จัดส่ง : " +
            order.delivery +
            "\n สะดวกรับ : " +
            appointment +
            "\n รายละเอียดเพิ่มเติม : " +
            Constants.URL +
            "/" +
            orderId;

        console.log("datail", datail);

        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderLink/" + orderId, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                link: datail,
            }),
        }).json();
        return response;
    }

    updatePaymentStatus(orderId) {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/orderPaymentStatus/" + orderId, {
            method: "PUT",
            body: JSON.stringify({
                paymentStatusId: 2,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    updateProduct(orderId) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderProduct/" + orderId, requestOptions).json();
        return res;
    }

    updatedOrder() {
        console.log("updatedOrder appointment>>>", this.state.order.appointment);

        console.log("updatedOrder formatDateCal>>>", this.formatDateCal(this.state.order.appointment));

        console.log("updatedOrder formatDate>>>", this.formatDateToDateSql(this.formatDateCal(this.state.order.appointment)));

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/order/" + this.state.order.orderId, {
            method: "PUT",
            body: JSON.stringify({
                name: this.state.order.name.trim(),
                facebook: this.state.order.facebook,
                mobilePhone: this.state.order.mobilePhone,
                address: this.state.order.address,
                zipcode: this.state.selectedPostcode.postcode,
                deliveryId: this.state.selectedDelivery.deliveryId,
                paymentId: this.state.selectedPaymentType.paymentId,
                slip: this.state.data.path,
                appointmentFlag: this.state.order.appointment ? "Y" : "N",
                appointment: this.formatDateToDateSql(this.formatDateCal(this.state.order.appointment)),
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    updatedOrderItem(orderItem) {
        console.log("updatedOrderItem", orderItem.orderItemId, orderItem.total);

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/orderItem/" + orderItem.orderItemId, {
            method: "PUT",
            body: JSON.stringify({
                total: orderItem.total,
                discountId: orderItem.discountId,
                discount: orderItem.discount,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    createOrderItem(orderItem) {
        const fetchs = require("sync-fetch");
        const response = fetchs(Constants.APIURL + "/orderItem", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                total: orderItem.total,
                discountId: orderItem.discountId,
                discount: orderItem.discount,
                itemListId: orderItem.itemListId,
                orderId: orderItem.orderId,
                createdBy: this.userData.userId,
                updatedBy: this.userData.userId,
            }),
        }).json();
        return response;
    }

    async editOrder() {
        let state = { submitted: true };

        if (this.state.order.name.trim() && this.state.order.orderId && this.state.selectedPostcode && this.state.selectedDelivery && this.state.selectedPaymentType && this.state.order.mobilePhone && this.state.items.length > 0) {
            let updatedOrder = await this.updatedOrder();

            console.log("editOrder >>>> updatedOrder:", updatedOrder);
            console.log("saveOrder >>>> path:", this.state.data.path);
            if (this.state.data.path) {
                await this.updatePaymentStatus(this.state.order.orderId);
            }
        
            let items = [...this.state.items];

            console.log("editOrder >>>> items:", items);

            for (let item of items) {
                console.log("editOrder >>>> item:", item);
                console.log("editOrder >>>> discountId:", item.discountId);
                console.log("editOrder >>>> discount:", item.discount);
                console.log("editOrder >>>> orderItemId:", item.orderItemId);
                console.log("editOrder >>>> itemListId:", item.itemListId);
                console.log("editOrder >>>> total:", item.total);

                const orderItem = {
                    total: item.total,
                    discountId: item.discountId,
                    discount: item.discount,
                    itemListId: item.itemListId,
                    orderItemId: item.orderItemId,
                    orderId: this.state.order.orderId,
                };

                let discountId = 3;
                let discount = 0;
                if (item.discountId != null) {
                    discountId = item.discountId;
                    discount = item.discount;
                }

                const orderItemNew = {
                    total: item.total,
                    discountId: discountId,
                    discount: discount,
                    itemListId: item.itemListId,
                    orderId: this.state.order.orderId,
                };

                if (item.orderItemId) {
                    await this.updatedOrderItem(orderItem);
                } else {
                    await this.createOrderItem(orderItemNew);
                }
            }
            console.log("saveOrder >>>> orderId:", this.state.order.orderId);

            let getOrderSumByOrderId = this.getOrderSumByOrderId(this.state.order.orderId);

            console.log("saveOrder >>>> getOrderSumByOrderId:", getOrderSumByOrderId);

            // let totalArray = [...getOrderSumByOrderId];

            // console.log("saveOrder >>>> totalArray:", totalArray);

            //let index = Object.keys(getOrderSumByOrderId).toString();

            //console.log("saveOrder >>>> index:", index);

            //let amount = getOrderSumByOrderId[index].amount;

            let amount = Number(getOrderSumByOrderId.amount).toFixed(2);

            console.log("saveOrder >>>> amount ", amount);

            let updatedAmount = {
                amount: amount,
                orderId: this.state.order.orderId,
            };

            console.log("saveOrder >>>> updatedAmout:", updatedAmount);

            await this.updatedAmount(updatedAmount);

            await this.updatedLink(this.state.order.orderId);

            await this.updateProduct(this.state.order.orderId);

            let ordersUpdate = await this.getOrderById(this.props.match.params.id);

            state = {
                ...state,
                orders: ordersUpdate,
            };

            this.setState(state);

            this.toast.show({ severity: "success", summary: "แก้ไขออเดอร์", detail: "บันทึกสำเร็จ", life: 3000 });
        } else {
            this.toast.show({ severity: "warn", summary: "แก้ไขออเดอร์", detail: "โปรดระบุให้ครบถ้วน", life: 3000 });
        }

        this.setState(state);

        if(this.userData.role === Constants.SALE){
            this.props.history.push("/order");
        }else{
            this.props.history.push("/packing");
        }
        this.refresh();
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    deleteOrderItem(item) {
        let items = [...this.state.items];

        if (item.orderItemId) {
            let headersList = {
                Accept: "*/*",
            };

            fetch(Constants.APIURL + "/orderItem/" + item.orderItemId, {
                method: "DELETE",
                headers: headersList,
            })
                .then(function (response) {
                    return response.text();
                })
                .then(function (data) {
                    console.log(data);
                });
        }
        items.pop(item);

        this.setState({ items });

        this.toast.show({ severity: "success", summary: "เพิ่มสินค้า", detail: "ลบสำเร็จ", life: 3000 });
    
        this.refresh();
    
    }

    backToOrder() {
        if(this.userData.role === Constants.SALE){
            this.props.history.push("/order");
        }else{
            this.props.history.push("/packing");
        }
    }

    formatDateCal(data) {
        if (data) {
            var newDate = data.split("-").reverse().join("-");
            var formatDate = new Date(newDate);
            return formatDate;
        }
    }

    formatDate(data) {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            if (day < 10) {
                day = "0" + day;
            }

            return day + "-" + month + "-" + data.getFullYear();
        }
    }

    formatDateToDateSql(data) {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            if (day < 10) {
                day = "0" + day;
            }

            return data.getFullYear() + "-" + month + "-" + day;
        }
    }

    onDeliveryChange(e) {
        this.setState({ selectedDelivery: e.value });
    }

    async onPaymentTypeChange(e) {
        this.setState({ selectedPaymentType: e.value });
        const delivery = await this.getDelivery(e.value);
        this.setState({
            delivery: delivery,
            disableDelivery: false,
        });
    }

    onPostcodeChange(e) {
        this.dt.filter("", "postcode", null);
        this.setState({ selectedPostcode: e.value });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || "";
        let order = { ...this.state.order };
        order[`${name}`] = val;

        this.setState({ order });
    }

    onInputAppointmentChange(e, name) {
        const val = (e.target && e.target.value) || "";
        let order = { ...this.state.order };
        order[`${name}`] = this.formatDate(val);

        this.setState({ order });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let order = { ...this.state.order };
        order[`${name}`] = val;

        this.setState({ order });
    }

    onEditorValueChange(itemKey, props, value) {
        let updatedItems = [...props.value];
        updatedItems[props.rowIndex][props.field] = value;
        //this.setState({ [`${itemKey}`]: updatedItems });
        console.log("onEditorValueChange >>>> updatedItems: ", updatedItems);
        this.setState((state, props) => ({
            [`${itemKey}`]: updatedItems,
        }));
    }

    onEditorValueDiscountChange(itemKey, props, value) {
        let updatedItems = [...props.value];
        updatedItems[props.rowIndex][props.field] = value;
        //this.setState({ [`${itemKey}`]: updatedItems });

        this.setState((state, props) => ({
            [`${itemKey}`]: updatedItems,
        }));

        if (value === 1) {
            this.setState({ disableDiscount: false });
        } else if (value === 2) {
            this.setState({ disableDiscount: false });
        } else {
            this.setState({ disableDiscount: true });
        }
    }

    onProductSelect(e) {
        this.setState({ selectedProduct: e.value }, () => {
            let items = [...this.state.items];
            let item = { ...this.state.item };
            let selectedProduct = this.state.selectedProduct;

            console.log("onProductSelect >>>> selectedProduct : ", selectedProduct);

            let itemsBySelectedProduct = items.filter((val) => {
                if (val.itemListId === selectedProduct.itemListId) {
                    return selectedProduct;
                } else {
                    return "";
                }
            });

            let length = Object.keys(itemsBySelectedProduct).length;

            if (length === 1) {
                this.toast.show({ severity: "warn", summary: "เพิ่มสินค้า", detail: "มีสินค้านี้แล้ว" + this.state.selectedProduct.name, life: 3000 });
            } else {
                console.log("onProductSelect >>>> itemListId : ", this.state.selectedProduct.itemListId);

                item.itemListId = this.state.selectedProduct.itemListId;
                item.itemId = this.state.selectedProduct.itemId;
                item.itemNumber = this.state.selectedProduct.itemNumber;
                item.name = this.state.selectedProduct.name;
                item.price = this.state.selectedProduct.price;
                item.total = 1;
                items.push(item);

                this.setState({ items });
                this.toast.show({ severity: "info", summary: "เพิ่มสินค้า", detail: "เลือกสินค้าสำเร็จ" + this.state.selectedProduct.name, life: 3000 });
            }
            this.op.hide();
        });
    }

    imgUploadHandler(e) {
        //console.log('file', this.state.file);
        const file = e.files[0];
        //const [file] = files;
        //console.log('file', file);
        this.uploadImg(file);
        this.setState({
            file: file,
            progress: 0,
        });
    }

    uploadImg(img) {
        //console.log('img', img);
        let formData = new FormData();
        formData.append("file", img);

        axios
            .post(Constants.APIURL + "/uploadSlip", formData, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + "%";
                    this.setState({
                        progress: progress,
                        file: "",
                    });
                },
            })
            .then((res) => {
                console.log(res);
                const data = { name: res.data.name, path: Constants.APIURL + "/slip" + res.data.path };
                this.setState({ data: data });
                this.upload.clear();
            })
            .catch((err) => console.log(err));
    }

    onRowEditInit(event) {
        //console.log("onRowEditInit >>>> event.index: ", event.index);
        this.originalRows[event.index] = { ...this.state.items[event.index] };
    }

    onRowEditCancel(event) {
        //console.log("onRowEditCancel >>>> event.index: ", event.index);
        let itemsEdit = this.state.items;

        itemsEdit[event.index] = this.originalRows[event.index];

        this.setState((state, props) => ({
            items: Object.values(itemsEdit),
        }));

        delete this.originalRows[event.index];
    }

    onRowEditChange(event) {
        this.setState({ editingRows: event.data });
    }
    /*   
       onRowEditChange(event) {
           console.log("onRowEditChange >>>> event.data: ", event.data);
        
           this.setState((state, props) => ({
             editingRows: event.data
           }));
        
           //this.setState({ editingRows: event.data });
           let editingRows = { ...this.state.editingRows };
        
           console.log("onRowEditChange >>>> editingRows: ", editingRows);
        
           console.log("onRowEditChange >>>> state.editingRows: ", this.state.editingRows);
        
           let length = Object.keys(this.state.editingRows).length;
        
           console.log("onRowEditChange >>>> length: ", length);
        
           if (length === 1) {
             let itemListId = Object.keys(this.state.editingRows).toString();
             
             console.log("onRowEditChange >>>> itemListId: ", itemListId);
             
             let items = { ...this.state.items };
        
             console.log("onRowEditChange >>>> items: ", items);
             let itemsByEditingRows = Object.keys(items).filter((key) => {
               if (items[key].itemListId.toString() === itemListId) {
                 return itemListId;
               }
               else { return ''; }
             });
        
             console.log("onRowEditChange >>>> itemsByEditingRows: ", itemsByEditingRows);
             console.log("onRowEditChange >>>> items: ", items);
             console.log("onRowEditChange >>>> item: ", items[itemsByEditingRows]);
             let item = items[itemsByEditingRows]
        
             this.updateOrderItem(item);
           }
         }
    */
    imageBody(rowData) {
        return <img src={rowData.img} alt={rowData.img} className="product-image" width="32" />;
    }

    priceBody(rowData) {
        return this.formatCurrency(rowData.price);
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    inputTextEditor(itemKey, props, field) {
        return <InputText type="text" className="p-inputtext-sm" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(itemKey, props, e.target.value)} />;
    }

    inputDiscountEditor(itemKey, props, field) {
        return <InputText type="text" className="p-inputtext-sm" value={props.rowData[field]} disabled={this.state.disableDiscount} onChange={(e) => this.onEditorValueChange(itemKey, props, e.target.value)} />;
    }

    itemTotalEditor(itemKey, props) {
        // return this.inputTextEditor(itemKey, props, "total");
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวน</span>
                <InputNumber value={props.rowData["total"]} onValueChange={(e) => this.onEditorValueChange(itemKey, props, e.value)} mode="decimal" showButtons min={1} max={1000} />
            </React.Fragment>
        );
    }

    itemDiscountEditor(itemKey, props) {
        return (
            <React.Fragment>
                <span className="p-column-title">ส่วนลด</span>
                {this.inputDiscountEditor(itemKey, props, "discount")}
            </React.Fragment>
        );
    }

    itemDiscountTypeEditor(itemKey, props) {
        //return this.inputTextEditor(itemKey, props, "discount");
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทส่วนลด</span>
                <MultiStateCheckbox value={props.rowData["discountId"]} options={this.options} optionValue="value" onChange={(e) => this.onEditorValueDiscountChange(itemKey, props, e.target.value)} />
                {props.rowData["discountId"] === 1 && <label>ส่วนลดเปอร์เซ็น</label>}
                {props.rowData["discountId"] === 2 && <label>ส่วนลดเงินบาท</label>}
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.deleteOrderItem(rowData)} />
            </React.Fragment>
        );
    }

    itemNumberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสสินค้า</span>
                <span>{rowData.itemNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อสินค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    priceBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ราคาขาย</span>
                <span>{rowData.price}</span>
            </React.Fragment>
        );
    }

    totalBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวน</span>
                <span>{rowData.total}</span>
            </React.Fragment>
        );
    }

    discountTypeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทส่วนลด</span>
                <MultiStateCheckbox value={rowData.discountId} options={this.options} optionValue="value" />
                {rowData.discountId === 1 && <label>ส่วนลดเปอร์เซ็น</label>}
                {rowData.discountId === 2 && <label>ส่วนลดเงินบาท</label>}
            </React.Fragment>
        );
    }

    discountBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ส่วนลด</span>
                <span>{rowData.discount}</span>
            </React.Fragment>
        );
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="กลับไปหน้ารายการ" icon="pi pi-chevron-left" className="p-button-sm p-button-secondary" style={{ width: "160px" }} onClick={this.backToOrder} />
            </React.Fragment>
        );
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-search"
                    label={"เลือกสินค้า"}
                    onClick={(e) => this.op.toggle(e)}
                    //onClick={(e) => this.onItemSelecte(e)}
                    aria-haspopup
                    aria-controls="overlay_panel"
                    className="p-button-sm p-button-success"
                />
                <Button label="บันทึก" icon="pi pi-save" className="p-button-sm p-button-info" style={{ width: "100px", marginLeft: "7px" }} onClick={this.editOrder} />
            </React.Fragment>
        );
    }
    
    hideConfirmOrderDialog() {
        this.setState({ confirmOrderDialog: false });
    }

    render() {
        return (
            <div>
                {/*<BlockUI blocked={this.state.blockedForm} fullScreen />*/}

                <Toast ref={(el) => (this.toast = el)} />

                <div className="card">
                    <h3>แก้ไขออเดอร์สินค้า</h3>
                </div>

                <div className="card form-demo">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    type="text"
                                    value={this.state.order.name}
                                    onChange={(e) => this.onInputChange(e, "name")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.order.name,
                                    })}
                                />
                                <label htmlFor="name">ชื่อ-นามสกุล</label>
                            </span>
                            {this.state.submitted && !this.state.order.name && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="facebook"
                                    value={this.state.order.facebook}
                                    onChange={(e) => this.onInputChange(e, "facebook")}
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && !this.state.order.facebook,
                                    })}
                                />
                                <label htmlFor="name">เฟซบุ๊ก</label>
                            </span>
                            {this.state.submitted && !this.state.order.facebook && <small className="p-error">Facebook is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputTextarea
                                    rows={1}
                                    cols={30}
                                    id="address"
                                    value={this.state.order.address}
                                    onChange={(e) => this.onInputChange(e, "address")}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.order.address,
                                    })}
                                />
                                <label htmlFor="address">ที่อยู่</label>
                            </span>
                            {this.state.submitted && !this.state.order.address && <small className="p-error">Address is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Dropdown
                                    value={this.state.selectedPostcode}
                                    options={this.state.postcode}
                                    onChange={this.onPostcodeChange}
                                    optionLabel="postcode"
                                    showClear
                                    filter
                                    filterBy="postcode"
                                    placeholder="รหัสไปรณีย์"
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedPostcode,
                                    })}
                                />

                                {this.state.submitted && !this.state.selectedPostcode && <small className="p-error">Zip Code is required.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Dropdown
                                    id="paymentId"
                                    value={this.state.selectedPaymentType}
                                    options={this.state.paymentType}
                                    onChange={this.onPaymentTypeChange}
                                    optionLabel="payment"
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedPaymentType,
                                    })}
                                />
                                <label htmlFor="paymentId">การชำระเงิน</label>
                                {this.state.submitted && !this.state.selectedPaymentType && <small className="p-error">Payment is required.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Dropdown
                                    id="delivery"
                                    value={this.state.selectedDelivery}
                                    options={this.state.delivery}
                                    onChange={this.onDeliveryChange}
                                    optionLabel="delivery"
                                    disabled={this.state.disableDelivery}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.selectedDelivery,
                                    })}
                                />
                                <label htmlFor="delivery">การจัดส่ง</label>
                                {this.state.submitted && !this.state.selectedDelivery && <small className="p-error">Delivery is required.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputNumber id="price" className="p-inputtext-sm" value={this.state.order.amount} mode="currency" currency="THB" locale="th-TH" disabled />
                                <label htmlFor="price">ยอดเงิน</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <Calendar id="appointment" value={this.formatDateCal(this.state.order.appointment)} onChange={(e) => this.onInputAppointmentChange(e, "appointment")} dateFormat="dd-mm-yy" />
                                <label htmlFor="address">วันที่สะดวกรับ</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                <InputText
                                    id="mobilePhone"
                                    value={this.state.order.mobilePhone}
                                    onChange={(e) => this.onInputChange(e, "mobilePhone")}
                                    type="number"
                                    maxLength="10"
                                    autoFocus
                                    className={classNames({
                                        "p-inputtext-sm p-invalid": this.state.submitted && this.state.order.mobilePhone && this.state.order.mobilePhone.length !== 9 && this.state.order.mobilePhone.length !== 10,
                                    })}
                                />
                                <label htmlFor="mobilePhone">เบอร์โทรศัทพ์</label>
                                {this.state.submitted && this.state.order.mobilePhone && this.state.order.mobilePhone.length !== 9 && this.state.order.mobilePhone.length !== 10 && <small className="p-error">Mobile Phone is 9 or 10 digit.</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <span className="p-float-label">
                                {/*<InputText
                                        id="link"
                                        value={this.state.order.link}
                                        disabled
                                    />*/}
                                <InputTextarea rows={1} cols={30} value={this.state.order.link} disabled />
                                <label htmlFor="link">ลิงค์</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <FileUpload name="slip" ref={(el) => (this.upload = el)} accept="image/*" customUpload={true} uploadHandler={this.imgUploadHandler} mode="basic" chooseLabel="Upload Slip" />
                        </div>
                        <div className="p-field p-col-12 p-md-6 " style={{ marginTop: "5px" }}>
                            <Image src={this.state.data.path} alt={this.state.data.name} width="100" preview />
                        </div>
                    </div>

                    <div className="datatable-responsive-demo">
                        <DataTable
                            className="p-datatable-responsive-demo p-datatable-sm"
                            dataKey="itemListId"
                            value={this.state.items}
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            editMode="row"
                            editingRows={this.state.editingRows}
                            onRowEditInit={this.onRowEditInit}
                            onRowEditChange={this.onRowEditChange}
                            onRowEditCancel={this.onRowEditCancel}
                            style={{ fontSize: "11px" }}
                        >
                            <Column field="itemNumber" header="รหัสสินค้า" body={this.itemNumberBodyTemplate}></Column>
                            <Column field="name" header="ชื่อสินค้า" body={this.nameBodyTemplate} />
                            <Column field="price" header="ราคาขาย" body={this.priceBodyTemplate} />
                            <Column field="total" header="จำนวน" body={this.totalBodyTemplate} editor={(props) => this.itemTotalEditor("orderItems", props)} required />
                            <Column field="discountId" header="ประเภทส่วนลด" body={this.discountTypeBodyTemplate} editor={(props) => this.itemDiscountTypeEditor("discountId", props)} required />
                            <Column field="discount" header="ส่วนลด" body={this.discountBodyTemplate} editor={(props) => this.itemDiscountEditor("discount", props)} required />
                            <Column header="แก้ไข" rowEditor headerStyle={{ width: "5rem" }} />
                            <Column header="ลบ" headerStyle={{ width: "5rem" }} body={this.actionBodyTemplate} />
                        </DataTable>
                    </div>

                    <OverlayPanel ref={(el) => (this.op = el)} showCloseIcon id="overlay_panel" className="overlaypanel-demo" style={{ width: "450px" }}>
                        <DataTable value={this.state.products} selectionMode="single" paginator rows={5} selection={this.state.selectedProduct} onSelectionChange={this.onProductSelect} style={{ fontSize: "11px" }}>
                            <Column field="name" header="Name" sortable />
                            <Column field="img" header="Image" body={this.imageBody} />
                            <Column field="price" header="Price" sortable body={this.priceBody} />
                        </DataTable>
                    </OverlayPanel>
                </div>
            </div>
        );
    }
}
