import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import * as Constants from "../../constants/Constant";
import "../../styles/css/Table.css";

export class FormReportMonthlyLoss extends Component {
    // userData;
    // userStore;

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            years: [],
            months: [],
            selectedYear: "",
            selectedMonth: "",
        };
        this.userData = {};
        this.userStore = {};
        this.getReport = this.getReport.bind(this);
        this.getMonth = this.getMonth.bind(this);
        this.getYear = this.getYear.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.monthly = this.monthly.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const reports = await this.getReport();
        const years = await this.getYear();
        const months = await this.getMonth();
        this.setState({
            reports: reports,
            years: years,
            months: months,
        });
    }

    getMonth() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        const res = fetchs(Constants.APIURL + "/report/month", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getYear() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        const res = fetchs(Constants.APIURL + "/report/year", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getReport() {
        let monthly = null;

        if (this.state.selectedMonth && this.state.selectedYear) {
            monthly = this.state.selectedMonth.code + this.state.selectedYear.year;
        } else {
            monthly = this.monthly();
        }

        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        const res = fetchs(Constants.APIURL + "/report/monthlyLoss/" + this.userStore.storeId + "/" + monthly, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    monthly() {
        let newDate = new Date();
        let year = newDate.getFullYear();

        var month;
        let month_raw = newDate.getMonth() + 1;
        if (month_raw < 10) {
            month = "0" + month_raw.toString();
        } else {
            month = month_raw.toString();
        }

        //var date
        //let date_raw = newDate.getDate();
        //if (date_raw < 10) { date = "0" + date_raw.toString() } else { date = date_raw.toString() }

        return "" + month + year;
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.reports);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
            this.saveAsExcelFile(excelBuffer, "monthly_summarize_sale_report");
        });
    }

    saveAsExcelFile(buffer, fileName) {
        // import("file-saver").then((FileSaver) => {
        //     let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        //     let EXCEL_EXTENSION = ".xlsx";
        //     const data = new Blob([buffer], {
        //         type: EXCEL_TYPE,
        //     });
        //     FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        // });
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    amountBodyTemplate(rowData) {
        return this.formatCurrency(rowData.amount);
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    onMonthChange(e) {
        this.setState({ selectedMonth: e.value });
    }

    onYearChange(e) {
        this.setState({ selectedYear: e.value });
    }

    onSubmit() {
        const reports = this.getReport();
        console.log("reports", reports);
        this.setState({ reports: reports });
    }

    render() {
        const header = (
            <div className="p-d-flex p-ai-center export-buttons">
                <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-sm -button-success p-mr-2" data-pr-tooltip="XLS" />
                <Dropdown value={this.state.selectedMonth} options={this.state.months} onChange={this.onMonthChange} optionLabel="month" placeholder="เลือกเดือน" />
                <Dropdown value={this.state.selectedYear} options={this.state.years} onChange={this.onYearChange} optionLabel="year" placeholder="เลือกปี" style={{ marginLeft: "7px" }} />
                <Button label="ค้นหา" icon="pi pi-search" className="pp-button-sm -button-info p-mr-2" onClick={this.onSubmit} style={{ marginLeft: "7px" }} disabled={!this.state.selectedMonth || !this.state.selectedYear} />
            </div>
        );
        return (
            <div>
                <div className="card">
                    <h3>รายงาน:ค้างยอดโอน</h3>
                </div>
                <div className="card">
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable
                        value={this.state.reports}
                        header={header}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        style={{ fontSize: "11px" }}
                    >
                        <Column field="orderDate" header="วันที่รับออเดอร์" />
                        <Column field="storeName" header="ร้านค้า" />
                        <Column field="amount" header="ยอดขาย" />
                        <Column field="orderNumber" header="รหัสออเดอร์" />
                        <Column field="trackingNumber" header="เลขที่พัสดุ" />
                        <Column field="deliver" header="การจัดส่ง" />
                        <Column field="payment" header="การชำระ" />
                    </DataTable>
                </div>
            </div>
        );
    }
}
