import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Constants from "../constants/Constant";
import "../styles/css/Link.css";

export default function Link() {
    let emptyOrder = {
        status: "",
        orderDate: "",
        name: "",
        address: "",
        mobilePhone: "",
        delivery: "",
        trackingNumber: "",
        amount: "",
    };

    let emptyOrderItem = {
        name: "",
        total: "",
    };

    let { id } = useParams();
    //let { id } = id;
    const [orders, setOrders] = useState(emptyOrder);
    const [orderItems, setOrderItems] = useState(emptyOrderItem);

    useEffect(() => {
        setOrders(emptyOrder);
        setOrderItems(emptyOrderItem);

        const orders = getOrder();
        const orderItems = getOrderItem();

        setOrders(orders);
        setOrderItems(orderItems);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getOrder = () => {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/order/" + id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return emptyOrder;
        }
    };

    const getOrderItem = () => {
        const fetchs = require("sync-fetch");

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItemByOrderId/" + id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return emptyOrderItem;
        }
    };

    return (
        <div>
            <div className="p-text-center" style={{ fontSize: "20px", marginTop: 40 }}>
                รายละเอียดออเดอร์สินค้า{" "}
            </div>
            <div style={{ marginTop: 20, marginLeft: 10 ,marginRight:10 }}>
                <table style={{ background: "white" }}>
                    <thead>
                        <tr style={{ background: "#dddddd" }}>
                            <th>รายละเอียด ออเดอร์ </th>
                            <th>สถานะ : {orders.status}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={"w-25"}>วันที่</td>
                            <td>{orders.orderDateTime}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>ชื่อ</td>
                            <td>{orders.name}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>ที่อยู่</td>
                            <td>
                                {orders.address} {orders.zipcode}
                            </td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>เฟซบุ๊ก</td>
                            <td>{orders.facebook}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>เบอร์โทรศัพท์</td>
                            <td>{orders.mobilePhone}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>การจัดส่ง</td>
                            <td>{orders.delivery}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>สะดวกรับ</td>
                            <td>{orders.appointment}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>เลขพัสดุ</td>
                            <td>{orders.trackingNumber}</td>
                        </tr>
                        <tr>
                            <td className={"w-25"}>รวมเงิน</td>
                            <td>{orders.amount}</td>
                        </tr>
                    </tbody>

                    <thead>
                        <tr style={{ background: "#dddddd" }}>
                            <th>รายการสินค้า</th>
                            <th>จำนวน</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderItems.length > 0 &&
                            orderItems.map((item, key) => (
                                <tr key={key}>
                                    <td className={"w-25"}>{item.name}</td>
                                    <td>{item.total}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
