import React, { useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

export const AppProfile = () => {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));

    const [store] = useState(JSON.parse(localStorage.getItem("store")));

    const [expanded, setExpanded] = useState(false);

    const onClick = (event) => {
        setExpanded((prevState) => !prevState);
        event.preventDefault();
    };

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("store");
        window.location.href = "/";
    };

    return (
        <div className="layout-profile">
            <div>
                <img src="assets/images/avatar/profile.png" alt="profile" />
            </div>
            <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username">{user.name}</span>
                <i className="pi pi-fw pi-cog" />
            </button>
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ "layout-profile-expanded": expanded })}>
                    <li>
                        <button type="button" className="p-link" disabled={true}>
                            <i className="pi pi-fw pi-user" />
                            <span>{user.role}</span>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="p-link" disabled={true}>
                            <i className="pi pi-fw pi-home" />
                            <span>{store.name}</span>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="p-link" onClick={handleLogout}>
                            <i className="pi pi-fw pi-power-off" />
                            <span>Logout</span>
                        </button>
                    </li>
                </ul>
            </CSSTransition>
        </div>
    );
};
