import React from "react";
import {  Document, Page, StyleSheet } from '@react-pdf/renderer'
import RequisitionNo from "./RequisitionNo";
import RequisitionItemsTable from "./RequisitionItemsTable";
import RequisitionFooter from "./RequisitionFooter";
//import font from "../styles/fonts/THSarabunNew.ttf";

//Font.register({ family: 'THSarabunNew', src: font })

const styles = StyleSheet.create({
    page: {
        fontFamily: 'THSarabunNew',
        //fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },

});

const Requisition = ({ item }) => {
    console.log("pdf requisition item", item);
    console.log("pdf requisition items", item.items);
    return (
        <Document>
            <Page wrap size="A4" style={styles.page}>
                <RequisitionNo item={item} />
                <RequisitionItemsTable item={item} />
                <RequisitionFooter />
            </Page>
        </Document>
    );
};

export default Requisition