import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormItemView extends Component {
    // userData;
    // userStore;

    emptItem = {
        itemId: "",
        itemNumber: "",
        name: "",
        description: "",
        img: "",
        cost: 0,
        total: 0,
        minimun: 10,
    };
    constructor(props) {
        super(props);
        this.state = {
            item: this.emptItem,
            items: [],
        };
        this.userData = {};
        this.userStore = {};
        this.getItem = this.getItem.bind(this);
        this.getItems = this.getItems.bind(this);
        this.cancelItem = this.cancelItem.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
    }

    async componentDidMount() {
        //console.log("id", this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const item = await this.getItem();
        const items = await this.getItems();
        this.setState({
            item: item,
            items: items,
        });
    }

    cancelItem() {
        this.props.history.push("/item");
    }

    getItem() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/item/" + this.props.match.params.id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return this.emptItem;
        }
    }

    getItems() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/itemGroupsByItemId/" + this.props.match.params.id, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return this.emptItem;
        }
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="กลับไปหน้ารายการ" icon="pi pi-chevron-left" className="p-button-sm p-button-secondary" onClick={this.cancelItem} />
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <div className="card">
                    <h3>สินค้า</h3>
                </div>

                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}></Toolbar>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText id="itemNumber" className="p-inputtext-sm" value={this.state.item.itemNumber} disabled />
                                <label htmlFor="itemNumber">รหัสสินค้า</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText id="name" className="p-inputtext-sm" value={this.state.item.name} onChange={(e) => this.onInputChange(e, "name")} disabled />
                                <label htmlFor="name">ชื่อสินค้า</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputText id="description" className="p-inputtext-sm" value={this.state.item.description} disabled />
                                <label htmlFor="description">คำอธิบาย</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputNumber id="cost" value={this.state.item.cost} disabled />
                                <label htmlFor="cost">ราคาต้นทุน</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputNumber id="minimun" value={this.state.item.minimun} disabled />
                                <label htmlFor="name">ขั้นต้ำ</label>
                            </span>
                        </div>
                    </div>

                    <div className="datatable-responsive-demo">
                        <DataTable
                            className="p-datatable-responsive-demo p-datatable-sm"
                            dataKey="itemId"
                            value={this.state.items}
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            style={{ fontSize: "11px" }}
                        >
                            <Column field="itemNumber" header="รหัสสินค้า" />
                            <Column field="name" header="ชื้อสินค้า" />
                            <Column field="cost" header="ราคาต้นทุน" body={this.itemCostBody} />
                            <Column field="total" header="จำนวนขาย" editor={(props) => this.itemTotalEditor("total", props)} headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} required />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}
