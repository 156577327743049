import React, { Component } from "react";
//import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
//import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from "primereact/toolbar";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            item: this.emptItem,
            submitted: false,
            deleteItemDialog: false,
            editingRows: {},
        };
        this.userData = {};
        this.userStore = {};
        this.originalRows = {};
        this.getItems = this.getItems.bind(this);
        this.newItem = this.newItem.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.clearItem = this.clearItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDeleteItem = this.confirmDeleteItem.bind(this);
        this.hideDeleteItemDialog = this.hideDeleteItemDialog.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.itemNumberEditor = this.itemNumberEditor.bind(this);
        this.itemNameEditor = this.itemNameEditor.bind(this);
        this.itemDescEditor = this.itemDescEditor.bind(this);
        this.itemCostEditor = this.itemCostEditor.bind(this);
        this.itemMinimumEditor = this.itemMinimumEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.itemNumberBodyTemplate = this.itemNumberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.desBodyTemplate = this.desBodyTemplate.bind(this);
        this.costBodyTemplate = this.costBodyTemplate.bind(this);
        this.minimunBodyTemplate = this.minimunBodyTemplate.bind(this);
        this.totalBodyTemplate = this.totalBodyTemplate.bind(this);
        this.formatCurrency = this.formatCurrency.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionViewBodyTemplate = this.actionViewBodyTemplate.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));
        const items = await this.getItems();
        this.setState({ items });
        console.log("roleId", this.userData.roleId);
    }

    getItems() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/items", requestOptions).json();
        return res;
    }

    updateItem(item) {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/item/" + item.itemId, {
            method: "PUT",
            body: JSON.stringify({
                name: item.name,
                description: item.description,
                cost: item.cost,
                minimun: item.minimun,
                total: item.total,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        this.setState({
            //items: Object.values(this.getItems()),
            item: this.emptItem,
            submitted: false,
            deleteItemDialog: false,
            editingRows: {},
        });

        this.toast.show({ severity: "success", summary: "แก้ไขสินค้า", detail: "บันทึกสำเร็จ", life: 3000 });
    }

    deleteItem() {
        let item = { ...this.state.item };
        // let checked = '';
        // if (item.isActive === 'Y') {
        //   checked = null;
        // } else {
        //   checked = 'Y';
        // }

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/itemIsActive/" + item.itemId, {
            method: "PUT",
            body: JSON.stringify({
                isActive: "N",
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        let state = { submitted: true };
        state = {
            ...state,
            items: this.getItems(),
            item: this.emptItem,
            submitted: false,
            deleteItemDialog: false,
            editingRows: {},
        };
        this.setState(state);
        this.toast.show({ severity: "success", summary: "สินค้า", detail: "บันทึกสำเร็จ", life: 3000 });

        this.refresh();
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    newItem() {
        this.props.history.push("/newItem");
    }

    viewItem(item) {
        this.props.history.push("/viewItem/" + item.itemId);
    }

    clearItem() {
        let state = { ...this.state };
        state = {
            ...state,
            items: [],
            item: this.emptItem,
            submitted: false,
            deleteItemDialog: false,
            editingRows: {},
        };
        this.setState(state);
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    onEditorValueChange(itemKey, props, value) {
        let updatedItems = [...props.value];
        updatedItems[props.rowIndex][props.field] = value;
        //this.setState({ [`${itemKey}`]: updatedItems });
        this.setState((state, props) => ({
            [`${itemKey}`]: updatedItems,
        }));
    }

    onRowEditInit(event) {
        console.log("onRowEditInit >>>> event.index: ", event.index);
        this.originalRows[event.index] = { ...this.state.items[event.index] };
    }

    onRowEditCancel(event) {
        console.log("onRowEditCancel >>>> event.index: ", event.index);
        let itemsEdit = this.state.items;

        itemsEdit[event.index] = this.originalRows[event.index];

        this.setState((state, props) => ({
            items: Object.values(itemsEdit),
        }));

        delete this.originalRows[event.index];
    }

    onRowEditChange(event) {
        console.log("onRowEditChange >>>> event.data", event.data);
        this.setState((state, props) => ({
            editingRows: event.data,
        }));
        //this.setState({ editingRows: event.data });
        let editingRows = { ...this.state.editingRows };
        //let length = Object.keys(this.state.editingRows).length;
        let length = Object.keys(editingRows).length;
        //console.log("onRowEditChange >>>> length", length);
        if (length === 1) {
            //let itemId = Object.keys(this.state.editingRows).toString();
            let itemId = Object.keys(editingRows).toString();
            //console.log("onRowEditChange >>>> itemId", itemId);
            let items = { ...this.state.items };
            let itemsByEditingRows = Object.keys(items).filter((key) => {
                if (items[key].itemId.toString() === itemId) {
                    return itemId;
                } else {
                    return "";
                }
            });
            let item = items[itemsByEditingRows];
            /*
            let items = [...this.state.items];
            let itemsByEditingRows = items.filter(val => {
              if (val.itemId === itemId) {
                return itemId;
              }
              else { return ''; }
            });
            let item = items[Object.keys(itemsByEditingRows)]
      */
            this.updateItem(item);
        }
    }

    itemNumberEditor(itemKey, props) {
        return this.inputTextEditor(itemKey, props, "itemNumber");
    }

    itemNameEditor(itemKey, props) {
        return this.inputTextEditor(itemKey, props, "name");
    }

    itemDescEditor(itemKey, props) {
        return this.inputTextEditor(itemKey, props, "description");
    }

    itemCostEditor(itemKey, props) {
        //return this.inputTextEditor(itemKey, props, "cost");
        return <InputNumber value={props.rowData["cost"]} onValueChange={(e) => this.onEditorValueChange(itemKey, props, e.value)} mode="currency" currency="THB" locale="th-TH" />;
    }

    itemMinimumEditor(itemKey, props) {
        //return this.inputTextEditor(itemKey, props, "minimun");
        return <InputNumber value={props.rowData["minimun"]} onValueChange={(e) => this.onEditorValueChange(itemKey, props, e.value)} />;
    }

    inputTextEditor(itemKey, props, field) {
        return <InputText className="p-inputtext-sm" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(itemKey, props, e.target.value)} />;
    }

    actionViewBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.viewItem(rowData)} />
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.confirmDeleteItem(rowData)} />

                {/* <InputSwitch
          checked={rowData.isActive}
          onChange={() => this.confirmDeleteItem(rowData)} /> */}
            </React.Fragment>
        );
    }

    confirmDeleteItem(item) {
        this.setState({
            item,
            deleteItemDialog: true,
        });
    }

    hideDeleteItemDialog() {
        this.setState({ deleteItemDialog: false });
    }

    itemNumberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสสินค้า</span>
                <span>{rowData.itemNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อสินค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    desBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">คำอธิบาย</span>
                <span>{rowData.description}</span>
            </React.Fragment>
        );
    }

    costBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ราคาต้นทุน</span>
                <span>{this.formatCurrency(rowData.cost)}</span>
            </React.Fragment>
        );
    }

    minimunBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ขั้นต้ำ</span>
                <span>{rowData.minimun}</span>
            </React.Fragment>
        );
    }

    totalBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวนสินค้า</span>
                {/* <span>{rowData.total}</span> */}0
            </React.Fragment>
        );
    }

    imageBodyTemplate(rowData) {
        return <img src={rowData.img} alt={rowData.img} className="product-image" />;
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="เพิ่มสินค้า" icon="pi pi-table" className="p-button-sm p-button-info p-mr-2" onClick={this.newItem} />
            </React.Fragment>
        );
    }

    render() {
        const deleteItemDialogFooter = (
            <React.Fragment>
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteItemDialog} />
                <Button label="ยื่นยัน" icon="pi pi-check" className="p-button-text" onClick={this.deleteItem} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />

                <div className="card">
                    <h3>จัดการสินค้า</h3>
                </div>

                <div className="datatable-responsive-demo">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}></Toolbar>

                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="itemId"
                        value={this.state.items}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        editMode="row"
                        editingRows={this.state.editingRows}
                        onRowEditInit={this.onRowEditInit}
                        onRowEditChange={this.onRowEditChange}
                        onRowEditCancel={this.onRowEditCancel}
                        style={{ fontSize: "11px" }}
                    >
                        <Column field="itemNumber" header="รหัสสินค้า" body={this.itemNumberBodyTemplate} sortable />
                        <Column field="name" header="ชื่อสินค้า" body={this.nameBodyTemplate} editor={(props) => this.itemNameEditor("items", props)} sortable />
                        <Column field="description" header="คำอธิบาย" body={this.desBodyTemplate} editor={(props) => this.itemDescEditor("items", props)} />
                        <Column field="img" header="รูปภาพ" body={this.imageBodyTemplate} />
                        {this.userData.role !== Constants.SUPER && <Column field="cost" header="ราคาต้นทุน" body={this.costBodyTemplate} editor={(props) => this.itemCostEditor("items", props)} />}
                        <Column field="minimun" header="ขั้นต้ำ" body={this.minimunBodyTemplate} editor={(props) => this.itemMinimumEditor("items", props)} />
                        <Column field="total" header="จำนวนสินค้า" body={this.totalBodyTemplate} sortable />
                        {this.userData.role !== Constants.SUPER && <Column header="แก้ไข" rowEditor headerStyle={{ width: "5rem" }} />}
                        {this.userData.role !== Constants.SUPER && <Column header="ดู" rowEditor headerStyle={{ width: "5rem" }} body={this.actionViewBodyTemplate} />}
                        {this.userData.role !== Constants.SUPER && <Column field="isActive" header="ลบ" headerStyle={{ width: "5rem" }} body={this.actionBodyTemplate} />}
                    </DataTable>
                </div>

                <Dialog visible={this.state.deleteItemDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={this.hideDeleteItemDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                        {this.state.items && (
                            <span>
                                Are you sure you want to delete <b>{this.state.items.itemNumber}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        );
    }
}
