import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { BlockUI } from 'primereact/blockui';
import { Calendar } from "primereact/calendar";
import QRCode from "react-qr-code";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";
import "../styles/css/Print.css";
import "../styles/css/Page.css";
import "../styles/css/MyDiv.css";

export const FormPrinting = () => {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [store] = useState(JSON.parse(localStorage.getItem("store")));

    // const [orders4, setOrders4] = useState([]);
    // const [orders6, setOrders6] = useState([]);
    // const [show4, setShow4] = useState(false);
    // const [show6, setShow6] = useState(false);

    const [orders, setOrders] = useState([]);
    const [show, setShow] = useState(false);
    const [orderNumber, setOrderNumber] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [msg, setMsg] = useState("");
    const [blockedDocument, setBlockedDocument] = useState(false);

    const blockDocument = async() => {
        setBlockedDocument(true);
        //await getOrderByOrderNumber();
    }

    const unblockedDocument = () => {
        setBlockedDocument(false);
    }

    const componentRef = useRef();

    useEffect(() => {
        // blockDocument();
        async function fetchMyAPI() {
            const store = JSON.parse(localStorage.getItem("store"));
            console.log("store", store.storeId);

            const orderDate = daily();
            console.log("orderDate", orderDate);

            const fetchs = require("sync-fetch");

            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };

            const res = fetchs(Constants.APIURL + "/ordersPrintingByCriteria?statusId=3&isPrinting=N&storeId=" + store.storeId , requestOptions);
            if (res.ok) {
                setOrders(res.json());
                setShow(true);
                // unblockedDocument();
            } else {
                setShow(false);
            }
        }
        fetchMyAPI();
    }, []);

    const daily = () => {
        let newDate = new Date();
        let year = newDate.getFullYear();

        var month;
        let month_raw = newDate.getMonth() + 1;
        if (month_raw < 10) {
            month = "0" + month_raw.toString();
        } else {
            month = month_raw.toString();
        }

        var date;
        let date_raw = newDate.getDate();
        if (date_raw < 10) {
            date = "0" + date_raw.toString();
        } else {
            date = date_raw.toString();
        }

        return "" + date + "-" + month + "-" + year;
    };

    const formatDateToDateStr= (data) => {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            if (day < 10) {
                day = "0" + day;
            }

            return  day+ "-" + month + "-" + data.getFullYear();
        }
    }

    const getOrderByOrderNumber = async () => {
        blockDocument();
        
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        var text = "/ordersPrintingByCriteria?storeId=" + store.storeId;
    
        console.log("orderNumber", orderNumber);

        console.log("orderDate", formatDateToDateStr(orderDate));

        if (orderDate) {
            text = text + "&orderDate=" + formatDateToDateStr(orderDate) +"&statusId=3&isPrinting=N";
        } 
        
        if (orderNumber) {
            text = text + "&orderNumber=" + orderNumber;
        }

        console.log("text", text);

        const res = await fetchs(Constants.APIURL + text, requestOptions);
        if (res.ok) {
            setOrders(res.json());
            setShow(true);
            setOrderNumber("");
            setOrderDate("");
            unblockedDocument();
            // setShow4(false);
            // setShow6(false);
        } else {
            setShow(false);
            setOrderNumber("");
            setOrderDate("");
            setMsg("data not found !")
            unblockedDocument();
            // setShow4(false);
            // setShow6(false);
        }
        // setOrders4([]);
        // setOrders6([]);
        //console.log("show", show);
        //console.log("orders", orders);
    };

    const getItemDetail = (id) => {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/orderItem/listItemDescription/" + id, requestOptions).json();

        const getOrderItem = res;

        let detailArray = { ...getOrderItem };
        let index = Object.keys(detailArray).toString();
        let detail = detailArray[index];
        return detail.itemList;
    };

    const updateIsPrinting = async () => {
        console.log(orders);

        for (let order of orders) {
            if (order) {
                console.log(order);
                let headersList = {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                };

                await fetch(Constants.APIURL + "/orderIsPrintingByOrderId/" + order.orderId, {
                    method: "PUT",
                    body: JSON.stringify({
                        isPrinting: "Y",
                        updatedBy: user.userId,
                    }),
                    headers: headersList,
                })
                    .then(function (response) {
                        return response.text();
                    })
                    .then(function (data) {
                        console.log(data);
                    });
            }
        }

        // for (let order of orders4) {
        //     if (order) {
        //         console.log(order);
        //         let headersList = {
        //             "Accept": "*/*",
        //             "Content-Type": "application/json"
        //         }

        //         await fetch(Constants.APIURL + "/isPrintingByOrderId/" + order.orderId, {
        //             method: "PUT",
        //             body: JSON.stringify({
        //                 "isPrinting": 'Y',
        //                 updatedBy: user.userId
        //             }),
        //             headers: headersList
        //         }).then(function (response) {
        //             return response.text();
        //         }).then(function (data) {
        //             console.log(data);
        //         })
        //     }
        // }

        // for (let order of orders6) {
        //     if (order) {
        //         console.log(order);
        //         let headersList = {
        //             "Accept": "*/*",
        //             "Content-Type": "application/json"
        //         }

        //         await fetch(Constants.APIURL + "/isPrintingByOrderId/" + order.orderId, {
        //             method: "PUT",
        //             body: JSON.stringify({
        //                 "isPrinting": 'Y',
        //                 updatedBy: user.userId
        //             }),
        //             headers: headersList
        //         }).then(function (response) {
        //             return response.text();
        //         }).then(function (data) {
        //             console.log(data);
        //         })
        //     }
        // }
    };

    const getTHbaht = (val) => {
        let text = "";
        if (val) {
            const THBText = require("thai-baht-text");
            text = THBText(val);
        }
        return text;
    };

    const style1 = {
        fontSize: 40,
        fontWeight: 500,
    };

    const style2 = {
        fontSize: 23,
        fontWeight: 500,
    };

    const handleSearch = async () => {
        await getOrderByOrderNumber();
    };

    const handleChangeDate = async(e) => {
        setOrderDate(e.value);
        setOrders([]);
        setShow(false);
        //blockDocument();
        //await getOrderByOrderNumber();
        
    }

    // const onAfterPrint = async () => {
    //     await updateIsPrinting();
    // }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        //onAfterPrint: () => onAfterPrint(),
        onPrintError: () => window.location.reload(),
    });

    const handleUpdate = async () => {
        await updateIsPrinting();
        window.location.reload();
    };

    const handleCancel = () => {
        window.location.reload();
    };

    return (
        <div>
            <div className="card">
                <h3>พิมพ์ใบปะหน้า</h3>
                <BlockUI blocked={blockedDocument} fullScreen />
            </div>

            <div className="card">
                <div className="p-formgroup-inline">
                    <div className="p-field">
                        <Calendar value={orderDate} onChange={handleChangeDate} dateFormat="dd-mm-yy" />
                    </div>
                    <div className="p-field">
                        <InputText className="p-inputtext-sm" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                    </div>
                    <div className="p-field">
                        <Button label="ค้นหา" className="p-button-sm p-button-info" onClick={handleSearch} />
                    </div>

                    <Button label="ยกเลิก" className="p-button-sm p-button-danger" onClick={handleCancel} />
                    <Button label="อัพเดตสถานะปริ้น" className="p-button-sm p-button-success" icon="pi pi-check" onClick={handleUpdate} style={{ marginLeft: "12px" }} />
                    <Button label="ปริ้น" className="p-button-sm" icon="pi pi-print" onClick={handlePrint} style={{ marginLeft: "12px" }} />
                </div>
                <div ref={componentRef} className="p-grid p-p-6 ">
                    {show &&
                        orders.map((val, key) => (
                            <div id="div-height" key={key} className="p-col-6" style={{ paddingBottom: "0px" }}>
                                <div className="p-grid" style={{ borderStyle: "groove", height: "320px" }}>
                                    <div className="p-col-6" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <div className="p-text-left print-font-small"> No.{key + 1}</div>
                                        {(val.deliveryId === 1 || val.deliveryId === 2) && (
                                            <div className="p-text-center" style={style1}>
                                                {" "}
                                                {val.delivery}{" "}
                                            </div>
                                        )}
                                        {val.deliveryId !== 1 && val.deliveryId !== 2 && (
                                            <div className="p-text-center" style={style2}>
                                                {" "}
                                                {val.delivery}{" "}
                                            </div>
                                        )}
                                        {val.paymentId === 1 && <div className="p-text-center print-font-large">{val.pocketNumber}</div>}
                                        <div className="p-text-center print-font-medium">ติดต่อ: ({val.mobilePhone})</div>
                                    </div>
                                    <div className="p-col-6" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <div className="p-text-center">
                                            <QRCode value={val.orderNumber} size={50} />
                                        </div>
                                        <div className="p-text-center print-font-medium">{val.orderNumber}</div>
                                        {val.paymentId === 1 && (
                                            <>
                                                <div className="p-text-center print-font-large">ยอดเงิน {val.amount} บาท</div>
                                                <div className="p-text-center print-font-medium">{getTHbaht(val.amount)}</div>
                                            </>
                                        )}
                                    </div>
                                    <div className="p-col-12" style={{ paddingTop: "0px", paddingBottom: "0px", borderTopStyle: "groove" }}>
                                        <div className="p-text-justify print-font-large">{val.name}</div>
                                        <div className="print-font-medium">
                                            {val.address} {val.zipcode} ({val.mobilePhone})
                                        </div>
                                    </div>
                                    <div className="p-col-12" style={{ paddingTop: "0px", paddingBottom: "0px", borderTopStyle: "groove" }}>
                                        {val.appointmentFlag === "Y" && (
                                            <div className="p-text-justify print-font-medium">
                                                <mark>สะดวกรับ={val.appointment}</mark>
                                                {getItemDetail(val.orderId)}
                                            </div>
                                        )}
                                        {val.appointmentFlag !== "Y" && <div className="p-text-justify print-font-medium"> {getItemDetail(val.orderId)}</div>}
                                    </div>
                                </div>

                                {(key + 1) % 6 === 0 && (
                                    <>
                                        {key === 5 && <div className="p-grid myDiv11" style={{ height: "54px" }}></div>}
                                        {(key > 5) && (key+1) < orders.length && <div className="p-grid myDiv12" style={{ height: "89px" }}></div>}
                                        {(key+1) === orders.length && <div className="p-grid myDiv13"></div>}
                                    </>
                                )}
                            </div>
                        ))}

                    {!show && (<div>{msg}</div>)}   
                </div>
            </div>
        </div>
    );
};
