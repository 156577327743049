import React, { Component } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormTrackingUpdate extends Component {
    userData;
    userStore;

    emptFilter = {
        storeId: "",
        dateForm: "",
        dateTo: "",
        orderNumber: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            //orders: [],

            order: [],
            orderNumber: "",
            trackingNumber: "",
            show: false,
            notfound: false,
            disableSaveTrackingNumber: true,
        };
        this.saveTrackingNumber = this.saveTrackingNumber.bind(this);
        this.getOrderNumber = this.getOrderNumber.bind(this);
        this.onTrackingChange =this.onTrackingChange.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        //const orders = await this.getOrder();
        //this.setState({ orders });
    }

    getOrderNumber(event) {
        console.log("query", event.query);
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByOrderNumber/" + event.query, requestOptions);
        if (res.ok) {
            this.setState({
                order: res.json(),
                show: true,
                notfound: false,
            });
        } else {
            this.setState({
                show: false,
                notfound: true,
            });
        }

        console.log("orderId", this.state.order.orderId);
    }

    saveTrackingNumber() {
      
        console.log("orderId", this.state.order.orderId);
        console.log("trackingNumber", this.state.trackingNumber);

        const requestOrderItem = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                trackingNumber: this.state.trackingNumber,
                updatedBy: this.userData.userId,
            }),
        };

        fetch(Constants.APIURL + "/orderTrackingNumber/" + this.state.order.orderId, requestOrderItem)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });


        this.setState({
            order: [],
            orderNumber: "",
            trackingNumber: "",
            show: false,
            notfound: false,
            disableSaveTrackingNumber: true,
        });

        this.toast.show({ severity: "success", summary: "บันทึกเลขพัสดุ", detail: "บันทึกสำเร็จ", life: 3000 });
    }

    
    onTrackingChange(e) {
        const val = (e.target && e.target.value) || "";
        console.log("onTrackingChange", val);
        this.setState({ trackingNumber: val, disableSaveTrackingNumber: false });
    }

    cancel() {
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="card">
                    <h1>แก้ไขเลขที่พัสดุ</h1>
                </div>
                <div className="card">
                    <div className="p-field p-grid">
                        <label htmlFor="orderNumber" className="p-col-fixed" style={{ width: "100px" }}>
                            รหัสออเดอร์
                        </label>
                        <div className="p-col">
                            <AutoComplete id="orderNumber" value={this.state.orderNumber} completeMethod={this.getOrderNumber} field="label" autoFocus optionGroupLabel="label" optionGroupChildren="items" onChange={(e) => this.setState({ orderNumber: e.value })} />
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="trackingNumber" className="p-col-fixed" style={{ width: "100px" }}>
                            เลขที่พัสดุ
                        </label>
                        <div className="p-col">
                            <InputText id="trackingNumber" value={this.state.trackingNumber} onChange={this.onTrackingChange} required style={{ width: "180px" }} />
                        </div>
                    </div>
                    <div className="p-field p-grid"></div>
                    <div className="p-field p-grid">
                        <label htmlFor="save" className="p-col-fixed" style={{ width: "100px" }}></label>
                        <div className="p-col">
                            <Button id="save" label="บันทึก" className="p-button-sm p-button-info" onClick={this.saveTrackingNumber} disabled={this.state.disableSaveTrackingNumber} />
                            <Button label="ยกเลิก" className="p-button-sm p-button-info" onClick={this.cancel} style={{ marginLeft: "12px" }} disabled={this.state.disableSaveTrackingNumber} />
                        </div>
                    </div>
                </div>

                {this.state.show && (
                    <div className="card">
                        <div>
                            รหัสออเดอร์ : {this.state.order.orderNumber}
                            <br />
                            ชื่อลูกค้า : {this.state.order.name}
                            <br />
                            ที่อยู่ : {this.state.order.address}
                            <br />
                            เบอร์โทรศัทพ์ : {this.state.order.mobilePhone}
                            <br />
                            ยอดขาย : {this.state.order.amount} บาท
                            <br />
                            เลขที่พัสดุ : {this.state.order.trackingNumber}
                            <br />
                            สถานะ : {this.state.order.status}
                        </div>
                    </div>
                )}
                {this.state.notfound && (
                    <div className="card">
                        <div>data not found!</div>
                    </div>
                )}
            </div>
        );
    }
}
