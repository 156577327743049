import React, { Component } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
//import { InputSwitch } from 'primereact/inputswitch';
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormStoreItem extends Component {
    // userData;
    // userStore;

    emptStoreItem = {
        selectedItem: "",
        price: 0,
    };

    constructor(props) {
        super(props);
        this.state = {
            storeItems: [],
            storeItem: this.emptStoreItem,
            items: [],
            selectedItem: "",
            deleteStoreItemDialog: false,
            editingRows: {},
        };
        this.userData = {};
        this.userStore = {};
        this.originalRows = {};
        this.getItems = this.getItems.bind(this);
        this.getStoreItems = this.getStoreItems.bind(this);
        this.saveStoreItem = this.saveStoreItem.bind(this);
        this.clearStoreItem = this.clearStoreItem.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.updateStoreItem = this.updateStoreItem.bind(this);
        this.deleteStoreItem = this.deleteStoreItem.bind(this);
        this.confirmDeleteStoreItem = this.confirmDeleteStoreItem.bind(this);
        this.hideDeleteStoreItemDialog = this.hideDeleteStoreItemDialog.bind(this);
        this.formatCurrency = this.formatCurrency.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.itemNumberBodyTemplate = this.itemNumberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.desBodyTemplate = this.desBodyTemplate.bind(this);
        this.totalBodyTemplate = this.totalBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.priceEditor = this.priceEditor.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const items = this.getItems();
        const storeItems = this.getStoreItems();
        this.setState({
            items: items,
            storeItems: storeItems,
        });
    }

    getItems() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/itemByIsActive", requestOptions).json();
        return res;
    }

    getStoreItems() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/storeItemsByStoreId/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getStoreItemsByIdd(id) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/storeItemsByStoreId/" + id, requestOptions).json();
        return res;
    }

    checkDupItem(itemId) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/storeItemsDupByStoreId/" + this.userStore.storeId + "/" + itemId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    saveStoreItem() {
        let state = { submitted: true };

        if (this.state.selectedItem && this.state.storeItem.price) {
            let storeItem = { ...this.state.storeItem };

            let dupItems = this.checkDupItem(this.state.selectedItem.itemId);

            console.log("dupItems >>>>", dupItems);

            if (dupItems) {

                this.toast.show({ severity: "warn", summary: "เพิ่มสินค้า", detail: "มีสินค้านี้แล้ว", life: 3000 });
           
            } else {
                let headersList = {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                };

                fetch(Constants.APIURL + "/storeItem", {
                    method: "POST",
                    body: JSON.stringify({
                        itemId: this.state.selectedItem.itemId,
                        price: storeItem.price,
                        storeId: this.userStore.storeId,
                        isActive: "Y",
                        createdBy: this.userData.userId,
                        updatedBy: this.userData.userId,
                    }),
                    headers: headersList,
                })
                    .then(function (response) {
                        return response.text();
                    })
                    .then(function (data) {
                        console.log(data);
                    });
                    this.toast.show({ severity: "success", summary: "เพิ่มสินค้า", detail: "บันทึกสำเร็จ", life: 3000 });
            }
            
        }
        
        state = {
            ...state,
            storeItems: this.getStoreItems(),
            storeItem: this.emptStoreItem,
            selectedItem: "",
            submitted: false,
            deleteStoreItemDialog: false,
            editingRows: {},
        };

        this.setState(state);
    }

    clearStoreItem() {
        let state = {
            //...state,
            storeItem: this.emptStoreItem,
            selectedItem: "",
            submitted: false,
            deleteStoreItemDialog: false,
            editingRows: {},
        };
        this.setState(state);
    }

    updateStoreItem(storeItem) {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/storeItem/" + storeItem.itemListId, {
            method: "PUT",
            body: JSON.stringify({
                price: storeItem.price,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        let state = {
            //...state,
            selectedItem: "",
            storeItems: this.getStoreItems(),
            storeItem: this.emptStoreItem,
            submitted: false,
            deleteStoreItemDialog: false,
            editingRows: {},
        };
        this.setState(state);

        this.toast.show({ severity: "success", summary: "แก้ไขสินค้า", detail: "บันทึกสำเร็จ", life: 3000 });
    }

    deleteStoreItem() {
        let storeItem = { ...this.state.storeItem };
        // let checked = '';
        // if (storeItem.isActive === 'Y') {
        //   checked = null;
        // } else {
        //   checked = 'Y';
        // }

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/storeItemIsActive/" + storeItem.itemListId, {
            method: "PUT",
            body: JSON.stringify({
                isActive: "N",
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        let state = {
            //...state,
            selectedItem: "",
            storeItems: this.getStoreItems(),
            storeItem: this.emptStoreItem,
            submitted: false,
            deleteStoreItemDialog: false,
            editingRows: {},
        };
        this.setState(state);

        this.toast.show({ severity: "success", summary: "แก้ไขสินค้า", detail: "ลบสำเร็จ", life: 3000 });

        this.refresh();
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.storeItems[event.index] };
    }

    onRowEditCancel(event) {
        let storeItemsEdit = this.state.storeItems;
        storeItemsEdit[event.index] = this.originalRows[event.index];
        this.setState((state, props) => ({
            storeItems: Object.values(storeItemsEdit),
        }));
        delete this.originalRows[event.index];
    }

    onRowEditChange(event) {
        this.setState((state, props) => ({
            editingRows: event.data,
        }));
        let editingRows = { ...this.state.editingRows };
        let length = Object.keys(editingRows).length;
        if (length === 1) {
            let itemListId = Object.keys(editingRows).toString();
            let storeItems = { ...this.state.storeItems };
            let storeItemsByEditingRows = Object.keys(storeItems).filter((key) => {
                if (storeItems[key].itemListId.toString() === itemListId) {
                    return itemListId;
                } else {
                    return "";
                }
            });
            let storeItem = storeItems[storeItemsByEditingRows];
            console.log("storeItem", storeItem);
            this.updateStoreItem(storeItem);
        }
    }

    onItemChange(e) {
        this.setState({ selectedItem: e.value });
    }

    onInputChange(e, value) {
        const val = (e.target && e.target.value) || "";
        let storeItem = { ...this.state.storeItem };
        storeItem[`${value}`] = val;

        this.setState({ storeItem });
    }

    onInputNumberChange(e, value) {
        const val = e.value || 0;
        let storeItem = { ...this.state.storeItem };
        storeItem[`${value}`] = val;

        this.setState({ storeItem });
    }

    onEditorValueChange(itemKey, props, value) {
        let updatedStoreItem = [...props.value];
        updatedStoreItem[props.rowIndex][props.field] = value;
        this.setState((state, props) => ({
            [`${itemKey}`]: updatedStoreItem,
        }));
    }

    priceEditor(itemKey, props) {
        return <InputNumber value={props.rowData["price"]} onValueChange={(e) => this.onEditorValueChange(itemKey, props, e.value)} mode="currency" currency="THB" locale="th-TH" />;
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    itemNumberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสสินค้า</span>
                <span>{rowData.itemNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อสินค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    desBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">คำอธิบาย</span>
                <span>{rowData.description}</span>
            </React.Fragment>
        );
    }

    totalBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวนสินค้า</span>
                {/* <span>{rowData.total}</span> */}0
            </React.Fragment>
        );
    }

    priceBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">จำนวนสินค้า</span>
                <span>{this.formatCurrency(rowData.price)}</span>
            </React.Fragment>
        );
        //return this.formatCurrency(rowData.price);
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.confirmDeleteStoreItem(rowData)} />

                {/* <InputSwitch
          checked={rowData.isActive}
          onChange={() => this.confirmDeleteStoreItem(rowData)} /> */}
            </React.Fragment>
        );
    }

    confirmDeleteStoreItem(storeItem) {
        this.setState({
            storeItem,
            deleteStoreItemDialog: true,
        });
    }

    hideDeleteStoreItemDialog() {
        this.setState({ deleteStoreItemDialog: false });
    }

    render() {
        const deleteStoreItemDialogFooter = (
            <React.Fragment>
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-sm p-button-text" onClick={this.hideDeleteStoreItemDialog} />
                <Button label="ยื่นยัน" icon="pi pi-check" className="p-button-sm p-button-text" onClick={this.deleteStoreItem} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />

                <div className="card">
                    <h3>รายการสินค้า</h3>
                </div>

                <div className="card">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <Dropdown
                                value={this.state.selectedItem}
                                options={this.state.items}
                                onChange={this.onItemChange}
                                optionLabel="name"
                                filter
                                showClear
                                filterBy="name"
                                placeholder="เลือกสินค้า"
                                autoFocus
                                className={classNames({
                                    "p-invalid": this.state.submitted && !this.state.selectedItem,
                                })}
                            />

                            {this.state.submitted && !this.state.selectedItem && <small className="p-error">Item number is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <span className="p-float-label">
                                <InputNumber
                                    id="price"
                                    value={this.state.storeItem.price}
                                    onChange={(e) => this.onInputNumberChange(e, "price")}
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted && !this.state.storeItem.price,
                                    })}
                                />
                                <label htmlFor="cost">ราคาขาย</label>
                            </span>
                            {this.state.submitted && !this.state.storeItem.price && <small className="p-error">Item price is required.</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6" style={{ marginTop: "7px" }}>
                            <Button label="บันทึก" icon="pi pi-save" className="p-button-sm p-button-raised p-button-info" style={{ width: "100px" }} onClick={this.saveStoreItem} />
                            <Button label="ล้างข้อมูล" icon="pi pi-undo" className="p-button-sm p-button-raised p-button-secondary" style={{ width: "120px", marginLeft: "7px" }} onClick={this.clearStoreItem} />
                        </div>
                    </div>
                </div>

                <div className="datatable-responsive-demo">
                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="itemListId"
                        value={this.state.storeItems}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        editMode="row"
                        editingRows={this.state.editingRows}
                        onRowEditInit={this.onRowEditInit}
                        onRowEditChange={this.onRowEditChange}
                        onRowEditCancel={this.onRowEditCancel}
                        style={{ fontSize: "11px" }}
                    >
                        <Column field="itemNumber" header="รหัสสินค้า" body={this.itemNumberBodyTemplate}></Column>
                        <Column field="name" header="ชื่อสินค้า" body={this.nameBodyTemplate}></Column>
                        <Column field="description" header="คำอธิบาย" body={this.desBodyTemplate}></Column>
                        <Column field="total" header="จำนวนสินค้า" body={this.totalBodyTemplate}></Column>
                        <Column field="price" header="ราคาขาย" body={this.priceBodyTemplate} editor={(props) => this.priceEditor("items", props)} />
                        <Column header="แก้ไข" rowEditor headerStyle={{ width: "5rem" }} />
                        <Column field="isActive" header="ลบ" headerStyle={{ width: "5rem" }} body={this.actionBodyTemplate} />
                    </DataTable>
                </div>

                <Dialog visible={this.state.deleteStoreItemDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteStoreItemDialogFooter} onHide={this.hideDeleteStoreItemDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                        {this.state.storeItems && (
                            <span>
                                Are you sure you want to delete <b>{this.state.storeItems.name}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        );
    }
}
