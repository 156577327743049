import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
    },
    no: {
        width: "5%",
        borderRightWidth: 1,
    },
    description: {
        width: "25%",
        textAlign: "left",
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    number: {
        width: "10%",
        borderRightWidth: 1,
        textAlign: "right",
        paddingRight: 8,
    },
    piece: {
        width: "10%",
        borderRightWidth: 1,
        textAlign: "right",
        paddingRight: 8,
    },
    set: {
        width: "10%",
        borderRightWidth: 1,
        textAlign: "right",
        paddingRight: 8,
    },
    remark: {
        width: "15%",
        textAlign: "right",
        paddingRight: 8,
    },
});

const RequisitionTableRow = ({ item }) => {
    console.log("pdf requisitionRow item", item);
    return (
        <Fragment>
            {item.length > 0 &&
                item.map((val, key) => (
                    <View style={styles.row} key={val.itemNumber}>
                        <Text style={styles.no}>{key + 1}</Text>
                        <Text style={styles.number}>{val.itemNumber}</Text>
                        <Text style={styles.description}>{val.itemSet ? val.itemSet : "-"}</Text>
                        <Text style={styles.set}>{val.totalSet}</Text>
                        <Text style={styles.description}>{val.itemDesc}</Text>
                        <Text style={styles.piece}>{val.total}</Text>
                        <Text style={styles.remark}>-</Text>
                    </View>
                ))}
        </Fragment>
    );
};

export default RequisitionTableRow;
