import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import * as Constants from "../../constants/Constant";
import "../../styles/css/Table.css";

export class FormReportSaleDailyStock extends Component {
    // userData;
    // userStore;
    emptReport = {
        month: "",
        storeName: "",
        countOrder: "",
        amount: "",
        paymentStatus: "",
    };
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
        };
        this.userData = {};
        this.userStore = {};

        this.getReport = this.getReport.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const reports = await this.getReport();

        this.setState({
            reports: reports,
        });
    }

    getReport() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        const res = fetchs(Constants.APIURL + "/report/dailySaleInventoryStocks", requestOptions).json();
        console.log("res", res);
        if (res) {
            return res;
        } else {
            return this.emptReport;
        }
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.reports);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
            this.saveAsExcelFile(excelBuffer, "monthly_sale_by_store_report");
        });
    }

    saveAsExcelFile(buffer, fileName) {
        // import("file-saver").then((FileSaver) => {
        //     let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        //     let EXCEL_EXTENSION = ".xlsx";
        //     const data = new Blob([buffer], {
        //         type: EXCEL_TYPE,
        //     });
        //     FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        // });
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render() {
        const header = (
            <div className="p-d-flex p-ai-center export-buttons">
                <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-sm p-button-success p-mr-2" data-pr-tooltip="XLS" />
            </div>
        );
        return (
            <div>
                <div className="card">
                    <h3>รายงาน:สินค้าคงเหลือ</h3>
                </div>
                <div className="card">
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable
                        value={this.state.reports}
                        header={header}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        style={{ fontSize: "11px" }}
                    >
                        <Column field="itemMasterNumber" header="รหัสสินค้า" sortable />
                        <Column field="itemMaster" header="ชื่อสินค้า" sortable />
                        <Column field="totalIn" header="จำนวนสินค้าเข้า" sortable />
                        <Column field="totalAdj" header="จำนวนปรับสินค้า" sortable />
                        <Column field="totalOut" header="จำนวนสินค้าออก" sortable />
                        <Column field="balance" header="จำนวนสินค้าคงเหลือ" sortable />
                    </DataTable>
                </div>
            </div>
        );
    }
}
