import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
//import { PropTypes } from "prop-types";
import * as Constants from "../constants/Constant";
import "../styles/css/Login.css";
import "../styles/css/Form.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

async function loginUser(credentials) {
    console.log("username", credentials.username);
    console.log("password", credentials.password);

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            username: credentials.username,
            password: credentials.password,
        }),
    };

    return await fetch(Constants.APIURL + "/login", requestOptions).then((data) => data.json());
}

export default function Login({ setToken }) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [submitted, setSubmited] = useState(false);
    const toast = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("username", username);
        console.log("password", password);

        const response = await loginUser({
            username,
            password,
        });
        console.log("response", response);
        console.log("resUser", JSON.stringify(response.user));
        console.log("accessToken", response.accessToken);

        //setToken(response.token);
        setSubmited(true);

        if (response.accessToken) {
            let user = response.user;
            console.log("user", user);
            console.log("accessToken", response.accessToken);

            localStorage.setItem("accessToken", response.accessToken);
            localStorage.setItem("user", JSON.stringify(response["user"]));
            localStorage.setItem("store", JSON.stringify(response["store"]));
            window.location.href = "/home";
        } else {
            toast.current.show({ severity: "warn", summary: "เข้าสู้ระบบ", detail: "ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง, โปรดลองใหม่อีกครั้ง", life: 3000 });
        }
    };

    return (
        /**<div className="login-wrapper">**/
        <div style={{ marginTop: "50px" }}>
            <Toast ref={toast} />
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">SSO LOGIN</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="p-field" style={{ margin: "20px" }}>
                            <span className="p-float-label">
                                <InputText id="username" name="username" onChange={(e) => setUsername(e.target.value)} autoFocus style={{ width: "190px" }} className={classNames({ "p-inputtext-sm p-invalid": submitted && !username })} />
                                <label htmlFor="username">ชื่อผู้ใช้*</label>
                            </span>
                            {submitted && !username && <small className="p-error">Name is required.</small>}
                        </div>
                        <div className="p-field" style={{ margin: "20px" }}>
                            <span className="p-float-label">
                                <Password id="password" name="password" onChange={(e) => setPassword(e.target.value)} toggleMask feedback={false} className={classNames({ "p-invalid": submitted && !password })} />
                                <label htmlFor="password">รหัสผ่าน*</label>
                            </span>
                            {submitted && !password && <small className="p-error">Password is required.</small>}
                        </div>
                        <Button type="submit" label="Submit" className="p-mt-2" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </form>
                </div>
            </div>
        </div>
    );
}
/*
Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
*/
