import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import RequisitionTableHeader from "./RequisitionTableHeader";
import RequisitionTableRow from "./RequisitionTableRow";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderWidth: 1,
    },
});

const RequisitionItemsTable = ({ item }) => {
    console.log("pdf requisitionTable item", item);
    console.log("pdf requisitionTable items", item.items);
    return (
        <View style={styles.tableContainer}>
            <RequisitionTableHeader />
            <RequisitionTableRow item={item.items} />
        </View>
    );
};

export default RequisitionItemsTable;
