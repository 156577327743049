import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Constants from "../constants/Constant";

import "../styles/css/Table.css";

export class FormOrder extends Component {
    // userData;
    // userStore;

    constructor(props) {
        super(props);

        this.state = {
            stores: [],
            orders: [],
            status: [],
            delivery: [],
            deleteOrderDialog: false,
            deleteOrdersDialog: false,
            selectedOrders: null,
            selectedDelivery: null,
            selectedStatus: null,
            selectedDate: null,
            selectedAppointment: null,
            globalFilter: "",
            copied: false,
        };

        this.userData = {};
        this.userStore = {};
        this.numberBodyTemplate = this.numberBodyTemplate.bind(this);
        this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
        this.addressBodyTemplate = this.addressBodyTemplate.bind(this);
        this.amountBodyTemplate = this.amountBodyTemplate.bind(this);
        this.deliveryBodyTemplate = this.deliveryBodyTemplate.bind(this);
        this.trackingBodyTemplate = this.trackingBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.userBodyTemplate = this.userBodyTemplate.bind(this);
        this.linkBodyTemplate = this.linkBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.saleItemBodyTemplate = this.saleItemBodyTemplate.bind(this);
        this.dateBodyTemplate = this.dateBodyTemplate.bind(this);
        this.getDelivery = this.getDelivery.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getStore = this.getStore.bind(this);
        this.getOrder = this.getOrder.bind(this);
        // this.getOrderItem = this.getOrderItem.bind(this);
        // this.getItemDetail = this.getItemDetail.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.editOrder = this.editOrder.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
        this.deleteSelectedOrders = this.deleteSelectedOrders.bind(this);
        this.confirmDeleteOrder = this.confirmDeleteOrder.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.hideDeleteOrdersDialog = this.hideDeleteOrdersDialog.bind(this);
        this.hideDeleteOrderDialog = this.hideDeleteOrderDialog.bind(this);
        this.reset = this.reset.bind(this);
        this.onDeliveryChange = this.onDeliveryChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.filterDate = this.filterDate.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.formatDateValue = this.formatDateValue.bind(this);
        this.checkRole = this.checkRole.bind(this);
        this.onAppointmentChange = this.onAppointmentChange.bind(this);
        this.appointmentBodyTemplate = this.appointmentBodyTemplate.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        //console.log("id" , this.props.match.params.id);
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));

        const orders = await this.checkRole();
        const status = await this.getStatus();
        const delivery = await this.getDelivery();

        //console.log(status)
        //console.log(delivery)
        //console.log(orders)

        this.setState({
            orders: orders,
            delivery: delivery,
            status: status,
        });
    }

    checkRole() {
        if (this.userData.role === Constants.SALE) {
            const orders = this.getOrderUser();
            this.setState({ orders });
            return this.getOrderUser();
        } else {
            const orders = this.getOrder();
            this.setState({ orders });
            return this.getOrder();
        }
    }

    getOrderUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByUser/?storeId=" + this.userStore.storeId + "&userId=" + this.userData.userId + "&statusId=1", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getOrder() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersStatusByStoreId/" + this.userStore.storeId + "/1", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getDelivery() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/deliverName/", requestOptions).json();
        return res;
    }

    getStatus() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/statusName/", requestOptions).json();
        return res;
    }

    getStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/store/", requestOptions).json();
        return res;
    }

    // getOrderItem(id) {
    //     const fetchs = require("sync-fetch");
    //     const requestOptions = {
    //         method: "GET",
    //         headers: { "Content-Type": "application/json" },
    //     };
    //     const res = fetchs(Constants.APIURL + "/orderItem/listItemName/" + id, requestOptions).json();
    //     return res;
    // }

    // getItemDetail(id) {
    //     //console.log("getItemDetail id " + id);
    //     let getOrderItem = this.getOrderItem(id);
    //     let detailArray = { ...getOrderItem };
    //     let index = Object.keys(detailArray).toString();
    //     let detail = detailArray[index];
    //     return detail.itemList;
    // }

    deleteOrder() {
        let headersList = {
            Accept: "*/*",
        };

        fetch(Constants.APIURL + "/orderItem/orderItemByOrderId/" + this.state.order.orderId, {
            method: "DELETE",
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        fetch(Constants.APIURL + "/order/" + this.state.order.orderId, {
            method: "DELETE",
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });

        let state = { submitted: true };
        state = {
            ...state,
            orders: this.checkRole(),
            selectedOrders: "",
            deleteOrderDialog: false,
            copied: false,
        };
        this.setState(state);
        this.toast.show({ severity: "success", summary: "ออเดอร์", detail: "ลบสำเร็จ", life: 3000 });
        this.refresh();
    }

    deleteSelectedOrders() {
        let selectedOrders = this.state.selectedOrders;

        console.log("deleteSelectedOrders >>>> selectedOrders : ", selectedOrders);

        for (let order of selectedOrders) {
            console.log("deleteSelectedOrders >>>> order : ", order.orderId);

            let headersList = {
                Accept: "*/*",
            };

            fetch(Constants.APIURL + "/orderItem/orderItemByOrderId/" + order.orderId, {
                method: "DELETE",
                headers: headersList,
            })
                .then(function (response) {
                    return response.text();
                })
                .then(function (data) {
                    console.log(data);
                });

            fetch(Constants.APIURL + "/order/" + order.orderId, {
                method: "DELETE",
                headers: headersList,
            })
                .then(function (response) {
                    return response.text();
                })
                .then(function (data) {
                    console.log(data);
                });

            let state = { submitted: true };
            state = {
                ...state,
                orders: this.checkRole(),
                selectedOrders: "",
                deleteOrdersDialog: false,
                copied: false,
            };
            this.setState(state);
            this.toast.show({ severity: "success", summary: "ออเดอร์", detail: "ลบสำเร็จ", life: 3000 });
            this.refresh();
        }
    }

    refresh() {
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    newOrder() {
        this.props.history.push("/newOrder");
    }

    editOrder(order) {
        this.props.history.push("/editOrder/" + order.orderId);
    }

    confirmDeleteOrder(order) {
        this.setState({
            order,
            deleteOrderDialog: true,
        });
    }

    confirmDeleteSelected() {
        this.setState({ deleteOrdersDialog: true });
    }

    hideDeleteOrdersDialog() {
        this.setState({ deleteOrdersDialog: false });
    }

    hideDeleteOrderDialog() {
        this.setState({ deleteOrderDialog: false });
    }

    reset() {
        this.setState({
            selectedDate: null,
            selectedDelivery: null,
            selectedStatus: null,
            globalFilter: "",
        });

        this.dt.reset();
    }

    onStatusChange(e) {
        this.dt.filter(e.value, "status", "equals");
        this.setState({ selectedStatus: e.value });
    }

    onDeliveryChange(e) {
        this.dt.filter(e.value, "delivery", "equals");
        this.setState({ selectedDelivery: e.value });
    }

    onDateChange(e) {
        this.dt.filter(e.value, "orderDateTime", "custom");
        this.setState({ selectedDate: e.value });
        //console.log(e.value);
    }

    onAppointmentChange(e) {
        this.dt.filter(e.value, "appointment", "custom");
        this.setState({ selectedAppointment: e.value });
    }



    filterDate(value, filter) {
        console.log("filterDate value", this.formatDateValue(value));
        console.log("filterDate filter",this.formatDate(filter));


        if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        
        return this.formatDateValue(value) === this.formatDate(filter);
    }

    formatDateValue(value) {
        return value.substring(1, value.indexOf(' '));
    }


    formatDate(data) {
        if (data) {
            let month = data.getMonth() + 1;
            let day = data.getDate();

            if (month < 10) {
                month = "0" + month;
            }

            // if (day < 10) {
            //     day = "0" + day;
            // }

            return day + "-" + month + "-" + data.getFullYear();
        }
    }

    dateBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">วันที่</span>
                <span>{rowData.orderDateTime}</span>
            </React.Fragment>
        );
    }

    numberBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัสออเดอร์</span>
                <span>{rowData.orderNumber}</span>
            </React.Fragment>
        );
    }

    nameBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ชื่อลูกค้า</span>
                <span>{rowData.name}</span>
            </React.Fragment>
        );
    }

    addressBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ที่อยู่</span>
                <span>
                    {rowData.address} {rowData.zipcode}
                </span>
            </React.Fragment>
        );
    }

    amountBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ยอดเงิน</span>
                <span>{rowData.amount}</span>
            </React.Fragment>
        );
    }

    formatCurrency(value) {
        return value.toLocaleString("th-TH", { style: "currency", currency: "THB" });
    }

    saleItemBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สินค้า</span>
                <span>{rowData.product}</span>
            </React.Fragment>
        );
    }

    deliveryBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">การจัดส่ง</span>
                <span> {rowData.delivery}</span>
            </React.Fragment>
        );
    }

    trackingBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">รหัส tracking</span>
                <span>{rowData.trackingNumber}</span>
            </React.Fragment>
        );
    }

    statusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สถานะ</span>
                <span>{rowData.status}</span>
            </React.Fragment>
        );
    }

    userBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">แอดมิน</span>
                <span>{rowData.createdByName}</span>
            </React.Fragment>
        );
    }

    linkBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ลิงค์</span>
                <span>{rowData.link}</span>
            </React.Fragment>
        );
    }

    facebookBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">เฟซบุ๊ก</span>
                <span>{rowData.facebook}</span>
            </React.Fragment>
        );
    }

    mobilephoneBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">เบอร์โทรศัทพ์</span>
                <span>{rowData.mobilePhone}</span>
            </React.Fragment>
        );
    }

    copyBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ลิงค์</span>
                <CopyToClipboard
                    text={rowData.link}
                    //onCopy={() => this.setState({ copied: true })}
                >
                    <Button icon="pi pi-copy" className="p-button-sm p-button-text" onClick={this.hideDeleteOrdersDialog} />
                </CopyToClipboard>
            </React.Fragment>
        );
    }

    paymentTypeBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">ประเภทการชำระเงิน</span>
                <span>{rowData.payment}</span>
            </React.Fragment>
        );
    }

    paymentStatusBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สถานะการชำระเงิน</span>
                <span>{rowData.paymentStatus}</span>
            </React.Fragment>
        );
    }

    appointmentBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">สะดวกรับ</span>
                <span> {rowData.appointment}</span>
            </React.Fragment>
        );
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.editOrder(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-secondary p-button-text" onClick={() => this.confirmDeleteOrder(rowData)} />
            </React.Fragment>
        );
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="เพิ่มออเดอร์" icon="pi pi-shopping-cart" className="p-button-sm p-button-info p-mr-2" onClick={this.newOrder} />
                <Button label="ยกเลิกออเดอร์" icon="pi pi-trash" className="p-button-sm p-button-danger" onClick={this.confirmDeleteSelected} style={{ marginLeft: "7px" }} disabled={!this.state.selectedOrders || !this.state.selectedOrders.length} />
            </React.Fragment>
        );
    }

    render() {
        const dateFilter = <Calendar value={this.state.selectedDate} onChange={this.onDateChange} dateFormat="dd-mm-yy" className="p-column-filter" />;

        const deliveryFilter = <Dropdown value={this.state.selectedDelivery} options={this.state.delivery} onChange={this.onDeliveryChange} itemTemplate={this.deliveryItemTemplate} optionLabel="delivery" optionValue="delivery" className="p-column-filter" showClear />;

        const appointmentFilter = <Calendar value={this.state.selectedAppointment} onChange={this.onAppointmentChange} dateFormat="dd-mm-yy" className="p-column-filter" />;

        // const statusFilter =
        //   <Dropdown value={this.state.selectedStatus}
        //     options={this.state.status}
        //     onChange={this.onStatusChange}
        //     itemTemplate={this.statusItemTemplate}
        //     optionLabel="status"
        //     optionValue="status"
        //     className="p-column-filter"
        //     showClear />;

        const deleteOrderDialogFooter = (
            <React.Fragment>
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-sm p-button-text" onClick={this.hideDeleteOrderDialog} />
                <Button label="ยื่นยัน" icon="pi pi-check" className="p-button-sm p-button-text" onClick={this.deleteOrder} />
            </React.Fragment>
        );

        const deleteOrdersDialogFooter = (
            <React.Fragment>
                <Button label="ยกเลิก" icon="pi pi-times" className="p-button-sm p-button-text" onClick={this.hideDeleteOrdersDialog} />
                <Button label="ยื่นยัน" icon="pi pi-check" className="p-button-sm p-button-text" onClick={this.deleteSelectedOrders} />
            </React.Fragment>
        );
        const header = (
            <div className="table-header">
                <Button type="button" label="Clear" className="p-button-sm p-button-outlined" icon="pi pi-filter-slash" onClick={this.reset} />
            </div>
        );

        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="card">
                    <h3>รายการออเดอร์สินค้า </h3>
                </div>

                <div className="card datatable-responsive-demo">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}></Toolbar>
                    <DataTable
                        className="p-datatable-responsive-demo p-datatable-sm"
                        dataKey="orderId"
                        value={this.state.orders}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={200}
                        rowsPerPageOptions={[100, 200, 500, 1000]}
                        globalFilter={this.state.globalFilter}
                        selection={this.state.selectedOrders}
                        onSelectionChange={(e) => this.setState({ selectedOrders: e.value })}
                        ref={(el) => (this.dt = el)}
                        style={{ fontSize: "11px" }}
                        header={header}
                        //scrollable scrollHeight="400px" virtualScrollerOptions={{ itemSize: 46 }}
                        //showGridlines
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
                        <Column field="orderDateTime" header="วันที่" body={this.dateBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={this.filterDate} />
                        <Column field="orderNumber" header="รหัสออเดอร์" body={this.numberBodyTemplate} filter filterField="orderNumber" filterMatchMode="contains" sortable />
                        <Column field="name" header="ชื่อลูกค้า" body={this.nameBodyTemplate} filter filterField="name" filterMatchMode="contains" sortable />
                        <Column field="facebook" header="เฟซบุ๊ก" body={this.facebookBodyTemplate} filter filterField="facebook" filterMatchMode="contains" sortable />
                        <Column field="address" header="ที่อยู่" body={this.addressBodyTemplate} filter filterField="address" filterMatchMode="contains" sortable />
                        <Column field="mobilePhone" header="เบอร์โทรศัทพ์" body={this.mobilephoneBodyTemplate} filter filterField="mobilePhone" filterMatchMode="contains" sortable />

                        <Column field="delivery" header="การจัดส่ง" body={this.deliveryBodyTemplate} filterElement={deliveryFilter} filter sortable />
                        {/* <Column
                        field="status"
                        header="สถานะ"
                        body={this.statusBodyTemplate}
                        filterElement={statusFilter}
                        filter
                        sortable
                        /> */}
                        <Column field="appointment" header="สะดวกรับ" body={this.appointmentBodyTemplate} filter filterElement={appointmentFilter} filterFunction={this.filterDate} sortable />
                        <Column field="amount" header="ยอดเงิน" body={this.amountBodyTemplate} filter filterField="amount" filterMatchMode="contains" sortable />
                        <Column field="product" header="สินค้า" body={this.saleItemBodyTemplate} sortable />
                        <Column field="payment" header="ประเภทการชำระเงิน" body={this.paymentTypeBodyTemplate} sortable />
                        <Column field="paymentStatus" header="สถานะการชำระเงิน" body={this.paymentStatusBodyTemplate} sortable />
                        <Column field="link" header="คัดลอกลิงค์" body={this.copyBodyTemplate} />
                        <Column field="createdByName" header="แอดมิน" body={this.userBodyTemplate} />
                        <Column header="แก้ไข" body={this.actionBodyTemplate} />
                    </DataTable>
                </div>

                <Dialog visible={this.state.deleteOrderDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteOrderDialogFooter} onHide={this.hideDeleteOrderDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                        {this.state.order && (
                            <span>
                                คุณต้องการลบรายการขายเลขที่ <b>{this.state.order.orderNumber}</b>
                                หรือไม่?
                            </span>
                        )}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteOrdersDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteOrdersDialogFooter} onHide={this.hideDeleteOrdersDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                        <span>คุณต้องการลบรายการขายที่เลือกหรือไม่?</span>
                    </div>
                </Dialog>
            </div>
        );
    }
}
