import React, { Component } from "react";
import { Chart } from "primereact/chart";
import { Panel } from "primereact/panel";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Constants from "../constants/Constant";
import "../styles/css/Button.css";
export class Dashboard extends Component {
    emptySalesSummary = {
        count: 0,
        saleAmount: 0,
        name: "sales",
    };

    constructor(props) {
        super(props);

        this.state = {
            daily: this.daily(),
            years: [],
            months: [],
            selectedYear: "",
            selectedMonth: "",
            monthYear: "",
            // by store
            saleByStore: [],
            saleMonthByStore: [],
            orderingStatusByStore: "",
            packingStatusByStore: "",
            pocketStatusByStore: "",
            trackingStatusByStore: "",
            trackingMonthStatusByStore: "",
            salesSummaryByStore: [],
            // by user
            saleUserByUser: [],
            orderingStatusByUser: "",
            packingStatusByUser: "",
            trackingStatusByUser: "",
            trackingMonthStatusByUser: "",
            pocketStatusByUser: "",
            orderingTotalByUser: "",
        };
        this.userData = {};
        this.userStore = {};
        this.options = this.getLightTheme();
        this.daily = this.daily(this);
        this.getMonth = this.getMonth.bind(this);
        this.getYear = this.getYear.bind(this);
        this.monthly = this.monthly.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onChartStoreChange = this.onChartStoreChange.bind(this);
        this.onChartUserChange = this.onChartUserChange.bind(this);
        // by store
        this.getSaleUserByStore = this.getSaleUserByStore.bind(this);
        this.getSaleMonthlyUserByStore = this.getSaleMonthlyUserByStore.bind(this);
        this.getOrderingStatusByStore = this.getOrderingStatusByStore.bind(this);
        this.getPackingStatusByStore = this.getPackingStatusByStore.bind(this);
        this.getPocketStatusByStore = this.getPocketStatusByStore.bind(this);
        this.getTrackingStatusByStore = this.getTrackingStatusByStore.bind(this);
        this.getSalesSummaryByStore = this.getSalesSummaryByStore.bind(this);
        this.getTrackingMonthStatusByStore = this.getTrackingMonthStatusByStore.bind(this);
        // by user
        this.getSaleUserByUser = this.getSaleUserByUser.bind(this);
        this.getOrderingStatusByUser = this.getOrderingStatusByUser.bind(this);
        this.getPackingStatusByUser = this.getPackingStatusByUser.bind(this);
        this.getTrackingStatusByUser = this.getTrackingStatusByUser.bind(this);
        this.getPocketStatusByUser = this.getPocketStatusByUser.bind(this);
        this.getOrderingTotalByUser = this.getOrderingTotalByUser.bind(this);
        this.getTrackingMonthStatusByUser = this.getTrackingMonthStatusByUser.bind(this);
    }

    async componentDidMount() {
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));
        //month-year
        const years = await this.getYear();
        const months = await this.getMonth();
        const monthYear = this.monthly();
        // by store
        const saleByStore = await this.getSaleUserByStore();
        const saleMonthByStore = await this.getSaleMonthlyUserByStore();
        const orderingStatusByStore = await this.getOrderingStatusByStore();
        const packingStatusByStore = await this.getPackingStatusByStore();
        const pocketStatusByStore = await this.getPocketStatusByStore();
        const trackingStatusByStore = await this.getTrackingStatusByStore();
        const trackingMonthStatusByStore = await this.getTrackingMonthStatusByStore();
        const salesSummaryByStore = await this.getSalesSummaryByStore();

        let indexOrderByStore = Object.keys(orderingStatusByStore).toString();
        let countOrderByStore = orderingStatusByStore[indexOrderByStore].count;
        let indexPackByStore = Object.keys(packingStatusByStore).toString();
        let countPackByStore = packingStatusByStore[indexPackByStore].count;
        let indexPocketByStore = Object.keys(pocketStatusByStore).toString();
        let countPocketByStore = pocketStatusByStore[indexPocketByStore].count;
        let indexTrackByStore = Object.keys(trackingStatusByStore).toString();
        let countTrackByStore = trackingStatusByStore[indexTrackByStore].count;
        let indexTrackMonthByStore = Object.keys(trackingMonthStatusByStore).toString();
        let countTrackMonthByStore = trackingMonthStatusByStore[indexTrackMonthByStore].count;
        // by user
        const saleUserByUser = await this.getSaleUserByUser();
        const orderingStatusByUser = await this.getOrderingStatusByUser();
        const packingStatusByUser = await this.getPackingStatusByUser();
        const pocketStatusByUser = await this.getPocketStatusByUser();
        const trackingStatusByUser = await this.getTrackingStatusByUser();
        const trackingMonthStatusByUser = await this.getTrackingMonthStatusByUser();
        const orderingTotalByUser = await this.getOrderingTotalByUser();

        let indexOrderByUser = Object.keys(orderingStatusByUser).toString();
        let countOrderByUser = orderingStatusByUser[indexOrderByUser].count;
        let indexPackByUser = Object.keys(packingStatusByUser).toString();
        let countPackByUser = packingStatusByUser[indexPackByUser].count;
        let indexPocketByUser = Object.keys(pocketStatusByUser).toString();
        let countPocketByUser = pocketStatusByUser[indexPocketByUser].count;
        let indexTrackByUser = Object.keys(trackingStatusByUser).toString();
        let countTrackByUser = trackingStatusByUser[indexTrackByUser].count;
        let indexTrackMonthByUser = Object.keys(trackingMonthStatusByUser).toString();
        let countTrackMonthByUser = trackingMonthStatusByUser[indexTrackMonthByUser].count;
        let indexTotalByUser = Object.keys(orderingTotalByUser).toString();
        let countTotalByUser = orderingTotalByUser[indexTotalByUser].count;

        console.log("saleByStore", saleByStore);
        console.log("saleMonthByStore", saleMonthByStore);
        console.log("basicData", this.basicData);

        this.setState({
            //month-year
            years: years,
            months: months,
            monthYear: monthYear,
            // by store
            saleByStore: saleByStore,
            saleMonthByStore: saleMonthByStore,
            orderingStatusByStore: countOrderByStore,
            packingStatusByStore: countPackByStore,
            pocketStatusByStore: countPocketByStore,
            trackingStatusByStore: countTrackByStore,
            trackingMonthStatusByStore: countTrackMonthByStore,
            salesSummaryByStore: salesSummaryByStore,
            // by user
            saleUserByUser: saleUserByUser,
            orderingStatusByUser: countOrderByUser,
            packingStatusByUser: countPackByUser,
            pocketStatusByUser: countPocketByUser,
            trackingStatusByUser: countTrackByUser,
            trackingMonthStatusByUser: countTrackMonthByUser,
            orderingTotalByUser: countTotalByUser,
        });
    }

    getLightTheme() {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y1: {
                    type: "linear",
                    display: true,
                    position: "right",
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: "#ebedef",
                    },
                },
            },
        };

        return {
            basicOptions,
            multiAxisOptions,
        };
    }

    daily() {
        let newDate = new Date();
        let year = newDate.getFullYear();

        var month;
        let month_raw = newDate.getMonth() + 1;
        if (month_raw < 10) {
            month = "0" + month_raw.toString();
        } else {
            month = month_raw.toString();
        }

        var date;
        let date_raw = newDate.getDate();
        if (date_raw < 10) {
            date = "0" + date_raw.toString();
        } else {
            date = date_raw.toString();
        }

        return "" + date + month + year;
    }

    getMonth() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        const res = fetchs(Constants.APIURL + "/report/month", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getYear() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };

        const res = fetchs(Constants.APIURL + "/report/year", requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    monthly() {
        let newDate = new Date();
        let year = newDate.getFullYear();

        var month;
        let month_raw = newDate.getMonth() + 1;
        if (month_raw < 10) {
            month = "0" + month_raw.toString();
        } else {
            month = month_raw.toString();
        }

        //var date
        //let date_raw = newDate.getDate();
        //if (date_raw < 10) { date = "0" + date_raw.toString() } else { date = date_raw.toString() }

        return "" + month + year;
    }

    onMonthChange(e) {
        this.setState({ selectedMonth: e.value });
    }

    onYearChange(e) {
        this.setState({ selectedYear: e.value });
    }

    async onChartStoreChange() {
        const saleByStore = await this.getSaleUserByStore();
        this.setState({ saleByStore: saleByStore });
    }

    async onChartUserChange() {
        const saleUserByUser = await this.getSaleUserByUser();
        this.setState({ saleUserByUser: saleUserByUser });
    }

    // by store
    getSaleMonthlyUserByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleMonthlyByStoreId/" + this.userStore.storeId, requestOptions);
        const data = res.json();

        console.log("getSaleMonthlyUserByStore", data);

        return data;
    }

    getSaleUserByStore() {
        let monthly = null;

        if (this.state.selectedMonth && this.state.selectedYear) {
            monthly = this.state.selectedMonth.code + this.state.selectedYear.year;
        } else {
            monthly = this.monthly();
        }

        this.setState({ monthYear: monthly });

        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleByStoreId/" + this.userStore.storeId + "/" + monthly, requestOptions);
        const data = res.json();

        console.log("getSaleUserByStore", data);

        return data;
    }

    getOrderingStatusByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusByStoreId/1/" + this.userStore.storeId, requestOptions).json();
        return res;
    }

    getPackingStatusByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusPackingByStoreId/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getPocketStatusByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusByStoreId/3/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getTrackingStatusByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusTrackingByStoreId/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getTrackingMonthStatusByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleMonthStatusTrackingByStoreId/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getSalesSummaryByStore() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleUserByStoreId/" + this.userStore.storeId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return [this.emptySalesSummary];
        }
    }

    // by user
    getSaleUserByUser() {
        let monthly = null;

        if (this.state.selectedMonth && this.state.selectedYear) {
            monthly = this.state.selectedMonth.code + this.state.selectedYear.year;
        } else {
            monthly = this.monthly();
        }

        console.log("monthly", monthly);

        this.setState({ monthYear: monthly });

        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleByUserId/" + this.userData.userId +"/" + monthly, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getOrderingTotalByUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleTotalByUserId/" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getOrderingStatusByUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusByUserId/1/" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getPackingStatusByUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusPackingByUserId/" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getPocketStatusByUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusByUserId/3/" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    getTrackingStatusByUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusTrackingByUserId/" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    getTrackingMonthStatusByUser() {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/dashboard/saleStatusByUserId/4/" + this.userData.userId, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return 0;
        }
    }

    render() {
        const { basicOptions } = this.options;

        return (
            <div className="button-demo">
                {(this.userData.role === Constants.ADMIN || this.userData.role === Constants.OWNER || this.userData.role === Constants.SUPER) && (
                    <div className="p-grid p-fluid dashboard">
                        <div className=" card p-col-12 p-lg-9" style={{marginTop: "20px"}}>
                            <div className="p-d-flex p-ai-center">
                                <Dropdown value={this.state.selectedMonth} options={this.state.months} onChange={this.onMonthChange} optionLabel="month" placeholder="เลือกเดือน" />
                                <Dropdown value={this.state.selectedYear} options={this.state.years} onChange={this.onYearChange} optionLabel="year" placeholder="เลือกปี" style={{ marginLeft: "7px" }} />
                                <Button icon="pi pi-search" onClick={this.onChartStoreChange} style={{ marginLeft: "7px" }} disabled={!this.state.selectedMonth || !this.state.selectedYear} />
                            </div>
                            <div className="card">
                                <h5>ยอดขาย {this.state.monthYear}</h5>
                                <Chart type="line" data={this.state.saleByStore} options={basicOptions} />
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-3">
                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">รับออเดอร์</span>
                                    <span className="detail">Number of order</span>
                                    <span className="count visitors block text-500 font-medium mb-3">{this.state.orderingStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">กำลังแพ็ค</span>
                                    <span className="detail">Number of packing</span>
                                    <span className="count purchases">{this.state.packingStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">เตรียมจัดส่ง</span>
                                    <span className="detail">Number of prepare shiping</span>
                                    <span className="count income">{this.state.pocketStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">จัดส่งแล้ว</span>
                                    <span className="detail">Number of tracking</span>
                                    <span className="count revenue">{this.state.trackingStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card summary" style={{ with: "90px" }}>
                                    <span className="text-500">จัดส่งแล้วรายเดือน</span>
                                    <span className="detail">Number of tracking</span>
                                    <span className="count revenue">{this.state.trackingMonthStatusByStore}</span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-3">
                            <Panel header="Activity" style={{ height: "100%" }}>
                                <div className="p-grid p-mt-2">
                                    <ul className="activity-list">
                                        {this.state.salesSummaryByStore.length > 0 &&
                                            this.state.salesSummaryByStore.map((val, key) => (
                                                <li key={key}>
                                                    <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                                        <h5 className="activity p-m-0">{val.name}</h5>
                                                        <div className="count" style={{ backgroundColor: "#007be5", fontSize: "12px" }}>
                                                            ${val.saleAmount}
                                                        </div>
                                                    </div>
                                                    <ProgressBar value={val.count} style={{ height: "6px" }} showValue={false} />
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </Panel>
                        </div>

                        <div className="p-col-12 p-lg-9">
                            <div className="card">
                                <h5>ยอดขายรายเดือน</h5>
                                <Chart type="line" data={this.state.saleMonthByStore} options={basicOptions} />
                            </div>
                        </div>

                    </div>
                )}

                {this.userData.role === Constants.MANAGE && (
                    <div className="p-grid p-fluid dashboard">
                        <div className="p-col-4">
                                <div className="card summary">
                                    <span className="text-500">รับออเดอร์</span>
                                    <span className="detail">Number of order</span>
                                    <span className="count visitors block text-500 font-medium mb-3">{this.state.orderingStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="card summary">
                                    <span className="text-500">กำลังแพ็ค</span>
                                    <span className="detail">Number of packing</span>
                                    <span className="count purchases">{this.state.packingStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="card summary">
                                    <span className="text-500">เตรียมจัดส่ง</span>
                                    <span className="detail">Number of prepare shiping</span>
                                    <span className="count income">{this.state.pocketStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="card summary">
                                    <span className="text-500">จัดส่งแล้ว</span>
                                    <span className="detail">Number of tracking</span>
                                    <span className="count revenue">{this.state.trackingStatusByStore}</span>
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="card summary" style={{ with: "90px" }}>
                                    <span className="text-500">จัดส่งแล้วรายเดือน</span>
                                    <span className="detail">Number of tracking</span>
                                    <span className="count revenue">{this.state.trackingMonthStatusByStore}</span>
                                </div>
                            </div>
                    </div>
                )}

                {this.userData.role === Constants.SALE && (
                    <div className="p-grid p-fluid dashboard">
                        <div className="card p-col-12 p-lg-9" style={{marginTop: "20px"}}>
                            <div className="p-d-flex p-ai-center">
                                <Dropdown value={this.state.selectedMonth} options={this.state.months} onChange={this.onMonthChange} optionLabel="month" placeholder="เลือกเดือน" />
                                <Dropdown value={this.state.selectedYear} options={this.state.years} onChange={this.onYearChange} optionLabel="year" placeholder="เลือกปี" style={{ marginLeft: "7px" }} />
                                <Button icon="pi pi-search" onClick={this.onChartUserChange} style={{ marginLeft: "7px" }} disabled={!this.state.selectedMonth || !this.state.selectedYear} />
                            </div>
                            <div className="card">
                                <h5>ยอดขาย {this.state.monthYear} </h5>
                                <Chart type="line" data={this.state.saleUserByUser} options={basicOptions} />
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-3">
                            <div className="p-col-12 ">
                                <div className="card summary">
                                    <span className="text-500">รวมออเดอร์</span>
                                    <span className="detail">Total number of order</span>
                                    <span className="count total">{this.state.orderingTotalByUser}</span>
                                </div>
                            </div>
                            <div className="p-col-12 ">
                                <div className="card summary">
                                    <span className="text-500">รับออเดอร์</span>
                                    <span className="detail">Number of order</span>
                                    <span className="count visitors">{this.state.orderingStatusByUser}</span>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">กำลังแพ็ค</span>
                                    <span className="detail">Number of packing</span>
                                    <span className="count purchases">{this.state.packingStatusByUser}</span>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">เตรียมจัดส่ง</span>
                                    <span className="detail">Number of prepare shiping</span>
                                    <span className="count income">{this.state.pocketStatusByUser}</span>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500">จัดส่งแล้ว</span>
                                    <span className="detail">Number of tracking</span>
                                    <span className="count revenue">{this.state.trackingStatusByUser}</span>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="card summary">
                                    <span className="text-500 ">จัดส่งแล้วรายเดือน</span>
                                    <span className="detail">Number of tracking</span>
                                    <span className="count revenue">{this.state.trackingMonthStatusByUser}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
