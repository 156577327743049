import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";
//import { AppConfig } from "./AppConfig";
import { Dashboard } from "./views/Dashboard";
import { FormOrder } from "./views/FormOrder";
import { FormOrderCreate } from "./views/FormOrderCreate";
import { FormOrderUpdate } from "./views/FormOrderUpdate";
import { FormItem } from "./views/FormItem";
import { FormItemCreate } from "./views/FormItemCreate";
import { FormItemView } from "./views/FormItemView";
import { FormItemStock } from "./views/FormItemStock";
import { FormStore } from "./views/FormStore";
import { FormStoreItem } from "./views/FormStoreItem";
import { FormStoreUser } from "./views/FormStoreUser";
import { FormPacking } from "./views/FormPacking";
import { FormPocket } from "./views/FormPocket";
import { FormTracking } from "./views/FormTracking";
import { FormSaleOrder } from "./views/FormSaleOrder";
import { FormSaleOrderDtl } from "./views/FormSaleOrderDtl";
import { FormUploadCod } from "./views/FormUploadCod";
import { FormReturn } from "./views/FormReturn";
import { FormRequisitionItem } from "./views/FormRequisitionItem";
import { FormPrinting } from "./views/FormPrinting";
import { FormLink } from "./views/FormLink";
import { FormTrackingUpdate } from "./views/FormTrackingUpdate";
import { FormTrackingList } from "./views/FormTrackingList";
import { FormReturnTracking } from "./views/FormReturnTracking";

import { FormReportMonthlyCod } from "./views/report/FormReportMonthlyCod";
import { FormReportMonthlyProfit } from "./views/report/FormReportMonthlyProfit";
import { FormReportMonthlyLoss } from "./views/report/FormReportMonthlyLoss";

import { FormReportSaleMonthlyByAdmin } from "./views/report/FormReportSaleMonthlyByAdmin";
import { FormReportSaleMonthlyByStore } from "./views/report/FormReportSaleMonthlyByStore";
import { FormReportSaleDailyByAdmin } from "./views/report/FormReportSaleDailyByAdmin";
import { FormReportSaleDailyByStore } from "./views/report/FormReportSaleDailyByStore";
import { FormReportSaleDailyByItem } from "./views/report/FormReportSaleDailyByItem";
import { FormReportSaleMonthlyByItem } from "./views/report/FormReportSaleMonthlyByItem";
import { FormReportSaleDailyStock } from "./views/report/FormReportSaleDailyStock";

import { FormReportMonthlySaleByAdmin } from "./views/report/FormReportMonthlySaleByAdmin";
import { FormReportMonthlySale } from "./views/report/FormReportMonthlySale";
import { FormReportMonthlySaleByStore } from "./views/report/FormReportMonthlySaleByStore";
import Login from "./views/Login";

import * as Constants from "./constants/Constant";

//import PrimeReact from 'primereact/api';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./styles/layout/flags/flags.css";
import "./styles/layout/layout.scss";
import "./App.scss";
const AppHome = () => {
    const [ripple] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(true);
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const sidebar = useRef();

    let menuClick = false;

    useEffect(() => {
        if (sidebarActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [sidebarActive]);

    const onWrapperClick = (event) => {
        if (!menuClick /*&& layoutMode*/ === "overlay") {
            setSidebarActive(false);
        }
        menuClick = false;
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        setSidebarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items /*&& layoutMode*/ === "overlay") {
            setSidebarActive(false);
        }
    };

    const menu = [
        { label: "แดชบอร์ด", icon: "pi pi-fw pi-th-large", to: "/home" },
        {
            label: "จัดการและตั้งค่า",
            icon: "pi pi-fw pi-sitemap",
            items: [
                {
                    label: "สินค้า",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/item",
                },
                {
                    label: "สต็อก",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/stock",
                },
                {
                    label: "ร้านค้า",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/store",
                },
            ],
        },
        {
            label: "จัดการร้านค้า",
            icon: "pi pi-fw pi-home",
            items: [
                {
                    label: "ตั้งค่าสินค้า",
                    icon: "pi pi-fw pi-table",
                    to: "/storeItem",
                },
                {
                    label: "ตั้งค่าผู้ใช้งาน",
                    icon: "pi pi-fw pi-user-plus",
                    to: "/storeUser",
                },
            ],
        },
        { label: "ออเดอร์สินค้า", icon: "pi pi-fw pi-shopping-cart", to: "/order" },
        { label: "บันทึกแพ็คสินค้า", icon: "pi pi-fw pi-inbox", to: "/packing" },
        { label: "บันทึกพ็อกเก็ต", icon: "pi pi-fw pi-wallet", to: "/pocket" },
        { label: "พิมพ์ใบเบิกสินค้า", icon: "pi pi-fw pi-external-link", to: "/pdfRequisitionItem" },
        { label: "พิมพ์ใบปะหน้า", icon: "pi pi-fw pi-print", to: "/printing" },
        { label: "บันทึกเลขพัสดุ", icon: "pi pi-fw pi-id-card", to: "/tracking" },
        { label: "แก้ไขเลขพัสดุ", icon: "pi pi-fw pi-check-square", to: "/editTracking" },
        { label: "รายการเลขพัสดุ", icon: "pi pi-fw pi-search", to: "/listTracking" },
        { label: "บันทึกคืนพัสดุ", icon: "pi pi-fw pi-replay", to: "/return" },
        { label: "บันทึกคืนเลขที่พัสดุ", icon: "pi pi-fw pi-refresh", to: "/returnTracking" },
        { label: "ค้นหารายการขาย", icon: "pi pi-fw pi-search", to: "/saleOrder" },
        { label: "อัปโหลดรายการCOD", icon: "pi pi-fw pi-upload", to: "/uploadCod" },
        {
            label: "รายงาน",
            icon: "pi pi-fw pi-clone",
            items: [
                {
                    label: "รายงานยอดขายสินค้า",
                    icon: "pi pi-fw pi-calendar-plus",
                    items: [
                       
                        {
                            label: "รายงาน ยอดขายสินค้าประจำวัน",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleDailyByStore",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำวันตามพนักงานขาย",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleDailyByAdmin",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำวันตามสินค้า",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleDailyByItem",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำเดือน",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleMonthlyByStore",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำเดือนตามพนักงานขาย",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleMonthlyByAdmin",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำเดือนตามสินค้า",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleMonthlyByItem",
                        },
                    ],
                },
                {
                    label: "รายงาน สินค้าคงเหลือประจำวัน",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportSaleDailyStock",
                },
                {
                    label: "รายงาน ยอดขายประจำเดือน",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlySale",
                },
                {
                    label: "รายงาน ยอดขายประจำเดือนตามพนักงานขาย",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlySaleByAdmin",
                },
                {
                    label: "รายงาน ค้างยอดโอน",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlyLoss",
                },
                {
                    label: "รายงาน ต้นทุน/กำไร",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlyProfit",
                },
                {
                    label: "รายงาน บริการส่งของและเก็บเงินปลายทาง",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlyCod",
                },
                {
                    label: "รายงาน รายการขายประจำเดือนตามร้านค้า",
                    icon: "pi pi-fw pi-heart",
                    to: "/reportMonthlySaleByStore",
                },
            ],
        },
    ];

    const menuOwner = [
        { label: "แดชบอร์ด", icon: "pi pi-fw pi-home", to: "/home" },
        {
            label: "จัดการและตั้งค่า",
            icon: "pi pi-fw pi-sitemap",
            items: [
                {
                    label: "สินค้า",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/item",
                },
                {
                    label: "สต็อก",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/stock",
                },
            ],
        },
        {
            label: "จัดการร้านค้า",
            icon: "pi pi-fw pi-sitemap",
            items: [
                {
                    label: "ตั้งค่าสินค้า",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/storeItem",
                },
                {
                    label: "ตั้งค่าผู้ใช้งาน",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/storeUser",
                },
            ],
        },
        { label: "ออเดอร์สินค้า", icon: "pi pi-fw pi-shopping-cart", to: "/order" },
        { label: "บันทึกแพ็คสินค้า", icon: "pi pi-fw pi-inbox", to: "/packing" },
        { label: "บันทึกพ็อกเก็ต", icon: "pi pi-fw pi-wallet", to: "/pocket" },
        { label: "พิมพ์ใบปะหน้า", icon: "pi pi-fw pi-print", to: "/printing" },
        { label: "พิมพ์ใบเบิกสินค้า", icon: "pi pi-fw pi-external-link", to: "/pdfRequisitionItem" },
        { label: "บันทึกเลขพัสดุ", icon: "pi pi-fw pi-id-card", to: "/tracking" },
        { label: "แก้ไขเลขพัสดุ", icon: "pi pi-fw pi-check-square", to: "/editTracking" },
        { label: "รายการเลขพัสดุ", icon: "pi pi-fw pi-search", to: "/listTracking" },
        { label: "บันทึกคืนพัสดุ", icon: "pi pi-fw pi-replay", to: "/return" },
        { label: "บันทึกคืนเลขที่พัสดุ", icon: "pi pi-fw pi-refresh", to: "/returnTracking" },
        { label: "ค้นหารายการขาย", icon: "pi pi-fw pi-search", to: "/saleOrder" },
        { label: "อัปโหลดรายการCOD", icon: "pi pi-fw pi-upload", to: "/uploadCod" },
        {
            label: "รายงาน",
            icon: "pi pi-fw pi-clone",
            items: [
                {
                    label: "รายงานยอดขายสินค้า",
                    icon: "pi pi-fw pi-calendar-plus",
                    items: [
                       
                        {
                            label: "รายงาน ยอดขายสินค้าประจำวัน",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleDailyByStore",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำวันตามพนักงานขาย",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleDailyByAdmin",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำวันตามสินค้า",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleDailyByItem",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำเดือน",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleMonthlyByStore",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำเดือนตามพนักงานขาย",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleMonthlyByAdmin",
                        },
                        {
                            label: "รายงาน ยอดขายสินค้าประจำเดือนตามสินค้า",
                            icon: "pi pi-fw pi-calendar",
                            to: "/reportSaleMonthlyByItem",
                        },
                    ],
                },
                {
                    label: "รายงาน สินค้าคงเหลือประจำวัน",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportSaleDailyStock",
                },
                {
                    label: "รายงาน ยอดขายประจำเดือน",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlySale",
                },
                {
                    label: "รายงาน ยอดขายประจำเดือนตามพนักงานขาย",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlySaleByAdmin",
                },
                {
                    label: "รายงาน ค้างยอดโอน",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlyLoss",
                },
                {
                    label: "รายงาน ต้นทุน/กำไร",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlyProfit",
                },
                {
                    label: "รายงาน บริการส่งของและเก็บเงินปลายทาง",
                    icon: "pi pi-fw pi-calendar-minus",
                    to: "/reportMonthlyCod",
                },
                {
                    label: "รายงาน รายการขายประจำเดือนตามร้านค้า",
                    icon: "pi pi-fw pi-heart",
                    to: "/reportMonthlySaleByStore",
                },
            ],
        },
    ];

    const menuManage = [
        { label: "แดชบอร์ด", icon: "pi pi-fw pi-home", to: "/home" },
        { label: "บันทึกแพ็คสินค้า", icon: "pi pi-fw pi-inbox", to: "/packing" },
        { label: "บันทึกพ็อกเก็ต", icon: "pi pi-fw pi-wallet", to: "/pocket" },
        { label: "พิมพ์ใบปะหน้า", icon: "pi pi-fw pi-print", to: "/printing" },
        { label: "พิมพ์ใบเบิกสินค้า", icon: "pi pi-fw pi-external-link", to: "/pdfRequisitionItem" },
        { label: "บันทึกเลขพัสดุ", icon: "pi pi-fw pi-id-card", to: "/tracking" },
        { label: "แก้ไขเลขพัสดุ", icon: "pi pi-fw pi-check-square", to: "/editTracking" },
        { label: "รายการเลขพัสดุ", icon: "pi pi-fw pi-search", to: "/listTracking" },
        { label: "บันทึกคืนพัสดุ", icon: "pi pi-fw pi-replay", to: "/return" },
        { label: "บันทึกคืนเลขที่พัสดุ", icon: "pi pi-fw pi-refresh", to: "/returnTracking" },
        { label: "ค้าหารายการขาย", icon: "pi pi-fw pi-search", to: "/saleOrder" },
    ];

    const menuSupervisor = [
        { label: "แดชบอร์ด", icon: "pi pi-fw pi-home", to: "/home" },
        {
            label: "จัดการและตั้งค่า",
            icon: "pi pi-fw pi-sitemap",
            items: [
                {
                    label: "สินค้า",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/item",
                },
                {
                    label: "สต็อก",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/stock",
                },
            ],
        },
        {
            label: "จัดการร้านค้า",
            icon: "pi pi-fw pi-sitemap",
            items: [
                {
                    label: "ตั้งค่าสินค้า",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/storeItem",
                },
                {
                    label: "ตั้งค่าผู้ใช้งาน",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/storeUser",
                },
            ],
        },
        { label: "ออเดอร์สินค้า", icon: "pi pi-fw pi-shopping-cart", to: "/order" },
        { label: "ค้าหารายการขาย", icon: "pi pi-fw pi-search", to: "/saleOrder" },
        {
            label: "รายงาน ยอดขายสินค้าประจำเดือนตามพนักงานขาย",
            icon: "pi pi-fw pi-calendar",
            to: "/reportSaleMonthlyByAdmin",
        },
    ];

    const menuSales = [
        { label: "แดชบอร์ด", icon: "pi pi-fw pi-home", to: "/home" },
        { label: "ออเดอร์สินค้า", icon: "pi pi-fw pi-shopping-cart", to: "/order" },
        { label: "ค้าหารายการขาย", icon: "pi pi-fw pi-search", to: "/saleOrder" },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const isSidebarVisible = () => {
        return sidebarActive;
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": /*layoutMode ===*/ "overlay",
        "layout-static": /*layoutMode === */ "static",
        "layout-active": sidebarActive,
        "p-input-filled": /*inputStyle === */ "filled",
        "p-ripple-disabled": ripple === false,
        "p-panel": { "line-height": 1.5, margin: 0 },
    });

    const sidebarClassName = classNames("layout-sidebar", {
        "layout-sidebar-light": /*layoutColorMode === */ "light",
    });

    const { pathToRegexp } = require("path-to-regexp");
    const regexp = pathToRegexp("/:id(\\d+)");
    regexp.exec("/:id");

    if (user) {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <AppTopbar onToggleMenu={onToggleMenu} />
                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                    <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                        <AppProfile />
                        {/* {user.role === Constants.MANAGE && user.storeId === 4 && <AppMenu model={menuManageTest} onMenuItemClick={onMenuItemClick} />} */}
                        {user.role === Constants.ADMIN && <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />}
                        {user.role === Constants.OWNER && <AppMenu model={menuOwner} onMenuItemClick={onMenuItemClick} />}
                        {user.role === Constants.SALE && <AppMenu model={menuSales} onMenuItemClick={onMenuItemClick} />}
                        {user.role === Constants.MANAGE && <AppMenu model={menuManage} onMenuItemClick={onMenuItemClick} />}
                        {user.role === Constants.SUPER && <AppMenu model={menuSupervisor} onMenuItemClick={onMenuItemClick} />}
                    </div>
                </CSSTransition>
                <div className="layout-main">
                    <Route path="/home" exact component={Dashboard} />
                    <Route path="/item" exact component={FormItem} />
                    <Route path="/newItem" exact component={FormItemCreate} />
                    <Route path="/viewItem/:id" exact component={FormItemView} />
                    <Route path="/stock" exact component={FormItemStock} />
                    <Route path="/store" exact component={FormStore} />
                    <Route path="/order" exact component={FormOrder} />
                    <Route path="/newOrder" exact component={FormOrderCreate} />
                    <Route path="/editOrder/:id" exact component={FormOrderUpdate} />
                    <Route path="/storeItem" exact component={FormStoreItem} />
                    <Route path="/storeUser" exact component={FormStoreUser} />
                    <Route path="/packing" exact component={FormPacking} />
                    <Route path="/pocket" exact component={FormPocket} />
                    <Route path="/tracking" exact component={FormTracking} />
                    <Route path="/printing" exact component={FormPrinting} />
                    <Route path="/saleOrder" exact component={FormSaleOrder} />
                    <Route path="/viewOrder/:id" exact component={FormSaleOrderDtl} />
                    <Route path="/uploadCod" exact component={FormUploadCod} />
                    <Route path="/return" exact component={FormReturn} />
                    <Route path="/pdfRequisitionItem" exact component={FormRequisitionItem} />
                    <Route path="/editTracking" exact component={FormTrackingUpdate} />
                    <Route path="/listTracking" exact component={FormTrackingList} />
                    <Route path="/returnTracking" exact component={FormReturnTracking} />
                    <Route path="/reportMonthlyCod" exact component={FormReportMonthlyCod} />
                    <Route path="/reportMonthlyProfit" exact component={FormReportMonthlyProfit} />
                    <Route path="/reportMonthlyLoss" exact component={FormReportMonthlyLoss} />
                    <Route path="/reportSaleMonthlyByAdmin" exact component={FormReportSaleMonthlyByAdmin} />
                    <Route path="/reportSaleMonthlyByStore" exact component={FormReportSaleMonthlyByStore} />
                    <Route path="/reportSaleDailyByAdmin" exact component={FormReportSaleDailyByAdmin} />
                    <Route path="/reportSaleDailyByStore" exact component={FormReportSaleDailyByStore} />
                    <Route path="/reportSaleDailyByItem" exact component={FormReportSaleDailyByItem} />
                    <Route path="/reportSaleMonthlyByItem" exact component={FormReportSaleMonthlyByItem} />
                    <Route path="/reportSaleDailyStock" exact component={FormReportSaleDailyStock} />
                    <Route path="/reportMonthlySaleByAdmin" exact component={FormReportMonthlySaleByAdmin} />
                    <Route path="/reportMonthlySaleByStore" exact component={FormReportMonthlySaleByStore} />
                    <Route path="/reportMonthlySale" exact component={FormReportMonthlySale} />
                    {/* <Route path="/packingList" exact component={FormPackingList} />
                    <Route path="/pocketList" exact component={FormPocketList} /> */}
                    <Route path="/:id" exact component={FormLink} />
                </div>
                <AppFooter />
            </div>
        );
    } else {
        return (
            <Route path="/login">
                <Login />
            </Route>
        );
    }
};
export default AppHome;
