import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./AppHome";
import Login from "./views/Login";
import Link from "./views/Link";
function App() {
    /*
    const [token, setToken] = useState();
    if (!token) {
      return <Login setToken={setToken} />
    }
  */
    const token = localStorage.getItem("accessToken");

    if (!token) {
        return (
            <div className="wrapper">
                <BrowserRouter>
                    <Switch>
                        <Route path="/:id" exact component={Link} />
                        <Route path="/">
                            <Login />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }

    return (
        <div className="wrapper">
            <BrowserRouter>
                <Switch>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
