import React, { Component } from "react";
import { Toast } from "primereact/toast";
import * as Constants from "../constants/Constant";
import "../styles/css/Table.css";

export class FormTracking extends Component {
    emptFilter = {
        storeId: "",
        dateForm: "",
        dateTo: "",
        orderNumber: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            order: {},
            orderNumber: "",
            trackingNumber: "",
            show: false,
            notfound: false,
        };
        this.userData = {};
        this.userStore = {};
        this.updateStock = this.updateStock.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.getOrderByOrderNumber = this.getOrderByOrderNumber.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    async componentDidMount() {
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.userStore = JSON.parse(localStorage.getItem("store"));
    }

    getOrderByOrderNumber(number) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByOrderNumber/" + number + "/3", requestOptions);
        if (res.ok) {
            this.setState({
                show: true,
                notfound: false,
            });
            return res.json();
        } else {
            this.setState({
                show: false,
                notfound: true,
            });
            return null;
        }
    }

    getOrderByTrackingNumber(number) {
        const fetchs = require("sync-fetch");
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const res = fetchs(Constants.APIURL + "/ordersByTrackingNumber/" + number, requestOptions);
        if (res.ok) {
            return res.json();
        } else {
            return null;
        }
    }

    updateStock(order) {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/itemStock/update/" + order.orderId, {
            method: "GET",
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    updateStatus(order) {
        let status = 4;

        if (order.slip) {
            status = 5;
        }

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(Constants.APIURL + "/orderStatus/" + order.orderId, {
            method: "PUT",
            body: JSON.stringify({
                statusId: status,
                updatedBy: this.userData.userId,
            }),
            headers: headersList,
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                console.log(data);
            });
    }

    handleFocus(e) {
        //Check if no of char in field == maxlength
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        console.log("fieldName", fieldName);
        // console.log("fieldIndex", fieldIndex)
        // console.log("value", value)
        // console.log("name", name)

        if (name === "field-1") {
            const orderByOrderNumber = this.getOrderByOrderNumber(value);
            this.setState({ orderNumber: value, order: orderByOrderNumber });
        } else {
            this.setState({ trackingNumber: value });
            //this.handleSave();
        }
        let fieldIntIndex = parseInt(fieldIndex, 13);
        if (value.length >= maxLength) {
            // It should not be last input field
            if (fieldIntIndex < 13) {
                // Get the next input field using it's name
                const nextfield = document.querySelector(`input[name=field-${fieldIntIndex + 1}]`);

                // If found, focus the next field
                if (nextfield !== null) {
                    console.log("nextfield", nextfield);
                    nextfield.focus();
                } else {
                    console.log("handleSave", nextfield);

                    setTimeout(() => {
                        console.log("handleFocus orderNumber", this.state.orderNumber);
                        console.log("handleFocus trackingNumber", this.state.trackingNumber);
                        this.handleSave();
                    }, 2000);
                }
            }
        }
    }

    handleSave(e) {
        console.log("handleSave orderNumber", this.state.orderNumber);
        console.log("handleSave trackingNumber", this.state.trackingNumber);
        console.log("handleSave trackingNumberFormat", this.state.trackingNumber.substring(this.state.trackingNumber.length - 2));

        let trackingNumberFormat = this.state.trackingNumber.substring(this.state.trackingNumber.length - 2);

        console.log(trackingNumberFormat.toUpperCase());
        console.log(trackingNumberFormat.toUpperCase() === "TH");

        if (trackingNumberFormat.toUpperCase() === "TH") {
            const orderByTrackingNumber = this.getOrderByTrackingNumber(this.state.trackingNumber);

            console.log("getOrderByTrackingNumber", orderByTrackingNumber);

            if (!orderByTrackingNumber) {
                const orderByOrderNumber = this.getOrderByOrderNumber(this.state.orderNumber);

                console.log("getOrderByOrderNumber", orderByOrderNumber);

                if (orderByOrderNumber) {
                    let headersList = {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                    };

                    fetch(Constants.APIURL + "/orderTrackingNumber/" + orderByOrderNumber.orderId, {
                        method: "PUT",
                        body: JSON.stringify({
                            trackingNumber: this.state.trackingNumber,
                            updatedBy: this.userData.userId,
                        }),
                        headers: headersList,
                    })
                        .then(function (response) {
                            return response.text();
                        })
                        .then(function (data) {
                            console.log(data);
                        });

                    this.updateStatus(orderByOrderNumber);

                    this.updateStock(orderByOrderNumber);

                    this.setState({
                        order: {},
                        orderNumber: "",
                        trackingNumber: "",
                    });

                    this.clearCourse();

                    this.toast.show({ severity: "success", summary: "บันทึกเลขพัสดุ", detail: "บันทึกสำเร็จ", life: 3000 });
                } else {
                    this.toast.show({ severity: "warn", summary: "บันทึกเลขพัสดุ", detail: "ไม่พบออเดอร์ " + this.state.orderNumber, life: 3000 });
                }
            } else {
                this.toast.show({ severity: "warn", summary: "บันทึกเลขพัสดุ", detail: "มีเลขที่พัสดุนี้แล้ว " + this.state.trackingNumber, life: 3000 });
            }
        } else {
            this.toast.show({ severity: "warn", summary: "บันทึกเลขพัสดุ", detail: "เลขที่พัสดุนี้ไม่ถูกต้อง " + this.state.trackingNumber, life: 3000 });
        }

        this.setState({
            order: {},
            orderNumber: "",
            trackingNumber: "",
        });

        this.clearCourse();
    }

    clearCourse() {
        document.getElementById("form-tracking").reset();
        document.getElementById("field-orderNumber").focus();
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="card">
                    <h1>บันทึกเลขพัสดุ</h1>
                </div>

                <div className="card">
                    <form id="form-tracking">
                        <div className="p-field p-grid">
                            <label htmlFor="field-orderNumber" className="p-col-fixed" style={{ width: "100px" }}>
                                รหัสออเดอร์
                            </label>
                            <div className="p-col">
                                <input id="field-orderNumber" name="field-1" onChange={this.handleFocus} maxLength="13" autoFocus={true}></input>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="field-trackingNumber" className="p-col-fixed" style={{ width: "100px" }}>
                                เลขที่พัสดุ
                            </label>
                            <div className="p-col">
                                <input id="field-trackingNumber" name="field-2" onChange={this.handleFocus} maxLength="13"></input>
                            </div>
                        </div>
                    </form>
                </div>

                {this.state.show && (
                    <div className="card">
                        <div>
                            รหัสออเดอร์ : {this.state.order.orderNumber}
                            <br />
                            ชื่อลูกค้า : {this.state.order.name}
                            <br />
                            ที่อยู่ : {this.state.order.address}
                            <br />
                            เบอร์โทรศัทพ์ : {this.state.order.mobilePhone}
                            <br />
                            ยอดขาย : {this.state.order.amount} บาท
                            <br />
                            เลขที่พัสดุ : {this.state.order.trackingNumber}
                            <br />
                            สถานะ : {this.state.order.status}
                        </div>
                    </div>
                )}
                {this.state.notfound && (
                    <div className="card">
                        <div>data not found!</div>
                    </div>
                )}
            </div>
        );
    }
}
